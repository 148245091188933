import ActionTypes from '../action-types'

import { getAuthToken } from '../common/auth-utils'

const tokenInfo = getAuthToken()

const initialState = {
  loggedIn: Boolean(tokenInfo.token),
  user: {
    email: tokenInfo.email || '',
    firstName: tokenInfo.firstName || '',
    lastName: tokenInfo.lastName || ''
  },
  token: tokenInfo.token || ''
}

export function trades(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.FETCH_ALL_TRADES:
      return {
        ...state
      }
    case ActionTypes.TRADES_SUCCESS:
      return {
        ...state,
        trades: {
          ...action.trades
        }
      }
    case ActionTypes.FETCH_CUSTOM_TRADES:
      return {
        ...state
      }
    case ActionTypes.CUSTOM_TRADES_SUCCESS:
      return {
        ...state,
        trades: {
          ...action.trades
        }
      }
    case ActionTypes.CUSTOM_TRADES_FAILURE:
      return {
        error: action.error
      }
    case ActionTypes.TRADES_FAILURE:
      return {
        error: action.error
      }

    default:
      return state
  }
}
