  import zipObject from 'lodash/zipObject'

const actions = [
  'UPDATE_PREMIUM_STATE',
  'SET_AUTH_STATE',

  'AUTH_REQUEST',
  'AUTH_SUCCESS',
  'AUTH_FAILURE',

  'GOOGLE_AUTH_REQUEST',
  'GOOGLE_AUTH_SUCCESS',
  'GOOGLE_AUTH_FAILURE',

  'TERMS_AGREEMENT_REQUEST',
  'TERMS_AGREEMENT_SUCCESS',
  'TERMS_AGREEMENT_FAILURE',

  'LOGOUT_REQUEST',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',

  'SEND_CHANGE_PWD_EMAIL',
  'VERIFY_CHANGE_PWD_TOKEN',
  'FORGOT_CHANGE_PASSWORD',
  'SAVE_VERIFY_CHANGE_PWD_TOKEN',
  'SAVE_SET_PASSWORD',

  'SET_ADVISOR_DATA',
]

export default zipObject(actions, actions)
