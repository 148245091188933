import React, { useState, useEffect } from 'react'
import { handleGet, handleApprovalChange, handleDelete } from './utils'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { Spinner } from 'shared-components/'

const ITEMS_PER_PAGE = 10

const ApprovalPatchButton = ({ handleApproval }) => {
  return (
    <div className="d-flex justify-content-center">
      <button className="btn btn-primary btn-sm" onClick={handleApproval}>
        Approve
      </button>
    </div>
  )
}
const DisapprovalPatchButton = ({ handleDisapproval }) => {
  return (
    <div className="d-flex justify-content-center">
      <button className="btn btn-danger btn-sm" onClick={handleDisapproval}>
        Disapprove
      </button>
    </div>
  )
}

const DeleteButton = ({ handleDelete }) => {
  return (
    <div className="d-flex justify-content-center">
      <button className="btn btn-danger btn-sm" onClick={handleDelete}>
        Delete
      </button>
    </div>
  )
}
const Treasury = () => {
  const [data, setData] = useState([])
  const [refetch, setRefetch] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const debouncedSearch = _.debounce(val => {
    setSearch(val)
  }, 100)
  const handlePageChange = newPage => {
    setCurrentPage(newPage)
  }
  const filteredData = data
    .filter(item => item.ticker.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => a.ticker.localeCompare(b.ticker))
    .sort((a, b) => a.id - b.id)

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / ITEMS_PER_PAGE))
  }, [filteredData])

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const paginatedData =
    search !== ''
      ? filteredData
      : filteredData.slice(startIndex, startIndex + ITEMS_PER_PAGE)
  const fetchData = async () => {
    const fetchedData = await handleGet()
    if (fetchedData) {
      setData(fetchedData)
      setTotalPages(Math.ceil(fetchedData.length / ITEMS_PER_PAGE))
    } else {
      console.log('No data')
    }
  }
  useEffect(() => {
    fetchData()
  }, [refetch])
  return (
    <div style={{ padding: 40 }}>
      <Spinner isFetching={isLoading} />
      <div className="section-detail" style={{ minHeight: '1121px' }}>
        <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 10
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={e => debouncedSearch(e.target.value)}
            style={{
              width: 200,
              padding: 5,
              margin: 5
            }}
          />
        </div>
        <div className="row relationship-row d-flex justify-content-between px-3">
          <div className="col-1 text-left mb-2 mb-sm-0">
            <label>ID</label>
          </div>
          <div className="col-2 text-left mb-2 mb-sm-0">
            <label>Ticker</label>
          </div>
          <div className="col-2 text-left mb-2 mb-sm-0">
            <label>Action</label>
          </div>
        </div>
        <div id="scrollableDiv">
          {data.length === 0 && (
            <div className="row relationship-row d-flex justify-content-between px-3">
              <div className="col-12 text-left mb-2 mb-sm-0">
                <label>No data</label>
              </div>
            </div>
          )}
          {paginatedData.length > 0 &&
            paginatedData.map((item, idx) => {
              return (
                <TreasuryRow
                  key={idx}
                  item={item}
                  handleApproval={() => {
                    handleApprovalChange({
                      id: item.id,
                      setLoading,
                      dispatch,
                      setRefetch,
                      approval: true
                    })
                  }}
                  handleDisapproval={() => {
                    handleApprovalChange({
                      id: item.id,
                      setLoading,
                      dispatch,
                      setRefetch,
                      approval: false
                    })
                  }}
                  handleDelete={() => {
                    handleDelete({
                      id: item.id,
                      setLoading,
                      dispatch,
                      setRefetch
                    })
                  }}
                />
              )
            })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

const TreasuryRow = ({
  item,
  handleDisapproval,
  handleApproval,
  handleDelete,
  isAvailable
}) => {
  return (
    <div className="row relationship-row d-flex justify-content-between px-3 border-0">
      <div
        className="col-1 text-left mb-2 mb-sm-0 d-flex align-items-center"
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: 5
        }}
      >
        {item.id}
      </div>
      <div
        className="col-2 text-left mb-2 mb-sm-0 d-flex justify-content-start align-items-center"
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: 5
        }}
      >
        {item.ticker}
        <i
          className="fa fa-circle"
          style={{
            color: item.isapproved ? 'green' : 'red',
            fontSize: 10
          }}
        ></i>
      </div>
      <div
        className="col-2 text-left mb-2 mb-sm-0 "
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: 10
        }}
      >
        {item.isapproved ? (
          <DisapprovalPatchButton handleDisapproval={handleDisapproval} />
        ) : (
          <ApprovalPatchButton handleApproval={handleApproval} />
        )}
        <DeleteButton handleDelete={handleDelete} />
      </div>
    </div>
  )
}
const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const pages = [...Array(totalPages).keys()].map(num => num + 1)
  return (
    <div
      className="pagination"
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 10,
        marginTop: 20
      }}
    >
      {pages.map(page => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`btn ${
            currentPage === page ? 'btn-primary' : 'btn-light'
          }`}
        >
          {page}
        </button>
      ))}
    </div>
  )
}
export default Treasury
