import zipObject from 'lodash/zipObject'

const actions = [
  'FETCH_ALL_TRADES',
  'TRADES_SUCCESS',
  'TRADES_FAILURE',
  'FETCH_CUSTOM_TRADES',
  'CUSTOM_TRADES_SUCCESS',
  'CUSTOM_TRADES_FAILURE'
]

export default zipObject(actions, actions)
