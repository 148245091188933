import { isEmpty, get } from 'lodash'
import React, { useEffect, useState, createRef, Fragment } from 'react'
import { Tooltip } from 'antd'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner as SpinnerR
} from 'reactstrap'
import {
  downloadSnap,
  makeAppeal,
  saveForReview,
  updateSnapStatus,
  uploadReviewImage
} from 'apis/account'
import PrimaryButton from 'shared-components/PrimaryButton'
import { Spinner } from 'shared-components/'
import ConfirmModal from '../../Elements/Confirm'

const FileSaver = require('file-saver')
const UPLOADED = 'uploaded'
const APPROVED = 'approved'
const REJECTED = 'rejected'
const APPEALED = 'appealed'

export const FILE_SIZE = 15 // mb
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png'
]
export class Documents extends React.Component {
  constructor(props) {
    super(props)
    this.documentContainer = createRef()
  }

  state = {
    finalReviewData: [],
    loading: false,
    appeal: ''
  }

  fetchedocs = () => {}

  updateFinalReviewData = obj => {
    // let data = this.state.finalReviewData.length ? this.state.finalReviewData : []
    // if (!data.length) data.push(obj)
    // data = data.map(item => {
    //   if (item.sketchId === obj.sketchId) {
    //     return obj
    //   } else return item
    // })
    let data = this.state.finalReviewData.length
      ? this.state.finalReviewData
      : []
    let index = data.findIndex(val => val.sketchId === obj.sketchId)
    if (index > -1) {
      data[index] = obj
    } else {
      data.push(obj)
    }
    // console.log(data)
    this.setState({ finalReviewData: data })
  }
  //{
  //   apex_account_id: 13,
  //   sketchId: '89343jbj7bjxbv80xHKJHyk',
  //   action: 'APPEALED',
  //   'text': 'auto appealing identity verification',
  //   'cip': {
  //     'snapIDs': [
  //       '0BB4E82C-DFDC-4A78-B11C-12910ECFBF1B'
  //     ]
  //   }
  // }

  onClickAppeal = async () => {
    // let snapIDs = this.props.accountData.snaps.map(snap => snap.snap_id);
    const investigations = get(this.props.apexStatus, 'investigations', [])
    // const showAppealButtonInvest = get(this.props.apexStatus, 'investigations').every(sketch=>sketch.sketchId.status === "ACCEPTED");
    const sketchIds = {}
    investigations.forEach(sketch => {
      if (sketch.sketchId.status !== 'ACCEPTED') {
        const { snaps } = this.props.accountData
        sketch.sketchId.cipCategories.forEach(cat => {
          if (
            cat.categoryState === 'REJECTED' &&
            snaps.findIndex(
              snap => snap.sketch_id === sketch.sketchId.sketchId
            ) > -1
          ) {
            snaps.forEach((snap, idx) => {
              if (
                snap.sketch_id === sketch.sketchId.sketchId &&
                snap.doc_type === cat.name
              ) {
                if (sketchIds[sketch.sketchId.sketchId]) {
                  sketchIds[sketch.sketchId.sketchId].push(snap.snap_id)
                } else {
                  sketchIds[sketch.sketchId.sketchId] = [snap.snap_id]
                }
              }
            })
          }
        })
      }
      // if(sketch.sketchId.status === "ACCEPTED"){
      //   this.props.accountData.snaps.forEach(snap => {
      //     if(sketchIds[sketch.sketchId.sketchId]){
      //       if(sketch.sketchId.sketchId === snap.sketch_id){
      //         sketchIds[sketch.sketchId.sketchId].push(snap.snap_id)
      //       }
      //       debugger
      //     }else{
      //       sketchIds[sketch.sketchId.sketchId] = [snap.snap_id];
      //       debugger
      //     }
      //   });
      // }
    })
    console.log(sketchIds)

    this.setState({
      loading: true
    })

    const promises = Object.keys(sketchIds).map(val => {
      let payload = {
        apex_account_id: this.props.apex_account_id,
        sketchId: val,
        action: 'APPEALED',
        text: 'auto appealing identity verification',
        cip: {
          snapIDs: sketchIds[val]
        }
      }
      return makeAppeal(payload)
    })
    Promise.all(promises)
      .then(res => {
        console.log('Apeal res--', res)
        this.setState({
          loading: false,
          appeal: 'APPEALED'
        })
        this.props.fetchProfile()
      })
      .catch(e => {
        console.log('Apeal error--', e)
        this.setState({
          loading: false,
          appeal: 'Error'
        })
      })
    // let payload = {
    //   apex_account_id: this.props.apex_account_id,
    //   sketchId: this.props.apexStatus.sketchIds[0],
    //   action: 'APPEALED',
    //   text: 'auto appealing identity verification',
    //   cip: {
    //     snapIDs
    //   }
    // }

    // try {
    //   let appeal = await makeAppeal(payload)

    //   this.setState({
    //     loading: false,
    //     appeal: 'APPEALED'
    //   })
    // } catch (e) {
    //   this.setState({
    //     loading: false,
    //     appeal: 'Error'
    //   })
    //   console.log(e)
    // }
  }

  onClickSave = async data => {
    let payload = {
      apex_account_id: this.props.apex_account_id,
      data: this.state.finalReviewData.map(val => ({
        sketchId: val.sketchId,
        snapIDs: val.snapIDs
      }))
    }
    try {
      this.setState({
        loading: true
      })
      let uploadRes = await saveForReview(payload)
      this.props.fetchProfile()
      this.setState({
        loading: false
      })
    } catch (e) {
      this.setState({
        loading: false
      })
      console.log(e)
    }
  }

  checkAppealStatus = (
    data,
    snaps,
    status = {
      // NOTE: Check STATUS for rendering 'Appeal' button
      // --------------------------------------------------
      snaps: APPROVED, // when snaps are 'approved'
      sketch_not: 'ACCEPTED', // sketch.status !== 'ACCEPTED', bcoz once done appealing the sketch status gets changed into 'ACCEPTED'
      cipCategories: 'REJECTED' // cipCategories are 'REJECTED'
      // --------------------------------------------------
    }
  ) => {
    // ['approved', "ACCEPTED"]
    // NOTE: return 'true' or 'false' to render appeal button, byDefault its 'false'.
    // steps 1: Check 'sketchId' and find 'snap'
    // steps 2: Check/Find for 'doc_type' from cipCategories -> 'name' ---->>> that will be FOUND_SNAP
    // steps 3: FOUND_SNAP -> 'status' -> 'approved' -> then show 'Appeal' button
    // ------------------------------------------------------------------------------------------------------------
    let renderAppealButton = null
    try {
      if (!isEmpty(data) && get(data, 'investigations')) {
        let investigations = get(data, 'investigations')
          .filter(item => item.sketchId.status !== status.sketch_not)
          .map(k => k.sketchId)
        // console.log(investigations);
        if (
          investigations &&
          !isEmpty(investigations) &&
          investigations.length
        ) {
          investigations.forEach((sketch, i) => {
            // console.log(sketch);
            if (
              sketch &&
              typeof sketch.cipCategories !== 'undefined' &&
              sketch.cipCategories.length
            ) {
              let sketchId = sketch.id || null
              // steps 1:
              if (sketchId && sketchId !== '') {
                let cipCategories = sketch.cipCategories.filter(
                  cip => cip.categoryState === status.cipCategories
                )
                cipCategories.forEach((cat, id) => {
                  // ------------------------------------------------------------------------------------------------------------
                  if (cat && cat.name && snaps && snaps.length) {
                    // steps 2:
                    const foundSnap = snaps.find(
                      snap => snap.sketch_id === sketchId
                    )
                    if (
                      foundSnap &&
                      typeof foundSnap.doc_type !== 'undefined' &&
                      foundSnap.doc_type === cat.name &&
                      foundSnap.status === status.snaps
                    ) {
                      // steps 3:
                      // NOTE: render 'Appeal' button
                      renderAppealButton = (
                        <PrimaryButton
                          type="button"
                          className="btn primary-btn ml-2"
                          style={{ marginTop: '36px', marginBottom: '6px' }}
                          onClick={this.onClickAppeal}
                        >
                          Appeal
                        </PrimaryButton>
                      )
                    } else {
                      console.log(
                        `COULD'NT FIND SNAP/SKETCH_ID ${sketchId} IN SNAPS LIST, FOR '${cat.name}'`
                      )
                      console.log('CHECK API RESPONSE')
                    }
                  } else {
                    console.log('SNAPS IS MISSING')
                  }
                  // ------------------------------------------------------------------------------------------------------------
                })
              } else {
                console.log('SKETCH_ID IS MISSING')
              }
            } else {
              console.log('SKETCH DATA IN INVESTIGATION ARRAY IS EMPTY')
            }
          })
        } else {
          throw 'INVESTIGATION DATA NOT FOUND'
        }
      } else {
        throw 'DATA IS MISSING'
      }
    } catch (e) {
      console.log('----------- COULDNT RENDER APPEAL BUTTON -----------')
      console.log(e)
      console.log('----------------------------------------------------')
      renderAppealButton = null
    }

    return renderAppealButton
    // ------------------------------------------------------------------------------------------------------------
  }

  render() {
    const {
      apexStatus: data,
      apex_account_id,
      apex_account_no,
      accountData
    } = this.props
    const { showOnlySnaps, finalReviewData } = this.state
    const documentsElement = get(this.documentContainer, 'current.children')
      ? Array.from(get(this.documentContainer, 'current.children'))
      : []
    const filterDocumentElementsLength = documentsElement
      ? documentsElement
          .filter(val => val.nodeName !== 'H5')
          .reduce((acc, node) => (acc += node.children.length), 0)
      : 0
    // console.log(finalReviewData, filterDocumentElementsLength);
    // const isSubmitDisabled = finalReviewData.length === filterDocumentElements.length ? !finalReviewData.every(val => val.enabled) : true;
    const isSubmitDisabled =
      filterDocumentElementsLength !==
      finalReviewData.reduce((acc, val) => (acc += val.snapIDs.length), 0)

    const snaps =
      accountData.snaps && accountData.snaps.length ? accountData.snaps : []
    const filterDocumentElements = get(
      this.documentContainer,
      'current.children'
    )
      ? Array.from(get(this.documentContainer, 'current.children')).filter(
          val => val.nodeName !== 'H5'
        )
      : []

    // NOTE: old-logic -> Checks and Mandate every snaps to be in APPROVED state to remnder 'Appeal' button
    // const showAppealButton = snaps.length ? snaps.every(snap=>snap.status===APPROVED) : false;
    // const showAppealButtonInvest = get(data, 'investigations') ? !get(data, 'investigations').every(sketch=>sketch.sketchId.status === "ACCEPTED") : true;

    const renderAppealButton = this.checkAppealStatus(data, snaps)

    let accountDocs = [
      { doc_type: 'upload_ria', snap_id: '' },
      { doc_type: 'upload_407', snap_id: '' },
      { doc_type: 'upload_3210', snap_id: '' }
    ]

    if (accountData.account[0].upload_ria) {
      accountDocs[0].snap_id = accountData.account[0].upload_ria
    }
    if (accountData.account[0].upload_407) {
      accountDocs[0].snap_id = accountData.account[0].upload_407
    }
    if (accountData.account[0].upload_3210) {
      accountDocs[0].snap_id = accountData.account[0].upload_3210
    }

    return (
      <div className="documents">
        <Spinner isFetching={this.state.loading} />

        <div className="heading mb-2">
          <label>{`Account Documents`}</label>
        </div>
        <div>
          {accountDocs &&
            accountDocs.map((snap, idx) => {
              if (snap && snap.snap_id) {
                return (
                  <AccountDocRow
                    key={idx}
                    fetchProfile={this.props.fetchProfile}
                    accountData={accountData}
                    data={snap}
                  />
                )
              }
            })}
        </div>

        <div className="heading mb-2">
          <label>{`Required documents for review`}</label>
        </div>
        <div>
          {accountData.snaps &&
            accountData.snaps.length &&
            accountData.snaps.map((snap, idx) => {
              return (
                <SnapIdRow
                  key={idx}
                  fetchProfile={this.props.fetchProfile}
                  data={snap}
                />
              )
            })}
        </div>
        <div ref={this.documentContainer}>
          {!isEmpty(data) &&
            data.hasOwnProperty('investigations') &&
            data.investigations.map((investigation, idx) => {
              let heading = ''
              if (filterDocumentElementsLength && idx === 0) {
                heading = (
                  <h5 className="mt-4">
                    Primary User Documents (
                    {`${get(accountData, 'account[0].first_name')}`}{' '}
                    {`${get(accountData, 'account[0].last_name')}`})
                  </h5>
                )
              } else if (filterDocumentElementsLength && idx === 1) {
                heading = (
                  <h5 className="mt-4">
                    Secondary User Documents (
                    {`${get(accountData, 'joint_user.joint_first_name')}`}{' '}
                    {`${get(accountData, 'joint_user.joint_last_name')}`})
                  </h5>
                )
              }
              if (!isEmpty(investigation.sketchId)) {
                return (
                  <Fragment key={idx}>
                    {heading}
                    <RequestedDocContainer
                      snaps={snaps}
                      apex_account_id={apex_account_id}
                      updateFinalReviewData={this.updateFinalReviewData}
                      account_no={apex_account_no}
                      data={investigation.sketchId}
                      onClickSave={this.onClickSave}
                      accountData={accountData}
                      key={idx}
                    />
                  </Fragment>
                )
              }
            })}
        </div>

        {this.documentContainer && filterDocumentElementsLength ? (
          <PrimaryButton
            type="button"
            disabled={isSubmitDisabled || this.state.loading}
            className="btn primary-btn"
            style={{ marginTop: '36px', marginBottom: '6px' }}
            onClick={this.onClickSave}
          >
            Save
          </PrimaryButton>
        ) : null}

        {/*<div>
          <p>
            {this.state.appeal}
          </p>
        </div>*/}
        {/*showAppealButton && showAppealButtonInvest && (
          <PrimaryButton
            type="button"
            className="btn primary-btn ml-2"
            style={{ marginTop: '36px', marginBottom: '6px' }}
            onClick={this.onClickAppeal}
          >
            Appeal
          </PrimaryButton>
        )*/}
        {renderAppealButton}
      </div>
    )
  }
}

export const RequestedDocContainer = ({
  snaps,
  updateFinalReviewData,
  data,
  account_no,
  apex_account_id,
  onClickSave,
  accountData
}) => {
  //data.cipCategories = []
  let Rows = []

  const [snapIDs, pushSnapIds] = useState([])

  let updateSnapID = obj => {
    pushSnapIds([...snapIDs, obj])
  }

  useEffect(() => {
    pushSnapIds([])
  }, [accountData])

  useEffect(() => {
    updateFinalReviewData({
      enabled: snapIDs.length > 0 && snapIDs.length === Rows.length,
      sketchId: data.id,
      snapIDs
    })
  }, [snapIDs])

  //render rows

  if (
    !isEmpty(data) &&
    typeof data.cipCategories !== 'undefined' &&
    data.cipCategories.length
  ) {
    let sketchId = data.id || null
    let cipCategories = data.cipCategories
    if (sketchId && sketchId !== '') {
      cipCategories.forEach((cat, id) => {
        // NOTE: RENDER DROPDOWN OPTION(cipCategories) FOR USER TO UPLOAD DOCUMENTS ['ADDRESS', 'NAME', 'DOB', 'TAX_ID', etc...]
        // Based on REJECTED state from 'cipCategories', Show Dropdown and for "APPROVED"/"UPLOADED" do-not show dropdown
        // ------------------------------------------------------------------------------------------------------------
        if (
          cat.categoryState === 'REJECTED' &&
          cat.requestedDocuments.length > 0
        ) {
          if (snaps && snaps.length) {
            const foundSnap = snaps.find(snap => snap.sketch_id === sketchId)
            if (foundSnap) {
              snaps.forEach((snap, idx) => {
                if (snap.sketch_id === sketchId && snap.doc_type === cat.name) {
                  if (snap.status === REJECTED) {
                    Rows.push(
                      <RequestedDocRow
                        key={idx}
                        apex_account_id={apex_account_id}
                        updateSnapID={updateSnapID}
                        data={cat}
                      />
                    )
                  }
                }
              })
            } else {
              console.log(
                `------ COULD'NT FIND SNAP/SKETCH_ID ${sketchId} IN SNAPS LIST, FOR ${cat.name} ------`
              )
              console.log('------ CHECK API RESPONSE ------')
              Rows.push(
                <RequestedDocRow
                  key={id}
                  apex_account_id={apex_account_id}
                  updateSnapID={updateSnapID}
                  data={cat}
                />
              )
            }
          } else {
            console.log('------ NO SNAPS FOUND ------')
            Rows.push(
              <RequestedDocRow
                key={id}
                apex_account_id={apex_account_id}
                updateSnapID={updateSnapID}
                data={cat}
              />
            )
          }
          // ------------------------------------------------------------------------------------------------------------

          // NOTE: SITESH'S LOGIC
          // ------------------------------------------------------------------------------------------------------------
          // snaps.findIndex(snap => snap.sketch_id === sketchId) > -1  && snaps.length ? snaps.forEach((snap,idx)=>{
          //   if(snap.sketch_id === sketchId && snap.doc_type === cat.name) {
          //     if(snap.status === REJECTED) {
          //       Rows.push(<RequestedDocRow key={idx} apex_account_id={apex_account_id} updateSnapID={updateSnapID} data={cat} />)
          //     }
          //   }
          // }) : Rows.push(<RequestedDocRow apex_account_id={apex_account_id} updateSnapID={updateSnapID} data={cat} />)
          // ------------------------------------------------------------------------------------------------------------

          // NOTE: PANKAJ'S LOGIC
          // ------------------------------------------------------------------------------------------------------------
          // /* NOTE: In case of 'REJECTED' , We need to check it in snaps response */
          // let _isCategoryIncludedInSnap = false;
          // snaps && snaps.length && snaps.forEach((snap,idx)=>{
          //   if( snap.doc_type === cat.name && snap.status === UPLOADED ){
          //     _isCategoryIncludedInSnap = true;
          //   }
          // })
          // if( !_isCategoryIncludedInSnap ){
          //   Rows.push(<RequestedDocRow key={id} apex_account_id={apex_account_id} updateSnapID={updateSnapID} data={cat} />)
          // }
          // ------------------------------------------------------------------------------------------------------------
        }
      })
    } else {
      console.log('------ SKETCH ID IS MISSING ------')
    }
  } else {
    console.log(`------ DATA IS EMPTY ------`)
  }

  return (
    <>
      {Rows.length ? <div className="row">{Rows}</div> : null}
      {/* {Rows.length>0 ?<PrimaryButton
      type="button"
      disabled = {snapIDs.length!==Rows.length}
      className="btn primary-btn"
      style={{ marginTop: '36px', marginBottom: '6px' }}
      onClick={() => onClickSave({
            sketchId: data.id,
            snapIDs
          })
      }
    >
      Save
    </PrimaryButton>:null} */}
    </>
  )
}

export const RequestedDocRow = ({ data, updateSnapID, apex_account_id }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [ddValue, setDDValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isUploadDisabled, updateButton] = useState(true)

  const onChangeHandler = async event => {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files
      let formData = new FormData()
      console.log(files[0])
      let fileSize = files[0].size / 1024 / 1024
      if (fileSize > FILE_SIZE) {
        setError(
          `The file ${files[0].name} is too big. (files must be under 15MB in size).`
        )
        return
      } else if (!SUPPORTED_FORMATS.includes(files[0].type)) {
        setError('Only .png,.jpg,.jpeg,.gif, image format are allowed')
        return
      }
      formData.append('file', files[0])
      formData.append('tag', 'AFFILIATED_APPROVAL')
      formData.append('account', apex_account_id)
      setLoading(true)
      try {
        let upload = await uploadReviewImage(formData)
        setLoading(false)
        updateSnapID({
          id: upload.record_id,
          type: data.name,
          proof: ddValue.name,
          status: UPLOADED
        })
      } catch (e) {
        setLoading(false)
        setError('Error')
      }
    }
  }

  const onChangeDD = doc => {
    setDDValue(doc)
    updateButton(false)
  }

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <div className="req-doc-row col">
      <div className="top mb-2 mt-2">
        <span className={'heading'}>{data.name}</span>
        <span class="alert-danger">{error}</span>
      </div>
      <div className="bottom d-flex">
        <Dropdown
          className={'dropdown-wrapper'}
          isOpen={dropdownOpen}
          toggle={toggle}
        >
          <DropdownToggle
            className={'toggle text-ellipsis'}
            style={{ maxWidth: '200px' }}
            caret
          >
            {ddValue.description || 'Select'}
          </DropdownToggle>
          <DropdownMenu>
            {data.requestedDocuments.map((doc, idx) => (
              <DropdownItem
                key={idx}
                value={doc}
                onClick={e => {
                  onChangeDD(doc)
                }}
              >
                {doc.description}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        <div style={{ borderBottom: 0 }} className={'upload_div ml-2 mt-1'}>
          <Input
            disabled={isUploadDisabled}
            type="file"
            name={data.name}
            id={data.name}
            onChange={onChangeHandler}
            placeholder="Upload document"
          />
          {/*<Label className={`label holoButton ${isUploadDisabled && 'disabled'}`} htmlFor={data.name}>Upload</Label>*/}
          {loading && <SpinnerR />}
        </div>
      </div>
    </div>
  )
}

export const SnapIdRow = ({ data, fetchProfile }) => {
  const [loading, setLoading] = useState(false)
  const status = data.status

  const [modalState, updateModalState] = useState([
    {
      modalHeading: 'Are you sure?',
      modalBtn1: 'Yes',
      modalBtn2: 'No',
      showModal: false,
      classType: '',
      action: '',
      id: ''
    }
  ])

  let [comment, setComment] = useState('')

  const name = `${data.doc_type}_${data.proof}_${data.snap_id}`

  const onDownloadSnap = async () => {
    try {
      setLoading(true)
      let download = await downloadSnap({ snap: data.snap_id })
      setLoading(false)
      //console.log(btoa(download.data));

      // var imgsrc = 'data:image/png;base64,' + btoa(unescape(encodeURIComponent(download.data)));
      // console.log(imgsrc);

      const newBlob = new Blob([download.data], {
        type: download.headers['content-type']
      })
      const extension = download.headers['content-type'].split('/')[1]

      var reader = new FileReader()

      // reader.readAsDataURL(newBlob);
      // reader.onloadend = function() {
      //   var base64data = reader.result;
      //   setTestImg(base64data)
      // }
      FileSaver.saveAs(newBlob, `${name}.${extension}`)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const onUploadSnap = async () => {}

  const onUpdateSnapStatus = async status => {
    try {
      setLoading(true)
      let payload = {
        comment: comment,
        snapId: data.snap_id,
        status: status
      }

      let update = await updateSnapStatus(payload)
      fetchProfile()
      setLoading(false)
      console.log(update)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  let showConfirmation = () => {
    updateModalState({
      showModal: true
    })
  }

  let hideModal = () => {
    modalState[0].showModal = false
    modalState[0].action = ''
    modalState[0].id = ''
    updateModalState([...modalState])
  }

  let showModal = action => {
    modalState[0].showModal = true
    modalState[0].action = action
    modalState[0].classType = action
    modalState[0].placeholder = 'Please enter a reason'
    updateModalState([...modalState])
    setComment('')
  }

  let handleModalAction = () => {
    modalState[0].showModal = false
    updateModalState([...modalState])
    onUpdateSnapStatus(modalState[0].action)
  }

  let handleChange = event => {
    const target = event.target
    const value = target.value

    setComment(value)
  }

  return (
    <>
      <div
        style={{ padding: `10px 0`, border: `1px solid #b3b3b3` }}
        className="d-flex justify-content-between document-row"
      >
        <div>{name}</div>

        <div>
          {loading && <SpinnerR />}
          <span>
            <i>{status}</i>
          </span>
          {status === UPLOADED && (
            <button onClick={() => showModal(APPROVED)}>Approve</button>
          )}
          {status === UPLOADED && (
            <button onClick={() => showModal(REJECTED)}>Reject</button>
          )}
          {status !== REJECTED && (
            <button onClick={onDownloadSnap}>Download</button>
          )}
        </div>
      </div>

      <>
        {modalState[0].showModal && (
          <ConfirmModal
            heading={modalState[0].modalHeading}
            type={modalState[0].classType}
            btn1={modalState[0].modalBtn1}
            btn2={modalState[0].modalBtn2}
            closeModal={() => hideModal()}
            handleModalAction={() => handleModalAction()}
            userInput={true}
            placeholder={modalState[0].placeholder}
            onChange={e => handleChange(e)}
            userInputArea={comment}
          />
        )}
      </>
    </>
  )
}

export const AccountDocRow = ({ data, fetchProfile }) => {
  const [loading, setLoading] = useState(false)

  const name = `${data.doc_type}_${data.snap_id}`

  const onDownloadSnap = async () => {
    try {
      setLoading(true)
      let download = await downloadSnap({ snap: data.snap_id })
      setLoading(false)

      const newBlob = new Blob([download.data], {
        type: download.headers['content-type']
      })
      const extension = download.headers['content-type'].split('/')[1]

      var reader = new FileReader()
      FileSaver.saveAs(newBlob, `${name}.${extension}`)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  return (
    <div
      style={{ padding: `10px 0`, border: `1px solid #b3b3b3` }}
      className="d-flex justify-content-between document-row"
    >
      <div>{name}</div>

      <div>
        {loading && <SpinnerR />}
        <button onClick={onDownloadSnap}>Download</button>
      </div>
    </div>
  )
}
