import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Space, Typography, Popconfirm } from 'antd';
import { WarningFilled, InfoCircleFilled } from '@ant-design/icons';
import { closeAccountRequest } from 'actions/account';
import { createLoadingSelector } from 'selectors/loading-selector'
import './MoreTab.scss';

const { Text } = Typography;

class MoreTab extends Component {
  closeAccount = () => {
    const { selectedAccount, closeAccountRequest } = this.props;
    if (closeAccountRequest && selectedAccount && typeof selectedAccount.user_id !== 'undefined') {
      // console.log(selectedAccount);
      closeAccountRequest({
        // request_type: 'CLOSE_ACCOUNT',
        account_id: selectedAccount.user_id,
      });
    }
  }

  render () {
    const { selectedAccount, closeAccountStatus, loading } = this.props;
    if (!selectedAccount || closeAccountStatus === "APPROVED") return null;

    return (
      <div className="more-tab-container">
        {(closeAccountStatus === "SENT" || closeAccountStatus === "INPROGRESS") ? (
          <Alert
            type="warning"
            message={(
              <Text strong type="danger">Close Account</Text>
            )}
            description={(
              <Text>
                Request for terminating <strong>{`${selectedAccount.name}(${selectedAccount.email})`}</strong> account has been sent.
              </Text>
            )}
            action={(
              <Space direction="vertical">
                <Button danger disabled type="primary" loading={loading}>
                  CLOSE ACCOUNT
                </Button>
              </Space>
            )}
            icon={<WarningFilled />}
            showIcon
          />
        ) : (
          <Alert
            type="error"
            message={(
              <Text strong type="danger">Close Account</Text>
            )}
            description={(
              <Text>
                Clicking "Close Account" button will terminate <strong>{`${selectedAccount.name}(${selectedAccount.email})`}</strong> account permanently.
                <br />
                This action cannot be undone.
              </Text>
            )}
            action={(
              <Space direction="vertical">
                <Popconfirm
                  okText="Yes"
                  title="Are you sure"
                  okButtonProps={{ style: { background: '#1E5F91', borderColor: '#1E5F91' } }}
                  icon={<InfoCircleFilled />}
                  onConfirm={this.closeAccount}
                >
                  <Button danger type="primary" loading={loading}>
                    CLOSE ACCOUNT
                  </Button>
                </Popconfirm>
              </Space>
            )}
            icon={<WarningFilled />}
            showIcon
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ account, loading }) => ({
  closeAccountStatus: account.closeAccountStatus,
  loading: createLoadingSelector(['CLOSE_ACCOUNT'])({ loading }),
});

const mapDispatchToProps = {
  closeAccountRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoreTab);
