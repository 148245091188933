
import config from './env'

export const CN = {
  'Id': 'id',
  'Ticker': 'ticker',
  'Ticker Short': 'ticker_short',
  'Name': 'name',
  'Name2': 'short_name',
  'Vehicle': 'vehicle',
  'Category': 'category',
  'Asset Class': 'asset_class',
  'Philosophy': 'philosophy',
  'Outcomes': 'outcomes',
  'Liquidity': 'liquidity',
  'LiquidityQ': 'quintile_liquidity',
  'Liquidity Pctile': 'liquidity_pctile',
  'Min Investment': 'minimum_investment',
  'Assets': 'aum',
  'AssetsQ': 'quintile_assets ',
  'Assets Pctile': 'assets_pctile',
  'Nav Price': 'nav',
  'Fees': 'fees',
  'FeesQ': 'quintile_fees',
  'Vehicle2': 'vehicle2',
  'Sponsor': 'sponsor',
  'Region': 'region',
  'U.S': 'North America',
  'Developed Market': 'Developed Markets',
  'Emerging Market': 'Emerging Markets',
  'Cash': 'Cash',
  'Consumer Cyclical': 'Consumer Cyclical',
  'Consumer Non-cyclical': 'Consumer Non-cyclical',
  'Energy': 'energy',
  'Financial': 'financials',
  'Industrial': 'industrials',
  'Technology': 'information_technology',
  'Cash2': 'cash2',
  'Communications': 'communication_services',
  'Utilities': 'utilities',
  'Sponsored': 'sponsored',
  'Diversified': 'Diversified',
  'Government': 'Government',
  'Funds': 'Funds',
  'Asset Backed Securities': 'asset_backed_security',
  'Mortgage Securities': 'mortgage_backed_security',
  'Commodity': 'Commodity',
  'Currencies': 'currencies',
  'Fixed Income': 'fixed_income',
  'Money Market': 'money_market',
  'Alternative': 'alternatives',
  'Mixed Allocation': 'multi_asset',
  'Diversified Equities': 'Diversified Equities',
  'Health Care': 'health_care',
  'Real Estate': 'real_estate',
  'Materials': 'materials',
  'Consumer Discretionary': 'consumer_discretionary',
  'Thematic': 'Thematic',
  'Consumer Staples': 'consumer_staples',
  'Not Classified - Non Equity': 'not_classified_non_equity',
  'Non Classified Equity': 'non_classified_equity',
  'Other Sectors': 'others_non_classified',
  'description': 'description',
  'short_desc': 'short_desc',
  'sharesout': 'sharesout',
  'underlying_index': 'underlying_index',
  'thm_txt': 'thm_txt',
  'value_stk': 'value_stk',
  'index_search_text': 'index_search_text',
  'Bonds': 'bonds',
  'Cash': 'cash',
  'Commodities': 'commodities',
  'Derivatives': 'derivatives',
  'Equities': 'equities',
  'Other': 'other',
  'Volume': 'attribute/technical/volume',
  'Yield': 'weight_yield',
  'Bid Ask spread': 'weight_bid_ask',
  'Track record': 'weight_track_record',
  'Premium to NAV': 'weight_premium_to_nav',
  'Premium to NAV 1yr': 'premium_discount_1y',
  'Premium to NAV 3yr': 'premium_discount_3y',
  'Premium to NAV 5yr': 'premium_discount_5y',
  'Trend': 'weight_trend',
  'Trend 1yr': 'trend_1y',
  'Trend 3yr': 'trend_3y',
  'Trend 5yr': 'trend_5y',
  'Fund Flows': 'weight_fund_flows',
  'Fund Flows 1yr': 'fund_flow_1y',
  'Fund Flows 3yr': 'fund_flow_3y',
  'Fund Flows 5yr': 'fund_flow_5y',
  // 'Price to Book': 'weight_risk_adjusted_returns',
  // 'Risk Adjusted Returns':' weight_price_to_book',
  'Manager Tenure': 'manager_tenure',
  'Expense Ratio': 'expense_ratio',
  'Median Expense Ratio': 'median_expense_ratio',
  'aumAC': 'aum',
  'Up Capture 5yr': 'upside_capture_5y',
  'Up Capture 10yr': 'upside_capture_10y',
  'Down Capture 5yr': 'downside_capture_5y',
  'Down Capture 10yr': 'downside_capture_10y',
  'Outperformance 3yr': 'outperformance_3y',
  'Outperformance 5yr': 'outperformance_5y',
  'Outperformance 10yr': 'outperformance_10y',
  'Alpha 5yr': 'alpha_5y',
  'Alpha 10yr': 'alpha_10y',
  'Min Expense Ratio': 'min_expense_ratio',
  'Expense Ratio 25pct': 'expense_ratio_25pct',
  'Expense Ratio 75pct': 'expense_ratio_75pct',
  'Max Expense Ratio': 'max_expense_ratio',
};
export const cn = (obj, attr) => obj[CN[attr]];

const suffix = ['', 'K', 'M', 'B'];
export const kmb = (val, i = 0) => ((val > -100 && val < 100) || i > 2) ? `$${val.toFixed(1)}${suffix[i]}` : kmb(val / 1000, i + 1);

export const
  ReservedRoutes = [
    '/register-complete',
    '/agree-terms',
    '/account-confirm',
  ],

  PREMIUM_ACCESS = [
    'ankit@opensesafi.com',
    'pratik@magnifi.com',
    'pratik@opensesafi.com',
    'vinay@magnifi.com',
    'vinay@opensesafi.com',
    'vinay@tifin.com',
  ],

  KNOWHOSTARR = [
    'demoyadav.magnifi.com',
    'devetfmf.magnifi.com',
    'devetf.magnifi.com',
    'test.opensesafi.com',
    'test2.opensesafi.com',
    'test3.opensesafi.com',
    'cheeslings.opensesafi.com',
    'cheeslings2.opensesafi.com',
    'demo.opensesafi.com',
    'demo.magnifi.com',
    'discover.magnifi.com',
    'localhost',
    '192.168.10.172',
    'idart-533b6.firebaseapp.com'
  ],

  DefaultYears = 3,
  DateRange = {
    start: new Date(2008, 0, 1),
    end: new Date(2019, 11, 1)
  }

  , FilterAttrData = [
    { name: 'Vehicle', col: 'vehicle' },
    { name: 'Sponsor', col: 'sponsor' },
    { name: 'Asset Class', col: 'asset_class' },
    { name: 'Philosophy', col: 'philosophy' },
  ]

  , SortAttrs = [
    { name: 'Return', col: '_returns', reverse: true },
    { name: 'Fees', col: 'fees', reverse: false },
    { name: 'Risk', col: '_risk', reverse: false },
    { name: 'Volume', col: 'attribute/technical/volume', reverse: true },
    { name: 'Assets', col: 'aum', reverse: true }
  ]
  , DefaultSortAttr = ''

  , ViewNames = [
    { name: 'Regions', display: true },
    { name: 'Sectors', display: true },
    { name: 'Assets', display: true },
    { name: 'Volume', display: true },
    { name: 'Top Holdings', display: true },
    { name: 'Asset Allocation', display: true },
    { name: 'Themes', display: true },
    // { name: '% Held',  display: false }, //name changed to dynamic
    { name: 'Diversification', display: true },
    { name: 'Return Quality', display: true },
    { name: '1 Year Yield', display: true },
    { name: 'Fiduciary Risk', display: true },
  ]
  , QuinItems = [
    { name: 'Fees', col: 'fees', qcol: 'feesq', reverse: true },
    { name: 'Volume', col: 'attribute/technical/volume', qcol: 'liquidityq', pcCol: 'liquidity_percentile', reverse: false },
    { name: 'Assets', col: 'aum', qcol: 'assetsq', pcCol: 'aum_percentile', reverse: false },
    { name: 'Return', col: '_stats.yRet', qcol: '_stats.yRetQ', reverse: false },
    { name: 'Risk', col: '_stats.volt', qcol: '_stats.voltQ', reverse: true },
    { name: 'Impact', col: 'esgScore', qcol: 'esgScoreq', reverse: false },
  ]

  , AllocationAttrs = ['U.S', 'Developed Market', 'Emerging Market', 'Cash']
  , SectorsAttrs = [
    'Communications',
    // 'Consumer Cyclical',
    'Consumer Discretionary',
    // 'Consumer Non-cyclical',
    'Consumer Staples',
    // 'Diversified',
    // 'Diversified Equities',
    'Energy',
    'Financial',
    'Health Care',
    'Industrial',
    'Materials',
    'Real Estate',
    'Technology',
    // 'Thematic',
    'Utilities',
    // 'Non Classified Equity',
    // 'Not Classified - Non Equity'
    'Other Sectors',
    // 'Others Non Classified',
  ]
  , AssetType = [
    'Bonds',
    'Cash',
    'Commodities',
    'Currencies',
    'Derivatives',
    'Equities',
    'Other',
  ]

  , CategoryToCol = {
    'Developed Market Equity': 'DM_Equity',
    'US Equity': 'US_Equity',
  }

  , ScoreAttrs = [{ name: 'Return', yearDropdown: true, col: 'yRet', code: 'return', colOff: 'yRetOff', inv: false, suffix: '%', weight: '', tooltip: 'Annualized returns generated by the fund for the selected time period', default: true },
  { name: 'Risk', yearDropdown: true, col: 'risk', code: 'risk', colOff: 'riskOff', inv: true, suffix: '%', weight: '', tooltip: 'Annualized volatility of the funds returns for the selected time period', default: true },
  { name: 'Fee', yearDropdown: false, col: 'fees', code: 'fees', colOff: 'feesOff', inv: true, suffix: '%', weight: '', tooltip: 'The total expense ratio charged by the Fund', default: true }]

  , AdditionalScoreAttrs = [
    { name: 'Assets', yearDropdown: false, col: 'aum', code: 'aum', colOff: 'assetsOff', inv: false, suffix: '', parser: val => kmb(val || 0), weight: '', tooltip: 'Size of the fund denoted by the total assets managed' },
    { name: 'Volume', yearDropdown: false, col: 'vol', code: 'volume', colOff: 'volumeOff', inv: false, suffix: '', parser: val => kmb(val || 0), weight: '', tooltip: 'Liquidity of the fund denoted by the average daily volume traded. Relevant for only ETFs' },
    { name: 'Tracking Error', yearDropdown: false, col: 'trkerror', code: 'trkerror', colOff: 'trkerrorOff', inv: true, suffix: '%', weight: '', tooltip: 'Tracking error tells us how much a fund deviates from its benchmark. Tracking error is relevent for ETFs and passive mutual funds.' },
    { name: 'Yield', yearDropdown: false, col: 'weight_yield', code: 'weight_yield', colOff: 'weight_yieldOff', inv: false, suffix: '%', weight: '', tooltip: 'Yield is a measure of cash flow generated by an investment' },
    { name: 'Bid-Ask Spread', yearDropdown: false, col: 'weight_bid_ask', code: 'weight_bid_ask', colOff: 'weight_bid_askOff', inv: true, suffix: '%', weight: '', tooltip: 'Bid-Ask spread is a measure of liquidity. It is the difference between the highest price that a buyer is willing to pay for an asset and the lowest price that a seller is willing to accept' },
    { name: 'Track Record', yearDropdown: false, col: 'weight_track_record', code: 'weight_track_record', colOff: 'weight_track_recordOff', inv: false, suffix: 'yrs', weight: '', tooltip: 'The number of years the fund has been active' },
    { name: 'Premium to NAV', yearDropdown: true, col: 'weight_premium_to_nav', code: 'weight_premium_to_nav', colOff: 'weight_premium_to_navOff', inv: true, suffix: '%', weight: '', multiplier: 100, tooltip: 'The difference between the traded price of a security and its Net Asset Value (NAV) as a fraction of the NAV' },
    { name: 'Trend', yearDropdown: true, col: 'weight_trend', code: 'weight_trend', colOff: 'weight_trendOff', inv: false, suffix: '%', weight: '', multiplier: 100, tooltip: 'Trend is an indicator which tells us the strength of the price movement over the past year' },
    { name: 'Fund Flows', yearDropdown: true, col: 'weight_fund_flows', code: 'weight_fund_flows', colOff: 'weight_fund_flowsOff', inv: false, suffix: '', parser: val => kmb(val || 0), weight: '', tooltip: 'The change in assets managed by the fund over the last 12 months' },
    /*{ name: 'Price to Book', col: 'weight_risk_adjusted_returns', code: 'weight_risk_adjusted_returns', colOff: 'weight_risk_adjusted_returnsOff', inv: false, suffix: '%', weight: '', tooltip: 'Total market capitalization of the holdings / Total book value of the holdings' },
    { name: 'Risk Adjusted Returns', col: 'weight_price_to_book', code: 'weight_price_to_book', colOff: 'weight_price_to_bookOff', inv: true, suffix: '%', weight: '', tooltip: 'The ratio is the return earned per unit of risk' },*/
  ]

  , ActiveCalcScoreAttrs = [
    { name: 'Manager Tenure', yearDropdown: false, col: 'manager_tenure', code: 'manager_tenure', colOff: 'manager_tenureOff', inv: false, suffix: 'Yrs.', weight: '', tooltip: 'Tenure of the current fund manager' },
    { name: 'Expense Ratio', yearDropdown: false, col: 'expense_ratio', code: 'expense_ratio', colOff: 'expense_ratioOff', inv: true, suffix: '%', weight: '', tooltip: 'The total expense ratio charged by the investment vehicle' },
    { name: 'AUM', yearDropdown: false, col: 'aumAC', code: 'aumAC', colOff: 'aumACOff', inv: false, suffix: '', parser: val => kmb(val || 0), weight: '', tooltip: 'Size of the investment vehicle defined as the total assets managed' },
    { name: 'Up Capture 5yr', yearDropdown: false, col: 'upside_capture_5y', code: 'upside_capture_5y', colOff: 'upside_capture_5yOff', inv: false, suffix: '%', weight: '', tooltip: 'Up capture tells us if the security has gained more than the benchmark during periods of market strength' },
    { name: 'Up Capture 10yr', yearDropdown: false, col: 'upside_capture_10y', code: 'upside_capture_10y', colOff: 'upside_capture_10yOff', inv: false, suffix: '%', weight: '', tooltip: 'Up capture tells us if the security has gained more than the benchmark during periods of market strength' },
    { name: 'Down Capture 5yr', yearDropdown: false, col: 'downside_capture_5y', code: 'downside_capture_5y', colOff: 'downside_capture_5yOff', inv: true, suffix: '%', weight: '', tooltip: 'Down capture tells us if the security has lost more than the benchmark during periods of market weakness' },
    { name: 'Down Capture 10yr', yearDropdown: false, col: 'downside_capture_10y', code: 'downside_capture_10y', colOff: 'downside_capture_10yOff', inv: true, suffix: '%', weight: '', tooltip: 'Down capture tells us if the security has lost more than the benchmark during periods of market weakness' },
    { name: 'Outperformance 3yr', yearDropdown: false, col: 'outperformance_3y', code: 'outperformance_3y', colOff: 'outperformance_3yOff', inv: false, suffix: '%', weight: '', tooltip: 'Excess return over the average category return over the last 3 years' },
    { name: 'Outperformance 5yr', yearDropdown: false, col: 'outperformance_5y', code: 'outperformance_5y', colOff: 'outperformance_5yOff', inv: false, suffix: '%', weight: '', tooltip: 'Excess return over the average category return over the last 5 years' },
    { name: 'Outperformance 10yr', yearDropdown: false, col: 'outperformance_10y', code: 'outperformance_10y', colOff: 'outperformance_10yOff', inv: false, suffix: '%', weight: '', tooltip: 'Excess return over the average category return over the last 10 years' },
    { name: 'Alpha 5yr', yearDropdown: false, col: 'alpha_5y', code: 'alpha_5y', colOff: 'alpha_5yOff', inv: false, suffix: '%', weight: '', tooltip: 'Excess return over the expected return given the systematic risk over the last 5 years' },
    { name: 'Alpha 10yr', yearDropdown: false, col: 'alpha_10y', code: 'alpha_10y', colOff: 'alpha_10yOff', inv: false, suffix: '%', weight: '', tooltip: 'Excess return over the expected return given the systematic risk over the last 10 years' },
  ]

  , D3Colors20 = ["#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78", "#2ca02c", "#98df8a", "#d62728", "#ff9896", "#9467bd", "#c5b0d5",
    "#8c564b", "#c49c94", "#e377c2", "#f7b6d2", "#7f7f7f", "#c7c7c7", "#bcbd22", "#dbdb8d", "#17becf", "#9edae5"]
  , TickColors = ["#ff7f0e", "#2ca02c"]
  , D3Colors09 = ["#609f60", "#7d91b5", "#C97CAC", "#568dab", "#81a83a", "#907060", "#A884C3", "#6b5e82", "#648a20", "#E3795C"]
  , D3AppColors09 = ["#83aed4", "#8592c9", "#ab90e0", "#d59af5", "#d1799e", "#e6b58a", "#e07777"]
  , RankColors = ['#EEE4CA', '#EAEAEA', '#D3D0C6', '#F9F9F9']
  , Brokers = [
    { name: 'Charles Schwab', logo: '/assets/images/brokers/schwab.png', color: '#54B0E1' },
    { name: 'Fidelity', logo: '/assets/images/brokers/fidelity.png', color: '#007156' },
    { name: 'Raymond James', logo: '/assets/images/brokers/raymond.png', color: '#102B76' },
    { name: 'ETrade', logo: '/assets/images/brokers/etrade.png', color: '#6633cc', parent: 'TradeIT' },
    { name: 'TD Ameritrade', logo: '/assets/images/brokers/dummy.png', color: '#50b948' },
    { name: 'dummy', logo: '/assets/images/brokers/dummy.png', color: '#777777' },
    { name: 'Robinhood', logo: '/assets/images/brokers/robinhood.png', color: '#21ce99', parent: 'TradeIT' },
    { name: 'Dummy', logo: '/assets/images/brokers/dummy.png', color: '#21ce99', parent: 'TradeIT' },
    { name: 'Vanguard', logo: '/assets/images/brokers/vanguard.png', color: '#21ce99', parent: 'TradeIT' },
    { name: 'TradeStation', logo: '/assets/images/brokers/tradestation.png', color: '#21ce99', parent: 'TradeIT' },
    { name: 'Tradier', logo: '/assets/images/brokers/tradier.png', color: '#21ce99', parent: 'TradeIT' },
    { name: 'Just2Trade', logo: '/assets/images/brokers/just2trade.png', color: '#21ce99', parent: 'TradeIT' },
    { name: 'Interactive Brokers', logo: '/assets/images/brokers/interactivebrokers.png', color: '#21ce99', parent: 'TradeIT' },
    { name: 'Ally', logo: '/assets/images/brokers/ally.png', color: '#21ce99', parent: 'TradeIT' },
  ],
  BrokersComms = [
    { commission: 6.95, name: "TD Ameritrade", "fees": 0 },
    { commission: 4.95, name: "Fidelity", "fees": 0 },
    { commission: 0.0, name: "Dummy" },
    { commission: 0.0, name: "Ally" },
    { commission: 0.0, name: "Just2Trade" },
    { commission: 0.0, name: "LPL" },
    { commission: 0.0, name: "Tradier" },
    { commission: 0.0, name: "Robinhood" },
    { commission: 4.95, name: "Charles Schwab" },
    { commission: 12.95, name: "Raymond James" },
    { commission: 0.0, name: "Vanguard" },
    { commission: 4.95, name: "ETrade" }
  ],
  BrokersList = [
    {
      name: "tdameritrade",
      displayName: "TD Ameritrade",
      imgSrc: `/assets/images/brokers/ameritrade.png`,
      disabled: false
    },
    // {
    //   name: "robinhood",
    //   displayName: "Robinhood",
    //   imgSrc: `/assets/images/brokers/robinhood.png`,
    //   disabled: false
    // },
    // {
    //   name: "vanguard",
    //   displayName: "Vanguard",
    //   imgSrc: `/assets/images/brokers/vanguard.png`,
    //   disabled: true
    // },
    // {
    //   name: "etrade",
    //   displayName: "Etrade",
    //   imgSrc: `/assets/images/brokers/etrade.png`,
    //   disabled: false
    // },
    // {
    //   name: "tradier",
    //   displayName: "Tradier",
    //   imgSrc: `/assets/images/brokers/tradier.png`,
    //   disabled: false
    // },
    // {
    //   name: "just2trade",
    //   displayName: "Just2Trade",
    //   imgSrc: `/assets/images/brokers/just2trade.png`,
    //   disabled: false
    // },
    // {
    //   name: "fidelity",
    //   displayName: "Fidelity",
    //   imgSrc: `/assets/images/brokers/fidelity.png`,
    //   disabled: true
    // },
    // {
    //   name: "ally",
    //   displayName: "Ally",
    //   imgSrc: `/assets/images/brokers/ally.png`,
    //   disabled: false
    // },
    // {
    //   name: "tradestation",
    //   displayName: "Tradestation",
    //   iimgSrc: `/assets/images/brokers/tradestation.png`,
    //   disabled: true
    // },
    // {
    //   name: "interactivebrokers",
    //   displayName: "InteractiveBrokers",
    //   imgSrc: `/assets/images/brokers/interactivebrokers.png`,
    //   disabled: true
    // }, {
    //   name: "charles-schwab",
    //   displayName: "Charles SCHWAB",
    //   imgSrc: `/assets/images/brokers/charlesschwab.png`,
    //   disabled: true
    // }, {
    //   name: "raymond-james",
    //   displayName: "RAYMOND JAMES",
    //   imgSrc: `/assets/images/brokers/raymondjames.png`,
    //   disabled: true
    // }, {
    //   {name: "Dummy",
    //   displayName: "DUMMY",
    //   imgSrc: `/assets/images/brokers/dummy.png`,
    //   disabled: false
    // }
  ]
  , sponsorLogo = (sponsor) => {
    let path = '/assets/images/sponsors/';
    switch (sponsor.toLowerCase()) {
      case 'vanguard':
        return path + 'vanguard-h30.png';
      default:
        return path + sponsor + '.png';
    }
  }, PopularThemes = [
    { image: '3D Printing.png', label: '3D Printing' },
    // { image: 'Adult Entertainment.png', label: 'Adult Entertainment' },
    { image: 'Big Data.png', label: 'Big Data' },
    { image: 'Bitcoin.png', label: 'Bitcoin' },
    { image: 'Blockchain.png', label: 'Blockchain' },
    { image: 'Cancer Treatment.png', label: 'Cancer Treatment' },
    { image: 'Catholic.png', label: 'Catholic' },
    { image: 'Clean Energy.png', label: 'Clean Energy' },
    { image: 'Cleantech.png', label: 'Cleantech' },
    { image: 'Climate Change.png', label: 'Climate Change' },
    { image: 'Cloud Computing.png', label: 'Cloud Computing' },
    { image: 'Coal.png', label: 'Coal' },
    { image: 'Coins.png', label: 'Coins' },
    { image: 'Cybersecurity.png', label: 'Cybersecurity' },
    { image: 'Demographics.png', label: 'Demographics' },
    { image: 'Drip Irrigation.png', label: 'Drip Irrigation' },
    { image: 'Driverless Cars.png', label: 'Driverless Cars' },
    { image: 'Drone.png', label: 'Drone' },
    { image: 'em.png', label: 'Emerging Markets' },
    { image: 'Entertainment.png', label: 'Entertainment' },
    { image: 'Entrepreneurship.png', label: 'Entrepreneurship' },
    { image: 'Environmental.png', label: 'Environmental' },
    { image: 'fintech.png', label: 'Fintech' },
    { image: 'Food.png', label: 'Food' },
    { image: 'Forestry.png', label: 'Forestry' },
    { image: 'Gaming.png', label: 'Gaming' },
    { image: 'Geothermal.png', label: 'Geothermal' },
    { image: 'Health Tech.png', label: 'Health Tech' },
    { image: 'Home Ownership.png', label: 'Home Ownership' },
    { image: 'Human Rights.png', label: 'Human Rights' },
    { image: 'Insure Tech.png', label: 'Insure Tech' },
    { image: 'IOT.png', label: 'IOT' },
    { image: 'Leisure.png', label: 'Leisure' },
    { image: 'LGBT.png', label: 'LGBT' },
    { image: 'Logistics.png', label: 'Logistics' },
    { image: 'Luxury.png', label: 'Luxury' },
    { image: 'Marijuana.png', label: 'Marijuana' },
    { image: 'Medical Devices.png', label: 'Medical Devices' },
    { image: 'Military Equipment.png', label: 'Military Equipment' },
    { image: 'Military.png', label: 'Military' },
    { image: 'Millenials.png', label: 'Millenials' },
    { image: 'Music.png', label: 'Music' },
    { image: 'Organic.png', label: 'Organic' },
    { image: 'Outsourcing.png', label: 'Outsourcing' },
    { image: 'Payments.png', label: 'Payments' },
    { image: 'Pets.png', label: 'Pets' },
    { image: 'Precision Agriculture.png', label: 'Precision Agriculture' },
    { image: 'Robotics.png', label: 'Robotics' },
    { image: 'Seniors.png', label: 'Seniors' },
    { image: 'Sensors.png', label: 'Sensors' },
    { image: 'Shale.png', label: 'Shale' },
    { image: 'Smartphones.png', label: 'Smartphones' },
    { image: 'Social.png', label: 'Social' },
    { image: 'Solar Energy.png', label: 'Solar Energy' },
    { image: 'Sports.png', label: 'Sports' },
    { image: 'Stemcell.png', label: 'Stemcell' },
    { image: 'Streaming.png', label: 'Streaming' },
    { image: 'Telecommunications.png', label: 'Telecommunications' },
    { image: 'Travel.png', label: 'Travel' },
    { image: 'Vegan.png', label: 'Vegan' },
    { image: 'Virtual Reality.png', label: 'Virtual Reality' },
    { image: 'Waste Management.png', label: 'Waste Management' },
    { image: 'Water.png', label: 'Water' },
    { image: 'Wearable Tech.png', label: 'Wearable Tech' },
    { image: 'Wellness.png', label: 'Wellness' },
    { image: 'Wind Energy.png', label: 'Wind Energy' },
    { image: 'Women.png', label: 'Women' },
  ], FailureQuery = {
    company: 'ibm',
    region: 'emerging markets',
    sector: 'utilities',
    theme: 'fintech',
    assetType: 'equities',
    fund: 's&p 500 etf',
    objective: 'retirement',
  }, SeoLinks = [{ name: "FB", query: "holds FB" },
  { name: "AAPL", query: "holds AAPL" },
  { name: "CAT", query: "holds CAT" },
  { name: "GM", query: "holds GM" },
  { name: "LULU", query: "holds LULU" },
  { name: "TSLA", query: "holds TSLA" },
  { name: "C", query: "holds C" },
  { name: "GOOG", query: "holds GOOG" },
  { name: "T", query: "holds T" },
  { name: "V", query: "holds V" },
  { name: "MSFT", query: "holds MSFT" },
  { name: "AMD", query: "holds AMD" },
  { name: "GPS", query: "holds GPS" },
  { name: "NVDA", query: "holds NVDA" },
  { name: "BAC", query: "holds BAC" },
  { name: "DISH", query: "holds DISH" },
  { name: "NFLX", query: "holds NFLX" },
  { name: "GE", query: "holds GE" },
  { name: "IBM", query: "holds IBM" },
  { name: "INTC", query: "holds INTC" },
  { name: "SPY", query: "funds similar to SPY" },
  { name: "QQQ", query: "funds similar to QQQ" },
  { name: "SMH", query: "funds similar to SMH" },
  { name: "VTI", query: "funds similar to VTI" },
  { name: "DIA", query: "funds similar to DIA" },
  { name: "TQQQ", query: "funds similar to TQQQ" },
  { name: "JNUG", query: "funds similar to JNUG" },
  { name: "TVIX", query: "funds similar to TVIX" },
  { name: "UVXY", query: "funds similar to UVXY" },
  { name: "SH", query: "funds similar to SH" },
  { name: "agriculture", query: "agriculture" },
  { name: "drip_irrigation", query: "drip_irrigation" },
  { name: "precision_agriculture", query: "precision_agriculture" },
  { name: "blockchain", query: "blockchain" },
  { name: "buybacks", query: "buybacks" },
  { name: "cars", query: "cars" },
  { name: "driverless_cars", query: "driverless_cars" },
  { name: "electric", query: "electric" },
  { name: "coal", query: "coal" },
  { name: "cybersecurity", query: "cybersecurity" },
  { name: "demographics", query: "demographics" },
  { name: "baby_boomers", query: "baby_boomers" },
  { name: "gen_x", query: "gen_x" },
  { name: "millenials", query: "millenials" },
  { name: "seniors", query: "seniors" },
  { name: "ecommerce", query: "ecommerce" },
  { name: "entertainment", query: "entertainment" },
  { name: "gaming", query: "gaming" },
  { name: "music", query: "music" },
  { name: "sports", query: "sports" },
  { name: "streaming", query: "streaming" },
  { name: "entrepreneurship", query: "entrepreneurship" },
  { name: "esg", query: "esg" },
  { name: "development", query: "development" },
  { name: "environmental", query: "environmental" },
  { name: "clean_energy", query: "clean_energy" },
  { name: "cleantech", query: "cleantech" },
  { name: "climate_change", query: "climate_change" },
  { name: "fossil_free", query: "fossil_free" },
  { name: "renewable_energy", query: "renewable_energy" },
  { name: "geothermal", query: "geothermal" },
  { name: "hydro", query: "hydro" },
  { name: "nuclear", query: "nuclear" },
  { name: "solar_energy", query: "solar_energy" },
  { name: "wind_energy", query: "wind_energy" },
  { name: "water", query: "water" },
  { name: "governance", query: "governance" },
  { name: "social", query: "social" },
  { name: "catholic", query: "catholic" },
  { name: "employee_treatment", query: "employee_treatment" },
  { name: "gender", query: "gender" },
  { name: "global_goals", query: "global_goals" },
  { name: "human_rights", query: "human_rights" },
  { name: "lgbt", query: "lgbt" },
  { name: "women", query: "women" },
  { name: "food", query: "food" },
  { name: "organic", query: "organic" },
  { name: "qsr", query: "qsr" },
  { name: "precision_medicine", query: "precision_medicine" },
  { name: "home_ownership", query: "home_ownership" },
  { name: "innovation", query: "innovation" },
  { name: "ipos", query: "ipos" },
  { name: "leisure", query: "leisure" },
  { name: "logistics", query: "logistics" },
  { name: "luxury", query: "luxury" },
  { name: "manufacturing", query: "manufacturing" },
  { name: "military", query: "military" },
  { name: "military_equipment", query: "military_equipment" },
  { name: "military_spending", query: "military_spending" },
  { name: "outsourcing", query: "outsourcing" },
  { name: "pets", query: "pets" },
  { name: "philantrophy", query: "philantrophy" },
  { name: "shale", query: "shale" },
  { name: "social_media", query: "social_media" },
  { name: "spinoffs", query: "spinoffs" },
  { name: "tech", query: "tech" },
  { name: "artificial_intelligence", query: "artificial_intelligence" },
  { name: "computer_vision", query: "computer_vision" },
  { name: "natural_language_processing", query: "natural_language_processing" },
  { name: "robotics", query: "robotics" },
  { name: "big_data", query: "big_data" },
  { name: "bitcoin", query: "bitcoin" },
  { name: "bitcoin_cash", query: "bitcoin_cash" },
  { name: "coins", query: "coins" },
  { name: "ethereum", query: "ethereum" },
  { name: "ripple", query: "ripple" },
  { name: "cloud_computing", query: "cloud_computing" },
  { name: "drone", query: "drone" },
  { name: "fintech", query: "fintech" },
  { name: "insurtech", query: "insurtech" },
  { name: "lending_platforms", query: "lending_platforms" },
  { name: "payments", query: "payments" },
  { name: "roboadvisors", query: "roboadvisors" },
  { name: "wealthtech", query: "wealthtech" },
  { name: "health_tech", query: "health_tech" },
  { name: "immunotherapy", query: "immunotherapy" },
  { name: "medical_devices", query: "medical_devices" },
  { name: "mri", query: "mri" },
  { name: "stemcell", query: "stemcell" },
  { name: "iot", query: "iot" },
  { name: "sensors", query: "sensors" },
  { name: "threed_printing", query: "threed_printing" },
  { name: "virtual_reality", query: "virtual_reality" },
  { name: "wearable_tech", query: "wearable_tech" },
  { name: "travel", query: "travel" },
  { name: "vice", query: "vice" },
  { name: "marijuana", query: "marijuana" },
  { name: "wellness", query: "wellness" },
  ],
  RelatedInsightsToggle = config.relatedInsight
  ;

export const categoryCol = cat => CategoryToCol[cat] || cat;

let _creds = 'c55demo:sesafi';

let DOMAIN_PATH = window.location.hostname;
const hostname = window.location.hostname;


const path = window.location.origin + '/login';
DOMAIN_PATH = path;


export const REDIRECT_DOMAIN_PATH = DOMAIN_PATH;

export const Universe = 'etf,etn,mf,cef';
// export const Universe = 'etf,etn,mf';
//universe=etf,etn or universe=mf or universe=all

export const ApiHeaders = () => ({
  // ...Api.headers,
  'Authorization': `Token ${window.localStorage.getItem('token')}`
});

export const DummyUser = process.env.NODE_ENV === 'production'
  ? { n: 'ZGVtb0BvcGVuc2VzYWZpLmNvbQ==', p: 'b3Blbl9zZXNhZmk=' }
  : { n: '', p: '' };

export const MaxResult = 100;

export function isDemoUser() {
  // return window.localStorage.getItem('email') === 'demo@opensesafi.com' || window.localStorage.getItem('email') === 's.agiwal@opensesafi.com' || window.localStorage.getItem('email') === 'siddharth@opensesafi.com' || window.localStorage.getItem('email') === 'j.karnik@opensesafi.com' || window.localStorage.getItem('email') === 'betsy@opensesafi.com' || window.localStorage.getItem('email') === 'ankit@opensesafi.com' || window.localStorage.getItem('email') === 'vinay@opensesafi.com' || window.localStorage.getItem('email') === 'pratik@opensesafi.com' || window.localStorage.getItem('email') === 'uttung@opensesafi.com'
  return true;
}

export const BrokerNameMap = brokerName => {
  let transformedBrokerName = brokerName || '';
  switch (brokerName) {
    case 'ETrade':
      transformedBrokerName = 'etrade'
      break
    case 'TD Ameritrade':
      transformedBrokerName = 'tdameritrade'
      break;
    case 'Vanguard':
      transformedBrokerName = 'vanguard'
      break;
    case 'Robinhood':
      transformedBrokerName = 'robinhood'
      break;
    case 'Just2Trade':
      transformedBrokerName = 'just2trade'
      break;
    case 'Tradier':
      transformedBrokerName = 'tradier'
      break;
    case 'Ally':
      transformedBrokerName = 'ally'
      break;
    default:
      break
  }
  return transformedBrokerName;
}

export const TradeItSetupStatus = {
  INFORMATION_NEEDED: 'INFORMATION_NEEDED',
  SUCCESS: 'SUCCESS'
}

export const getBrokerNameForImport = brokerName => {
  if (brokerName !== 'tdameritrade') {
    return 'tradeit'
  }
  return brokerName
}

export const calculatorOptions = {
  'magnificalculator': "Magnifi fund selector",
  'mycalculator': "passive fund selector",
  'magnifiactivecalculator': "active fund selector",
  'magnifiactivescreener': "active fund screener",
  //'sponsorcalculator': "[sponsor] calculator"
}

export const calcTestDriveKey = {
  'Magnifi fund selector': 'magnifi_calculator',
  'passive fund selector': 'my_calculator',
  'active fund selector': 'active_funds_selector',
  'active fund screener': 'active_funds_screener',
  'create custom selector': 'create_custom_selector',
}

export const QUERY_MAP = {
  'similar': 'Lower Cost Alternatives',
  'outperform': 'Best Performing Options In Category',
  'safe_alternative': 'Lower Risk Alternatives',
  'low_cost_low_risk_replacement': 'Lower Cost And Risk Alternatives',
  'lower_drawdown_alternatives': 'Lower Drawdown Alternatives',
  'high_yield_alternative': 'Highest Yield Alternatives',
  'long_track_record_alternatives': 'Long Track Record Alternatives',
  'lowest_tracking_error_option': 'Lower Tracking Error Alternatives',
  'high_sharpe_alternative': 'Highest Risk Adjusted Return Alternatives',
  'user_criteria': 'Best Alternatives Using Passive Fund Selector',
  'user_criteria_passive_fund_selector': 'Best Alternatives Using Passive Fund Selector',
  'user_criteria_active_fund_selector': 'Best Alternatives Using Active Fund Selector',
  'low_cost_alternative_models': 'Low Cost Alternatives',
  'low_cost_low_risk_replacement_models': 'Low Cost And Low Risk Alternatives',
  'lower_drawdown_alternatives_models': 'Lower Drawdown Alternatives',
  'long_track_record_alternatives_models': 'Long Track Record Alternatives',
  'lowest_tracking_error_option_models': 'Lower Tracking Error Alternatives',
  'low_risk_alternative_models': 'Lower Risk Alternatives',
  'high_yield_alternative_models': 'Highest Yield Alternatives',
  'high_sharpe_alternative_models': 'Highest Risk Adjusted Return Alternatives',
  'outperform_models': 'Best Performing Options In Category',
  'user_criteria_models': 'Best Alternative Using My Investment Selector',
};

export const TO_MODEL_QUERY = {
  'similar': 'low_cost_alternative',
  'outperform': 'outperform',
  'safe_alternative': 'low_risk_alternative',
  'low_cost_low_risk_replacement': 'low_cost_low_risk_replacement',
  'lower_drawdown_alternatives': 'lower_drawdown_alternatives',
  'high_yield_alternative': 'high_yield_alternative',
  'long_track_record_alternatives': 'long_track_record_alternatives',
  'lowest_tracking_error_option': 'lowest_tracking_error_option',
  'high_sharpe_alternative': 'high_sharpe_alternative',
  'user_criteria': 'user_criteria',
};

export const MODEL_PORTFOOLIO_QUERY_MAP = {
  'low_cost_alternative_models': 'low_cost_alternative',
  'low_risk_alternative_models': 'low_risk_alternative',
  'low_cost_low_risk_replacement_models': 'low_cost_low_risk_replacement',
  'lower_drawdown_alternatives_models': 'lower_drawdown_alternatives',
  'high_yield_alternative_models': 'high_yield_alternative',
  'high_sharpe_alternative_models': 'high_sharpe_alternative_models',
  'long_track_record_alternatives_models': 'long_track_record_alternatives',
  'long_track_record_alternatives_models': 'long_track_record_alternatives',
  'lowest_tracking_error_option_models': 'lowest_tracking_error_option',
  'outperform_models': 'outperform',
  'user_criteria_models': 'user_criteria',
};

export const REVERSE_MODEL_PORTFOOLIO_QUERY_MAP = {
  'low_cost_alternative': 'low_cost_alternative_models',
  'low_risk_alternative': 'low_risk_alternative_models',
  'low_cost_low_risk_replacement': 'low_cost_low_risk_replacement_models',
  'lower_drawdown_alternatives': 'lower_drawdown_alternatives_models',
  'high_yield_alternative': 'high_yield_alternative_models',
  'high_sharpe_alternative_models': 'high_sharpe_alternative_models',
  'high_sharpe_alternative': 'high_sharpe_alternative',
  'user_criteria': 'user_criteria_models',
  'long_track_record_alternatives': 'long_track_record_alternatives_models',
  'outperform': 'outperform_models',
  'lowest_tracking_error_option': 'lowest_tracking_error_option_models',
};

export const AssetAllocationBurstChartMapping = {
  'Allocations': 'Allocations',
  'bonds': 'Bonds',
  'cash': 'Cash',
  'commodities': 'Commodities',
  'currencies': 'Currencies',
  'derivatives': 'Derivatives',
  'equities': 'Equities',
  'other': 'Other',
};

export const PORTFOLIO_CLASS_MAP = {
  'Retail Class': 'retail',
  'Institutional Class': 'institutional',
  'A Class': 'class_a',
};

export const GET_USER_TYPE = {
  'RIA': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': true,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Hybrid Advisor': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': true,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Broker/Dealer/Wirehouse Advisor': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': true,
    'Client Accounts': true,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Asset Manager': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Hedge Fund Manager': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Portfolio Manager': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Corporate/Business Development': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Institutional Investor': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Individual Investor': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Other/Curious': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Sales Team': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': true,
    'Client Accounts': true,
    'Sponsor Models': true,
    'From Scratch': true,
  },
  'Default': {
    'Selected Funds': true,
    'My Models': true,
    'Home Office Models': false,
    'Client Accounts': false,
    'Sponsor Models': true,
    'From Scratch': true,
  },
};


// add new fund cards keys here
export const fundsCardArray = [
  { key: 'yield', paramKey: 'attribute/fundamental/yield', queryIcon: 'Yield', multiplier: 100 },
  { key: 'outperform', paramKey: 'outperform', queryIcon: 'OutPerform', multiplier: 100 },
  { key: 'high risk adjusted returns', paramKey: 'attribute/technical/returns/risk_adjusted_returns', queryIcon: 'Sharpe', multiplier: 1 },
  { key: 'track record', paramKey: 'attribute/track_record', queryIcon: 'TrackRecord', multiplier: 1, valueType: 'years' }
];

// passing keys for model and user portfolio
// targetCards and querySpecific for LHS and mpFundCards, upFundCards for RHS
export const cardsParams = {
  all: {
    targetCards: [
      { key: 'yRet', paramKey: 'attribute/technical/returns', multiplier: 100 },
      { key: 'volt', paramKey: 'attribute/technical/risk', multiplier: 100 },  // percent will multiply value by 100
    ],
    // for 10 results of model cards
    mpFundCards: [
      { key: 'fees', paramKey: 'fees', multiplier: 1 },
      { key: 'yRet', paramKey: 'attribute/technical/returns', multiplier: 100, levelParam: 'risk_return_yield' },
      { key: 'volt', paramKey: 'attribute/technical/risk', multiplier: 100, levelParam: 'risk_return_yield' },
      { key: 'feesQ', paramKey: 'feesq', multiplier: 1 },
      { key: 'yRetQ', paramKey: 'returnq', multiplier: 1, levelParam: 'Fee_Risk_Return' },
      { key: 'voltQ', paramKey: 'riskq', multiplier: 1, levelParam: 'Fee_Risk_Return' }
    ],
    upFundCards: [
      { key: 'fees', paramKey: 'fees', multiplier: 1 },
      { key: 'yRet', paramKey: 'Annualized Average', multiplier: 1, levelParam: 'Fee_Risk_Return' },
      { key: 'volt', paramKey: 'Annualized Volatility', multiplier: 1, levelParam: 'Fee_Risk_Return' },
      { key: 'feesQ', paramKey: 'feesq', multiplier: 1 },
      { key: 'yRetQ', paramKey: 'returnq', multiplier: 1, levelParam: 'Fee_Risk_Return' },
      { key: 'voltQ', paramKey: 'riskq', multiplier: 1, levelParam: 'Fee_Risk_Return' },
      { key: 'yield', paramKey: 'attribute/fundamental/yield', multiplier: 100 },
      { key: 'sharpe', paramKey: 'attribute/technical/returns/risk_adjusted_returns', multiplier: 1 },
    ]
  },
  querySpecific: [
    { key: 'yield', paramKey: 'attribute/fundamental/yield', multiplier: 100, queryIcon: 'Yield' },
    { key: 'sharpe', paramKey: 'attribute/technical/returns/risk_adjusted_returns', multiplier: 1, queryIcon: 'Sharpe' },
  ]
};

export const assetIconMapping = {
  "trend": {
    "name": "Trend",
    "icon": "/assets/images/selection-criteria/Trend.svg"
  },
  "yield": {
    "name": "Yield",
    "icon": "/assets/images/selection-criteria/Yield.svg"
  },
  "tracking error": {
    "name": "Tracking Error",
    "icon": "/assets/images/selection-criteria/TrackingError.svg"
  },
  "assets": {
    "name": "Assets",
    "icon": "/assets/images/selection-criteria/Assets.svg"
  },
  "volume": {
    "name": "Volume",
    "icon": "/assets/images/selection-criteria/Volume.svg"
  },
  "risk": {
    "name": "Risk",
    "icon": "/assets/images/selection-criteria/Risk.svg"
  },
  "fee": {
    "name": "Fee",
    "icon": "/assets/images/selection-criteria/Fees.svg"
  },
  "bid-ask spread": {
    "name": "Bid-Ask Spread",
    "icon": "/assets/images/selection-criteria/BidAskSpread.svg"
  },
  "premium to nav": {
    "name": "Premium to NAV",
    "icon": "/assets/images/selection-criteria/PremiumToNav.svg"
  },
  "return": {
    "name": "Return",
    "icon": "/assets/images/selection-criteria/Return.svg"
  },
  "track record": {
    "name": "Track Record",
    "icon": "/assets/images/selection-criteria/TrackRecord.svg"
  },
  "fund flows": {
    "name": "Fund Flows",
    "icon": "/assets/images/selection-criteria/FundFlows.svg"
  },
  "manager tenure": {
    "name": "Manager Tenure",
    "icon": "/assets/images/selection-criteria/ManagerTenure.svg"
  },
  "expense ratio": {
    "name": "Expense Ratio",
    "icon": "/assets/images/selection-criteria/ManagerTenure.svg"
  },
  "aum": {
    "name": "AUM",
    "icon": "/assets/images/selection-criteria/AUM.svg"
  },
  "upside capture 5yr": {
    "name": "Up Capture 5yr",
    "icon": "/assets/images/selection-criteria/Upcapture5years.svg"
  },
  "upside capture 10yr": {
    "name": "Up Capture 10yr",
    "icon": "/assets/images/selection-criteria/UpCapture10years.svg"
  },
  "down capture 5yr": {
    "name": "Down Capture 5yr",
    "icon": "/assets/images/selection-criteria/DownCapture5Years.svg"
  },
  "down capture 10yr": {
    "name": "Down Capture 10yr",
    "icon": "/assets/images/selection-criteria/DownCapture10years.svg"
  },
  "outperformance 3yr": {
    "name": "Outperformance 3yr",
    "icon": "/assets/images/selection-criteria/Return3yearsOutperformance.svg"
  },
  "outperformance 5yr": {
    "name": "Outperformance 5yr",
    "icon": "/assets/images/selection-criteria/Return5yearsOutperformance.svg"
  },
  "outperformance 10yr": {
    "name": "Outperformance 10yr",
    "icon": "/assets/images/selection-criteria/Return10yearsOutperformance.svg"
  },
  "alpha 5yr": {
    "name": "Alpha 5yr",
    "icon": "/assets/images/selection-criteria/Alpha5years.svg"
  },
  "alpha 10yr": {
    "name": "Alpha 10yr",
    "icon": "/assets/images/selection-criteria/Alpha10years.svg"
  },
  "default": {
    "name": "default",
    "icon": "/assets/images/selection-criteria/DefaultIcon.svg"
  },
}

export const weightSelectorsOptions = [
  {
    name: 'Passive Fund Selector',
    value: 'passive fund selector',
    code: 'passive_fund_selector',
  }, {
    name: 'Active Fund Selector',
    value: 'active fund selector',
    code: 'active_fund_selector',
  },
];

export const mappingRequiredKeys = [
  'id',
  'name',
  'short_desc',
  'short_name',
  'nav',
  'sponsor',
  'ticker',
  'expense_ratio',
  'fees',
  'category',
  'feesq',
  'aum',
  'asset_alloc',
  'weight_yield',
  'weight_track_record',
  'outperform',
  'average_down_ret',
  'average_up_ret',
  'cumulative_returns_3y',
  'downside_capture_3y',
  'info_ratio',
  'region',
  'return',
  'returns',
  'returns_3y',
  'sharpe_3y',
  'tracking_error',
  'underlying_index',
  'upside_capture_3y',
  'vehicle',
  'volatility_3y',
  'quantity',
  'shares',
  'Fee_Risk_Return',
  'market_value',
  'monthly_nav',
  'weight',
  '_pF',
  'FRR',
  'odometerChart',
  '_realTimePrice',
  'track_record',
  'yield',
  'attribute/track_record',
  'attribute/fundamental/yield',
];

export const skipRequiredKeys = [
  // 'Fee_Risk_Return',
  'average_down_ret',
  'average_up_ret',
  'downside_capture_3y',
  'info_ratio',
  'underlying_index',
  'upside_capture_3y',
  'volatility_3y',
];

export const REDIRECT_PATH = {
  'client': '/advisor/account',
  'builder': '/build-portfolio/onboard-screen',
};

export const optWeightList = [
  { name: '5%', value: 0.05 },
  { name: '10%', value: 0.1 },
  { name: '15%', value: 0.15 },
  { name: '20%', value: 0.2 },
  { name: '25%', value: 0.25 },
];

// Use this animation in FlipMove component
// If you find the font scaling issues
export const flipMoveElevator = {
  enterAnimation: {
    from: {
      transform: 'scale(0.5)',
      opacity: 0,
    },
    to: {
      transform: '',
      opacity: '',
    },
  },
  leaveAnimation: {
    from: {
      transform: 'scale(1)',
      opacity: 1,
    },
    to: {
      transform: 'scale(0)',
      opacity: 0,
    },
  }
}

// Reverse mapping for code key in selector attributes
// to old code to fix time frame issues
export const reverseMapCodeKeys = {
  'attribute/technical/returns': 'return',
  'attribute/technical/risk': 'risk',
  'attribute/technical/volume': 'volume',
  'attribute/technical/tracking_error': 'trkerror',
  'attribute/technical/trend': 'weight_trend',
  'attribute/technical/aum/fund_flow': 'weight_fund_flows',
  'attribute/premium_to_nav': 'weight_premium_to_nav',
}

export const AUTO_APPROVAL_ALLOW_USERS = [98048]