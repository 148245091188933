import React, { Component } from 'react'

import { connect } from 'react-redux'
import SponserCollapse from './Pages/SponsersList';

class SponsersComponent extends Component {

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
    } 
  }

  render() {
    return (
      <>
        <SponserCollapse/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
})

export default connect(mapStateToProps, null)(SponsersComponent)
