const uatConfig = {
  apiBaseUrl: 'https://api.magnifi.com/api',
  profileBaseUrl: 'https://api-uat.magnifi.com/profile/api',
  tdAmeritradeCallbackUrl:
    'https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=https://test.opensesafi.com/callbacks/tdameritrade/td-auth-code',
  tradeItOrigin: 'https://www.trade.it',
  tradingTicketorigin: 'https://ems.tradingticket.com',
  googleAnalyticsCode: 'AIzaSyC2_hXo2574jMnsCi2FKJZLbT2LSInreac',
  googleTaManagerCode: 'UA-153849204-1',
  heapAnalyticsCode: '4262087573',
  //  krakenId: '5df2a89236c054a5e077c7e5',
  tradeServiceBaseUrl: 'https://api-uat.magnifi.com/trade-service/api',
  stripePublishableKey: 'pk_test_AEQFGPFVy9AERNCjkr8AU5gc00K1DdiLbk'
}

export default uatConfig
