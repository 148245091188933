import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Alert, Spinner as Loader } from 'reactstrap'
import {
  tradingAccountDetailRequest,
  fetchAccountStatus,
  updateAccountStatus,
  cancelACHRequest,
  setAccountState
} from 'actions/account'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'
import moment from 'moment'
import * as AccountApi from '../../../../apis/account'
import ConfirmModal from '../../Elements/Confirm'
import { Documents } from './Documents'
import { isEmpty, capitalize, get } from 'lodash'
import {
  getApexAccountStatus,
  requestAction,
  uploadReviewImage,
  updateSnapStatus,
  saveForReview,
  toggleisActive,
  fetchAccountData
} from 'apis/account'
import { RequestTab } from './Request'
import ReasonModal from '../../Elements/AchConfirm'
import { downloadSnap } from 'apis/account'
import { message } from 'antd'
import FileSaver from 'file-saver'
import { trustedContactCountry } from '../../Constant/FormData'
import ErrorAlert from '../../Elements/ErrorAlert'
import MoreTab from './MoreTab'
// import ADDRESSVERIFYIMG from 'assets/images/address-verification.png';

const REASONS = {
  UPDATED_ROUTING_NUMBER_BY_NOC: 'The account routing number has been updated.'
}

const REJECT_REASONS = [
  'ADDRESS_STANDARDIZATION_ERROR_PRESENT_FOR_CURRENT_ADDRESS',
  'INPUT_ADDRESSES_INCLUDING_CURRENT_AND_FORMER_DID_NOT_MATCH_TO_ANY_OF_THE_ADDRESSES_CONTAINED_IN_THE_PRIMARY_DATA_SOURCE',
  'INQUIRY_ADDRESS_IS_LISTED_AS_A_NONRESIDENTIAL_ADDRESS',
  'NO_VERIFIABLE_MATCH_FOUND_ON_ANY_CONSUMER_PROVIDED_ADDRESSES',
  'INQUIRY_ADDRESS_IS_NOT_ASSOCIATED_WITH_THIS_CONSUMERS_NAME'
]

const annualIncomeRange = {
  '0': '0',
  '25000': '25,000',
  '25001': '25,001',
  '50000': '50,000',
  '50001': '50,001',
  '100000': '100,000',
  '100001': '100,001',
  '200000': '200,000',
  '200001': '200,001',
  '300000': '300,000',
  '300001': '300,001',
  '500000': '500,000',
  '500001': '500,001',
  '1200000': '1,200,000',
  '1200001': '1,200,001',
  '9999999': '9,999,999'
}

const autoLoadImageStyle = {
  position: 'fixed',
  top: 0,
  opacity: 0,
  display: 'none',
  visibility: 'hidden'
}

class AccountDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountData: {},
      isFetching: false,
      fetchError: false,
      apex_account_id: this.props.match.params.id,
      apex_account_no: '',
      active_tab: 'tab1',
      modalType: '',
      modalHeading: '',
      modalBtn1: '',
      modalBtn2: '',
      showModal: false,
      apexStatus: {},
      showSuspendedAccount: false,
      showReasonModal: false,
      activeEvaluation: '1',
      activeEquifax: '1',
      placeholder: 'Please enter a reason',
      userInputArea: '',
      confirmModalAction: '',
      canUploadInModal: false,
      approvedDocID: '',
      appeal: '',
      loadingSnapId: null,
      primaryOrSecondary: '1',
      validateResponse: null,
      autoLoadImagePath: '/assets/images/address-verification.png',
      invalidAutoUploadImagePath: null,
      recentTransactions: {}
    }
  }

  componentDidMount() {
    this.setState({ isFetching: true })
    this.fetchProfile()
    console.log('---- ACCOUNT DETAILS MOUNTED ----')
  }

  componentWillUnmount() {
    const { setAccountState } = this.props
    if (setAccountState) {
      // NOTE: RESET 'closeAccountStatus' when components unmount
      setAccountState({
        closeAccountStatus: ''
      })
    }
  }

  fetchProfile = () => {
    const { tradingAccountDetailRequest } = this.props
    if (tradingAccountDetailRequest) {
      try {
        tradingAccountDetailRequest(this.state.apex_account_id)
      } catch (e) {
        console.log('<----- UNABLE TO GET ACCOUNT DETAIL----->')
      }
    }
  }

  checkRejectReasons(investigations) {
    function compareValues(obj) {
      for (const key in obj) {
        if (!REJECT_REASONS.includes(key)) {
          return false
        }
      }
      return true
    }

    let flag = investigations.map(obj => {
      if (
        obj.sketchId &&
        obj.sketchId.result &&
        obj.sketchId.result.equifaxResult &&
        obj.sketchId.result.equifaxResult.results &&
        obj.sketchId.result.equifaxResult.results.reject &&
        compareValues(obj.sketchId.result.equifaxResult.results.reject)
      ) {
        return true
      }
      return false
    })
    return flag[0]
  }

  // item.sketchID.result.equifaxResult.results.reject

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accountData && this.props.accountData !== undefined) {
      if (
        this.props.accountData &&
        this.props.accountData.status === 200 &&
        this.props.accountData !== prevProps.accountData
      ) {
        this.setState({
          accountData: this.props.accountData.data
        })
      }
      if (
        this.props.error !== prevProps.error &&
        (this.props.accountData.status !== 200 ||
          this.props.error !== undefined)
      ) {
        this.setState({
          fetchError: true,
          error: this.props.error
        })
      }
    }
  }

  async componentWillReceiveProps(nextProps) {
    let apex
    let showSuspendedAccount = false
    let isInValidAddress = false

    if (
      nextProps.accountData.status === 200 &&
      !isEmpty(nextProps.accountData)
    ) {
      apex = nextProps.accountData.data

      this.setState({
        isFetching: true,
        apex_account_no: apex.account[0].account_no //8 digit
      })
      if (apex && apex.account[0].account_no) {
        this.fetchTransactionData(apex && apex.account[0].account_no)
      }
      let apexStatus = await getApexAccountStatus(apex.account[0].id)
      apexStatus = apexStatus.result
      !isEmpty(apexStatus.investigations) &&
        (apexStatus.status === 'SUSPENDED' ||
          apexStatus.status === 'ACTION_REQUIRED') &&
        apexStatus.investigations.forEach(investigation => {
          if (!showSuspendedAccount) {
            !isEmpty(investigation.sketchId) &&
              !investigation.sketchId.error &&
              investigation.sketchId.cipCategories.length &&
              investigation.sketchId.cipCategories.forEach(cat => {
                if (
                  cat.categoryState === 'REJECTED' &&
                  cat.requestedDocuments.length > 0
                ) {
                  showSuspendedAccount = true
                }
              })
          }

          console.log(investigation.sketchId.cipCategories)
          // NOTE: to check valid 'Address' state
          if (
            !isInValidAddress &&
            !isEmpty(investigation.sketchId) &&
            investigation.sketchId.cipCategories &&
            investigation.sketchId.cipCategories.length
          ) {
            console.log(investigation.sketchId.cipCategories)
            investigation.sketchId.cipCategories.forEach(cat => {
              if (cat.name === 'ADDRESS' && cat.categoryState === 'REJECTED') {
                console.log(
                  '------- Is Address Invalid ? -------> ',
                  this.state.isInValidAddress,
                  cat.categoryState
                )
                isInValidAddress = true
              }
            })
          }
        })

      this.setState(
        {
          apexStatus,
          isFetching: false,
          isInValidAddress,
          showSuspendedAccount
        },
        () => {
          console.log(
            '------- Is Address Invalid ? -------> ',
            this.state.isInValidAddress
          )
          if (this.state.isInValidAddress) {
            const showApealAndReject =
              !showSuspendedAccount &&
              apexStatus &&
              apexStatus.investigations &&
              apexStatus.investigations[0] &&
              apexStatus.investigations[0].sketchId.status === 'REJECTED' &&
              apexStatus.investigations[0].sketchId.result &&
              apexStatus.investigations[0].sketchId.result.evaluation &&
              apexStatus.investigations[0].sketchId.result.evaluation.dataSources.includes(
                'EQUIFAX'
              )
            const accountDetails = apex.account[0]

            // NOTE: 'showAppealButton' is only checking state of 'EQUIFAX' from api
            // NOTE: -- NEED TO CHECK ERROR IN 'DOW JONES RESULTS' AS WELL TO AVOID AUTO-ADDRESS-APPROVE-PROCESS --
            // ------------------------------------------------------------------------------------------------------------------------------------------------
            const dowJonesHasError =
              apexStatus &&
              apexStatus.investigations &&
              apexStatus.investigations.length &&
              apexStatus.investigations[0] &&
              apexStatus.investigations[0].sketchId &&
              apexStatus.investigations[0].sketchId.status === 'REJECTED' &&
              apexStatus.investigations[0].sketchId.result &&
              apexStatus.investigations[0].sketchId.result.dowJonesResult &&
              apexStatus.investigations[0].sketchId.result.dowJonesResult
                .profiles &&
              apexStatus.investigations[0].sketchId.result.dowJonesResult
                .profiles.length > 0
            console.log('dowJonesHasError --->', dowJonesHasError)

            // if (!dowJonesHasError) {
            //   AccountApi.validateAddress({
            //     street: get(apex, 'profile[0].address'),
            //     city: get(apex, 'profile[0].city'),
            //     PostalCode: get(apex, 'profile[0].zipcode'),
            //     CountryCode: get(apex, 'profile[0].country')
            //   }).then(resdata => {
            //     this.setState({ validateResponse: resdata.data })
            //     if (
            //       resdata.data.rdi === 'R' &&
            //       resdata.data.status === 'VALID'
            //     ) {
            //       if (this.canAutoApprove(apexStatus.investigations)) {
            //         this.automateAddressApproveProcess()
            //       }
            //     }
            //   })
            // }
            // ------------------------------------------------------------------------------------------------------------------------------------------------

            // NOTE: if 'Account' state is 'SUSPENDED', call an api to 'validate the Address'
            if (
              isInValidAddress &&
              !dowJonesHasError &&
              accountDetails &&
              (accountDetails.status === 'SUSPENDED' ||
                accountDetails.status === 'COMPLETE') &&
              this.checkRejectReasons(apexStatus.investigations)
            ) {
              AccountApi.validateAddress({
                street: get(apex, 'profile[0].address'),
                city: get(apex, 'profile[0].city'),
                PostalCode: get(apex, 'profile[0].zipcode'),
                CountryCode: get(apex, 'profile[0].country')
              }).then(resdata => {
                this.setState({ validateResponse: resdata.data })
                if (
                  resdata.data.rdi === 'R' &&
                  resdata.data.status === 'VALID'
                ) {
                  if (this.canAutoApprove(apexStatus.investigations)) {
                    this.automateAddressApproveProcess()
                  }
                }
              })
            }
          }
        }
      )
    }
  }

  canAutoApprove = investigation => {
    let status = true
    investigation &&
      investigation.forEach(data => {
        let cip = data.sketchId.cipCategories
        for (let i = 0; i < cip.length; i++) {
          if (
            cip[i].categoryState === 'REJECTED' &&
            cip[i].name !== 'ADDRESS'
          ) {
            status = false
          }
        }
      })
    return status
  }

  onBrokenImage = ({ error, url }) => {
    this.setState({
      invalidAutoUploadImagePath: true
    })
  }

  automateAddressApproveProcess = async () => {
    if (this.state.invalidAutoUploadImagePath) {
      // NOTE: In-Valid Image, Check image path again.
      // -------------------------------------------------
      console.log('---- Check auto-upload image path ----')
      // -------------------------------------------------
      return null
    } else {
      // NOTE: Valid Image, execute next Upload Image step
      // -------------------------------------------------

      //step 1 upload image
      const { apex_account_id, accountData } = this.state
      // const blob = new Blob([ADDRESSVERIFYIMG], { type: "image/png"});

      // NOTE: fetch Image from PATH
      // -------------------------------------------------
      const blob = await fetch(
        '/assets/images/address-verification.png'
      ).then(r => r.blob())
      // NOTE: Convert Blob to File
      const AUTO_UPLOAD_FILE = new File([blob], 'address-verification', {
        lastModified: new Date().getTime(),
        type: blob.type
      })
      // NOTE: Upload File as FormData
      let formData = new FormData()
      formData.append('file', AUTO_UPLOAD_FILE)
      formData.append('tag', 'AFFILIATED_APPROVAL')
      formData.append('account', apex_account_id)

      try {
        let upload = await uploadReviewImage(formData)
        // console.log(upload);

        // // NOTE: To test AutoUploadImage process, trigger 'this.onDownloadSnap' to download auto-uploaded image
        // // -----------------un-comment-below--------------------------------
        // if (upload && upload.record_id) {
        //   this.onDownloadSnap('AutoUploadImage', upload.record_id)
        // }
        // // -------------------------------------------------

        //step 2 save address
        let payload = {
          apex_account_id: apex_account_id,
          data: [
            {
              sketchId: accountData.account[0].sketch_ids,
              snapIDs: [
                {
                  id: upload.record_id,
                  type: 'ADDRESS',
                  proof: 'ALL_PASSING_CIP_RESULTS',
                  status: 'uploaded'
                }
              ]
            }
          ]
        }
        let saveReview = await saveForReview(payload)
        if (saveReview && saveReview.data && saveReview.data.message) {
          message.success(saveReview.data.message)
        }

        //step 3 update address with status approved
        let updatePayload = {
          snapId: upload.record_id,
          comment:
            'Verified residential address through 3rd party address validator',
          status: 'approved'
        }
        let update = await updateSnapStatus(updatePayload)

        this.setState({ isFetching: true })

        //step 4 make apeal
        let appealPayload = {
          apex_account_id: apex_account_id,
          sketchId: accountData.account[0].sketch_ids,
          action: 'APPEALED',
          text: 'auto appealing identity verification',
          cip: {
            snapIDs: [upload.record_id]
          }
        }

        let appeal = await AccountApi.makeAppeal(appealPayload).then(
          null,
          e => {
            console.log(e)
          }
        )

        if (appeal && appeal.status === 200) {
          message.success('APPEALED Successfully!')
          this.props.tradingAccountDetailRequest(this.state.apex_account_id)
        }

        this.setState({
          isFetching: false,
          appeal: 'APPEALED'
        })
      } catch (e) {
        console.log(e)
        this.setState({ isFetching: false })
      }
    }
  }

  getBack = () => {
    const { history } = this.props
    if (history && history.location.state && history.location.state.active) {
      return history.push({
        pathname: `/accounts`,
        state: {
          active: history.location.state.active,
          appliedFilter: history.location.state.appliedFilter
        }
      })
    }
    return history.push('/accounts')
  }

  changeTab = tab => {
    this.setState({
      active_tab: tab
    })
  }

  doThis = async status => {
    try {
      this.setState({ isFetching: true })
      let _responseData = await AccountApi.fetchAccountStatus(
        this.state.apex_account_id
      )
      let sketchID = ''

      if (_responseData && _responseData.status === 200) {
        if (
          _responseData.data &&
          _responseData.data.result &&
          _responseData.data.result.investigations
        ) {
          _responseData.data.result.investigations.map((obj, idx) => {
            if (obj.sketchId && obj.sketchId.status === 'INDETERMINATE') {
              sketchID = obj.sketchId.id
            }
          })
        }
      }

      if (status && sketchID !== '') {
        this.setState({ isFetching: true })

        let formData = {
          apex_account_id: this.state.apex_account_id,
          sketchId: sketchID,
          action: status,
          comment: this.state.userInputArea,
          snapid: this.state.approvedDocID
        }

        if (status === 'REJECTED') {
          delete formData.snapid
        }

        let _updateData = await AccountApi.updateAccountStatus(formData)

        if (_updateData && _updateData.status === 200) {
          this.props.tradingAccountDetailRequest(this.state.apex_account_id)
        }

        this.setState({ isFetching: false })
      }

      this.setState({ isFetching: false })
    } catch (error) {
      this.setState({ isFetching: false })
    }
  }

  hideModal = () => {
    this.setState({
      showModal: false,
      approvedDocID: ''
    })
  }

  confirmRejection = (classType, modalType) => {
    let modalHeading = ''
    let modalBtn1 = ''
    let canUploadInModal = false

    if (modalType === 'rejection_modal') {
      modalHeading = 'Reject Trading Account Application'
      modalBtn1 = 'Reject'
    } else if (modalType === 'approve_modal') {
      modalHeading = 'Approve Trading Account Application'
      modalBtn1 = 'Approve'
      canUploadInModal = true
    } else if (modalType === 'appeal_modal') {
      modalHeading = 'Approve Trading Account Application'
      modalBtn1 = 'Approve'
      canUploadInModal = true
    }

    this.setState({
      modalType: modalType,
      modalHeading: modalHeading,
      modalBtn1: modalBtn1,
      modalBtn2: 'Cancel',
      showModal: true,
      classType: classType,
      userInputArea: '',
      userInput: true,
      canUploadInModal: canUploadInModal,
      approvedDocID: ''
    })
  }

  handleModalAction = () => {
    if (this.state.modalType === 'rejection_modal') {
      this.doThis('REJECTED')
    }

    if (this.state.canUploadInModal) {
      if (!this.state.approvedDocID) {
        return false
      }
    }

    if (this.state.modalType === 'approve_modal') {
      this.doThis('ACCEPTED')
    } else if (this.state.modalType === 'appeal_modal') {
      if (!this.state.userInputArea) {
        return false
      }
      this.submitAppeal()
    }

    this.setState({
      showModal: false,
      userInput: false,
      canUploadInModal: false
    })
  }

  closeAccountAction = async payload => {
    this.setState({ isFetching: true })
    try {
      let action = await requestAction(payload)
      let error = get(action, 'data.result.result .errors[0].message', null)
      if (error) {
        message.error(error, 5)
      }
      this.setState({ isFetching: false })
      this.fetchProfile()
    } catch (e) {
      this.setState({
        isFetching: false
      })
      console.log(e)
    }
  }

  cancelACH = (id, reason) => {
    const { cancelACHRequest } = this.props
    if (cancelACHRequest) {
      cancelACHRequest({
        id,
        comment: reason
      })
    }
  }

  onClickCancel = () => {
    this.setState({ showReasonModal: true })
  }

  handleCloseReasonModal = () => {
    this.setState({ showReasonModal: false })
  }

  _getACHReason = apex => {
    if (apex && apex.ach && apex.ach.length) {
      let filtered = apex.ach.filter(val => val.reason.includes('_NOC'))
      if (filtered.length) {
        return (
          <Alert color="warning">
            <div>
              {REASONS[filtered[0].reason]
                ? REASONS[filtered[0].reason]
                : filtered[0].reason}
            </div>
          </Alert>
        )
      } else {
        return null
      }
    }
    return null
  }

  _getDipositeReasonWarning = apex => {
    if (apex && apex.deposits && apex.deposits.length) {
      let filtered = apex.deposits.filter(val => val.status === 'Returned')
      if (filtered.length) {
        return (
          <Alert color="warning">
            <div>
              {REASONS[filtered[0].reason]
                ? REASONS[filtered[0].reason]
                : filtered[0].reason}
            </div>
          </Alert>
        )
      } else {
        return null
      }
    }
    return null
  }

  handleInputChange = event => {
    if (event) {
      const target = event.target
      const value = target.value
      this.setState({ [event.target.name]: value })
    }
  }

  handleUprovedDocID = docID => {
    this.setState({ approvedDocID: docID })
  }

  submitAppeal = async () => {
    let snapIDs = [this.state.approvedDocID]

    this.setState({ isFetching: true })
    let payload = {
      apex_account_id: this.state.apex_account_id,
      sketchId: this.state.apexStatus.sketch_ids[0],
      action: 'APPEALED',
      text: this.state.userInputArea,
      cip: {
        snapIDs
      }
    }

    try {
      let appeal = await AccountApi.makeAppeal(payload)

      if (appeal && appeal.status === 200) {
        this.props.tradingAccountDetailRequest(this.state.apex_account_id)
      }

      this.setState({
        isFetching: false,
        appeal: 'APPEALED'
      })
    } catch (e) {
      this.setState({
        isFetching: false,
        appeal: 'Error'
      })
      console.log(e)
    }
  }

  onDownloadSnap = async (name, snap_id) => {
    try {
      this.setState({
        loadingSnapId: snap_id
      })
      let download = await downloadSnap({ snap: snap_id })
      const newBlob = new Blob([download.data], {
        type: download.headers['content-type']
      })
      const extension = download.headers['content-type'].split('/')[1]
      var reader = new FileReader()
      FileSaver.saveAs(
        newBlob,
        `${name}.${extension === 'octet-stream' ? 'gif' : extension}`
      )
      this.setState({
        loadingSnapId: null
      })
    } catch (e) {
      this.setState({
        loadingSnapId: null
      })
      console.log(e)
    }
  }

  getGender = accountData => {
    const gender = get(accountData, 'profile[0].gender', '-')
    if (gender === 'M') {
      return 'Male'
    } else if (gender === 'F') {
      return 'Female'
    } else {
      return '-'
    }
  }

  getJointCountryFullName = jointUser => {
    const countryCode = get(jointUser, 'joint_country')
    const foundIndex = trustedContactCountry.findIndex(
      val => val['alpha-3'] === countryCode
    )
    if (foundIndex > -1) {
      return trustedContactCountry[foundIndex]['name']
    }
    return countryCode
  }

  formatCurrncyCommas = value => {
    if (annualIncomeRange[value]) {
      return annualIncomeRange[value]
    }
    return value
  }
  handleIsActiveToggle = async () => {
    const account = this.state.accountData.account[0]
    const id = account.id
    console.log('id', account.is_active)
    await toggleisActive(id)
    await fetchAccountData(this.state.apex_account_id)
    window.location.reload()
  }

  fetchTransactionData = async accountNo => {
    try {
      const data = await AccountApi.fetchRecentTransactions(accountNo)
      this.setState({ recentTransactions: data })
    } catch (error) {
      console.error('Error fetching recent transactions:', error)
    }
  }

  render() {
    const {
      showSuspendedAccount,
      apexStatus,
      apex_account_id,
      apex_account_no,
      accountData,
      validateResponse,
      autoLoadImagePath,
      recentTransactions
    } = this.state

    let accountDetails =
      accountData && accountData.account && accountData.account[0]
    console.log(accountDetails)
    let jointUser = accountData && accountData.joint_user
    if (typeof jointUser === 'object') {
      jointUser.joint_relation =
        jointUser && jointUser.joint_political_organization
          ? JSON.parse(jointUser.joint_political_organization)
          : []
    }
    let requestData =
      this.state.accountData &&
      this.state.accountData.account &&
      this.state.accountData.account[0] &&
      this.state.accountData.account[0] &&
      this.state.accountData.account[0].request_data &&
      JSON.parse(this.state.accountData.account[0].request_data)
    let accountNumber =
      this.state.accountData &&
      this.state.accountData.bank &&
      this.state.accountData.bank[0] &&
      this.state.accountData.bank[0].bank_account_no
        ? this.state.accountData.bank[0].bank_account_no
        : ''
    let last5Digits =
      accountNumber && accountNumber.length > 0
        ? accountNumber.substr(accountNumber.length - 5)
        : ''

    let politicalOrganisation =
      accountDetails &&
      accountDetails.political_organization &&
      JSON.parse(accountDetails.political_organization)

    let account_request = !isEmpty(accountData)
      ? accountData.request && accountData.request.length
        ? accountData.request
        : []
      : []
    const showApealAndReject =
      !showSuspendedAccount &&
      apexStatus &&
      apexStatus.investigations &&
      apexStatus.investigations[0] &&
      apexStatus.investigations[0].sketchId.status === 'REJECTED' &&
      apexStatus.investigations[0].sketchId.result &&
      apexStatus.investigations[0].sketchId.result.evaluation &&
      apexStatus.investigations[0].sketchId.result.evaluation.dataSources.includes(
        'EQUIFAX'
      )
    const isJointAccount =
      accountDetails &&
      accountDetails.customer_type &&
      accountDetails.customer_type.toLowerCase() === 'joint' &&
      jointUser
    const columnValue = isJointAccount
      ? {
          label: 'col-sm-6 col-12',
          field: 'col-sm-3 col-12 text-left text-sm-right'
        }
      : {
          label: 'col-sm-8 col-12',
          field: 'col-sm-4 col-12 text-left text-sm-right'
        }
    return (
      <div className="container-wrapper homeWrapper">
        {this.state.showModal && (
          <ConfirmModal
            userInput={this.state.userInput}
            heading={this.state.modalHeading}
            type={this.state.classType}
            btn1={this.state.modalBtn1}
            btn2={this.state.modalBtn2}
            closeModal={this.hideModal}
            handleModalAction={this.handleModalAction}
            placeholder={this.state.placeholder}
            userInputArea={this.state.userInputArea}
            onChange={this.handleInputChange}
            apexAccountID={apex_account_id}
            canUpload={this.state.canUploadInModal}
            handleUprovedDocID={this.handleUprovedDocID}
            modalType={this.state.modalType}
          />
        )}

        <img
          style={autoLoadImageStyle}
          src={autoLoadImagePath}
          onError={error =>
            this.onBrokenImage({ error, url: autoLoadImagePath })
          }
        />

        <Spinner isFetching={this.state.isFetching} />

        <a onClick={() => this.getBack()} className="back-button">
          <i className="fa fa-arrow-left" /> Back
        </a>
        {this._getACHReason(accountData)}
        {this._getDipositeReasonWarning(accountData)}
        {showSuspendedAccount && (
          <Alert color="danger">
            <a
              onClick={() => this.changeTab('tab4')}
              style={{ color: `#721c24` }}
            >
              This account is rejected. Click here to upload and review
              documents.
            </a>
          </Alert>
        )}
        {accountDetails &&
          ['ACTION_REQUIRED', 'SUSPENDED', 'COMPLETE'].includes(
            accountDetails.status
          ) &&
          apexStatus &&
          apexStatus.investigations &&
          apexStatus.investigations[0] &&
          apexStatus.investigations[0].sketchId.result &&
          apexStatus.investigations[0].sketchId.result.equifaxResult && (
            <Alert
              color={
                accountDetails.status === 'ACTION_REQUIRED'
                  ? 'warning'
                  : 'danger'
              }
            >
              <ErrorAlert investigations={apexStatus.investigations} />
            </Alert>
          )}

        {validateResponse && validateResponse.status && (
          <Alert color="warning">
            <p>
              <b>Address Status</b>:{' '}
              {validateResponse.rdi === 'R'
                ? validateResponse.status
                : 'INVALID'}
            </p>
            {validateResponse.rdi && (
              <p>
                <b>Address Type</b>:{' '}
                {validateResponse.rdi === 'R' ? 'Residential' : 'Commercial'}
              </p>
            )}
          </Alert>
        )}

        {/* {
            (accountDetails && accountDetails.status === 'SUSPENDED' && apexStatus && apexStatus.investigations && apexStatus.investigations[0].sketchId.result && apexStatus.investigations[0].sketchId.result.equifaxResult && apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject) && <Alert color="danger">
              {
                <>
                  <div>{apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject.DATE_OF_BIRTH_NOT_VALIDATED}</div>
                  <div>{apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject.NO_IDENTITY_LOCATED_ON_ANY_DATA_SOURCE}</div>
                  <div>{apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject.SOCIAL_SECURITY_NUMBER_NOT_VALIDATED}</div>
                  <div>{apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject.WARNING_INQUIRY_SSN_IS_INVALID}</div>
                </>
              }
            </Alert>
          } */}
        <div className="content-box">
          <div className="row row-padding border-bottom">
            <div className="col text-left mb-2 mb-sm-0">
              <label>Name</label>
              <p>{accountDetails && accountDetails.name}</p>
            </div>
            <div className="col text-left mb-2 mb-sm-0">
              <label>Email ID</label>
              <p>{accountDetails && accountDetails.email}</p>
            </div>
            <div className="col text-left text-sm-center mb-2 mb-sm-0">
              <label></label>
              <p></p>
            </div>
            <div className="col text-left text-sm-right mb-2 mb-sm-0">
              <label>Status</label>
              <p>
                <span className="hyper-link">
                  {accountDetails && accountDetails.status}
                </span>
              </p>
            </div>
            {!showApealAndReject &&
              accountDetails &&
              accountDetails.status === 'ACTION_REQUIRED' && (
                <div className="col text-left text-sm-right mb-2 mb-sm-0">
                  <a
                    onClick={() =>
                      this.confirmRejection('approved', 'approve_modal')
                    }
                    className="line-btn green-btn text-uppercase mb-2 mb-sm-1 w-100"
                  >
                    <i className="fa fa-check" /> Apporve
                  </a>
                  <a
                    onClick={() =>
                      this.confirmRejection('rejected', 'rejection_modal')
                    }
                    className="line-btn red-btn text-uppercase mb-2 mb-sm-0 w-100"
                  >
                    <i className="fa fa-times" /> Reject
                  </a>
                </div>
              )}

            {showApealAndReject && (
              <div className="col text-left text-sm-right mb-2 mb-sm-0">
                <a
                  onClick={() =>
                    this.confirmRejection('appeal', 'appeal_modal')
                  }
                  className="line-btn green-btn text-uppercase mb-2 mb-sm-1 w-100"
                >
                  <i className="fa fa-check" /> Appeal
                </a>
                {apexStatus.investigations[0].sketchId.status !==
                  'REJECTED' && (
                  <a
                    onClick={() =>
                      this.confirmRejection('rejected', 'rejection_modal')
                    }
                    className="line-btn red-btn text-uppercase mb-2 mb-sm-0 w-100"
                  >
                    <i className="fa fa-times" /> Reject
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="section-detail">
            <div className="row">
              <div className="col mb-2">
                <label>Investment Profile</label>
              </div>
            </div>
            <div className="row mb-2 pb-1">
              <div className="col-sm-8 col-12">Annual Income</div>
              <div className="col-sm-4 col-12 text-left text-sm-right">
                <a className="hyper-link">
                  {accountDetails &&
                  !isEmpty(get(accountDetails, 'investment_profile')) &&
                  !isEmpty(
                    get(
                      JSON.parse(accountDetails.investment_profile),
                      'annualIncomeUSD'
                    )
                  )
                    ? '$' +
                      this.formatCurrncyCommas(
                        get(
                          JSON.parse(accountDetails.investment_profile),
                          'annualIncomeUSD.min'
                        )
                      ) +
                      ' - $' +
                      this.formatCurrncyCommas(
                        get(
                          JSON.parse(accountDetails.investment_profile),
                          'annualIncomeUSD.max'
                        )
                      )
                    : '-'}
                </a>
              </div>
            </div>
            <div className="row mb-2 pb-1">
              <div className="col-sm-8 col-12">Assets Under Management</div>
              <div className="col-sm-4 col-12 text-left text-sm-right">
                <a className="hyper-link">
                  {isEmpty(
                    get(accountData, 'profile[0].estimated_net_worth', '-')
                  )
                    ? '-'
                    : get(accountData, 'profile[0].estimated_net_worth', '-')}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="content-box">
          <div className="section-detail">
            <div className="row">
              <div className="col mb-2">
                <label>Trading Account Details</label>
              </div>
            </div>

            <ul className="custom-tabs">
              <li onClick={() => this.changeTab('tab1')}>
                <a className={this.state.active_tab === 'tab1' ? 'active' : ''}>
                  Account Details
                </a>
              </li>
              <li onClick={() => this.changeTab('tab2')}>
                <a
                  className={
                    this.state.active_tab === 'tab2' ? 'active' : 'inactive'
                  }
                >
                  Disclosures
                </a>
              </li>
              <li onClick={() => this.changeTab('tab3')}>
                <a className={this.state.active_tab === 'tab3' ? 'active' : ''}>
                  Employment
                </a>
              </li>
              <li onClick={() => this.changeTab('tab4')}>
                <a className={this.state.active_tab === 'tab4' ? 'active' : ''}>
                  Documents {/*<span className="tab-dot" />*/}
                </a>
              </li>
              <li onClick={() => this.changeTab('tab5')}>
                <a className={this.state.active_tab === 'tab5' ? 'active' : ''}>
                  Linked Account
                </a>
              </li>
              <li onClick={() => this.changeTab('tab6')}>
                <a className={this.state.active_tab === 'tab6' ? 'active' : ''}>
                  Requests
                </a>
              </li>
              <li onClick={() => this.changeTab('tab8')}>
                <a className={this.state.active_tab === 'tab8' ? 'active' : ''}>
                  Recent Transactions
                </a>
              </li>
              <li onClick={() => this.changeTab('tab7')}>
                <a className={this.state.active_tab === 'tab7' ? 'active' : ''}>
                  More
                </a>
              </li>
            </ul>

            {this.state.active_tab === 'tab1' && (
              <>
                <div className="row mb-2 pb-1">
                  <div className="col-sm-8 col-12">Applicant</div>
                  <div className="col-sm-4 col-12 text-left text-sm-right">
                    <a className="hyper-link">
                      {accountDetails &&
                        accountDetails.customer_type &&
                        accountDetails.customer_type.toLowerCase()}
                    </a>
                  </div>
                </div>
                <div className="row mb-2 pb-1">
                  <div className="col-sm-8 col-12">Is Active</div>
                  <div className="col-sm-4 col-12 text-left text-sm-right">
                    {accountDetails && accountDetails.is_active
                      ? 'Active'
                      : 'InActive'}
                    <a
                      className="back-button export ml-2"
                      onClick={() => {
                        this.handleIsActiveToggle()
                      }}
                    >
                      Update Status
                    </a>
                  </div>
                </div>
                <div className="row mb-2 pb-1 text-right applicant-label-outer">
                  <div className="col-sm-3 col-12 text-left text-sm-right">
                    <div className="applicant-label">Primary Applicant</div>
                    <div className="row">
                      <div className="col-sm-6 col-12 font-weight-bold">
                        {accountDetails && accountDetails.first_name}
                      </div>
                      <div className="col-sm-6 col-12 font-weight-bold">
                        {accountDetails && accountDetails.last_name}
                      </div>
                    </div>
                  </div>
                  {isJointAccount && (
                    <div className="col-sm-3 col-12 text-left text-sm-right">
                      <div className="applicant-label">Secondary Applicant</div>
                      <div className="row">
                        <div className="col-sm-6 col-12 font-weight-bold">
                          {jointUser.joint_first_name}
                        </div>
                        <div className="col-sm-6 col-12 font-weight-bold">
                          {jointUser.joint_last_name}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Email</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {accountDetails && accountDetails.apex_email}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {jointUser && jointUser.joint_email}
                      </a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Phone</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">{`${get(
                      accountData,
                      'profile[0].country_code'
                    )} ${get(accountData, 'profile[0].phone')}`}</a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">{`${get(
                        jointUser,
                        'joint_country_code'
                      )} ${jointUser && jointUser.joint_phone}`}</a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Gender</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">{this.getGender(accountData)}</a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">N/A</a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Marital Status</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {accountDetails &&
                        capitalize(accountDetails.marital_status)}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">N/A</a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Citizenship</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {accountDetails && accountDetails.citizenship}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {jointUser && jointUser.joint_citizenship}
                      </a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Date of birth</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {get(accountData, 'profile[0].date_of_birth', '-')}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {jointUser && jointUser.joint_dob}
                      </a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>
                    Social Security Number
                  </div>
                  <div className={columnValue.field}>
                    <b>{accountDetails && accountDetails.ss_number}</b>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <b>{jointUser && jointUser.joint_ssn}</b>
                    </div>
                  )}
                </div>

                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Address</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">{`${get(
                      accountData,
                      'profile[0].address'
                    )} ${get(accountData, 'profile[0].city')} ${get(
                      accountData,
                      'profile[0].state'
                    )}  ${get(accountData, 'profile[0].country')} ${get(
                      accountData,
                      'profile[0].zipcode'
                    )}`}</a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">{`${get(
                        jointUser,
                        'joint_street'
                      )} ${get(jointUser, 'joint_city')} ${get(
                        jointUser,
                        'joint_state'
                      )}  ${this.getJointCountryFullName(jointUser)} ${get(
                        jointUser,
                        'joint_postal'
                      )}`}</a>
                    </div>
                  )}
                </div>

                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Investment Experience</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {accountDetails && accountDetails.investment_exprience}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">N/A</a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>Account Created Date</div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {apexStatus && apexStatus.request_time
                        ? moment(apexStatus.request_time).format('MM/DD/YYYY')
                        : '-'}
                    </a>
                  </div>
                </div>
              </>
            )}

            {this.state.active_tab === 'tab2' && (
              <div>
                {isJointAccount && (
                  <div className="row mb-2 pb-1 text-right applicant-label-outer">
                    <div className="col text-left text-sm-right">
                      <div className="applicant-label">Primary Applicant</div>
                    </div>
                    {isJointAccount && (
                      <div className="col-sm-3 col-12 text-left text-sm-right">
                        <div className="applicant-label">
                          Secondary Applicant
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>
                    You/Family member is a senior executive or 10% shareholder
                    at a publicly traded company
                  </div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {accountDetails &&
                      accountDetails.is_control_person === 'YES'
                        ? 'Yes'
                        : 'No'}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {jointUser &&
                        jointUser.joint_is_control_person === 'YES'
                          ? 'Yes'
                          : 'No'}
                      </a>
                    </div>
                  )}
                </div>
                {(accountDetails &&
                  accountDetails.is_control_person === 'YES') ||
                  (jointUser && jointUser.joint_is_control_person === 'YES' && (
                    <div className="row mb-2 pb-1">
                      <div className={columnValue.label} />
                      <div className={`${columnValue.field} font-weight-bold`}>
                        {accountDetails && accountDetails.stock_name}
                      </div>
                      <div className={`${columnValue.field} font-weight-bold`}>
                        {jointUser && jointUser.joint_stock_name}
                      </div>
                    </div>
                  ))}
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>
                    You, or anyone authorized to trade in your account,
                    affiliated with, work with or work for a member firm of a
                    Stock Exchange or FINRA?
                  </div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {accountDetails &&
                      accountDetails.is_affiliated_exchange_finra === 'YES'
                        ? 'Yes'
                        : 'No'}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {jointUser &&
                        jointUser.joint_is_affiliated_exchange_finra === 'YES'
                          ? 'Yes'
                          : 'No'}
                      </a>
                    </div>
                  )}
                </div>
                {(accountDetails &&
                  accountDetails.is_affiliated_exchange_finra === 'YES') ||
                  (jointUser &&
                    jointUser.joint_is_affiliated_exchange_finra === 'YES' && (
                      <Fragment>
                        <div className="row mb-2 pb-1">
                          <div className={columnValue.label}>Firm Name</div>
                          <div className={columnValue.field}>
                            <a className="hyper-link">
                              {accountDetails && accountDetails.firm_name}
                            </a>
                          </div>
                          {isJointAccount && (
                            <div className={columnValue.field}>
                              <a className="hyper-link">
                                {jointUser && jointUser.joint_firm_name}
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="row mb-2 pb-1">
                          <div className={columnValue.label}>Upload Type</div>
                          {get(accountDetails, 'upload_407') ||
                            (get(accountDetails, 'upload_3210') && (
                              <div className={columnValue.field}>
                                <a
                                  className="hyper-link"
                                  onClick={() =>
                                    this.onDownloadSnap(
                                      get(accountDetails, 'upload_407')
                                        ? 'Upload 407'
                                        : get(accountDetails, 'upload_3210')
                                        ? 'Upload 3210'
                                        : '',
                                      get(accountDetails, 'upload_407') ||
                                        get(accountDetails, 'upload_3210')
                                    )
                                  }
                                >
                                  {get(accountDetails, 'upload_407')
                                    ? 'Upload 407'
                                    : get(accountDetails, 'upload_3210')
                                    ? 'Upload 3210'
                                    : ''}
                                  {this.state.loadingSnapId ===
                                    (get(accountDetails, 'upload_407') ||
                                      get(accountDetails, 'upload_3210')) && (
                                    <Loader />
                                  )}
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                    style={{ color: '#56a9e7', marginLeft: 5 }}
                                  ></i>
                                </a>
                              </div>
                            ))}
                          {isJointAccount && (
                            <Fragment>
                              {get(jointUser, 'joint_upload_file_type') && (
                                <div className={columnValue.field}>
                                  <a
                                    className="hyper-link"
                                    onClick={() =>
                                      this.onDownloadSnap(
                                        get(
                                          jointUser,
                                          'joint_upload_file_type'
                                        ),
                                        get(jointUser, 'joint_upload')
                                      )
                                    }
                                  >
                                    {jointUser &&
                                      jointUser.joint_upload_file_type}
                                    {this.state.loadingSnapId ===
                                      get(jointUser, 'joint_upload') && (
                                      <Loader />
                                    )}
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                      style={{
                                        color: '#56a9e7',
                                        marginLeft: 5
                                      }}
                                    ></i>
                                  </a>
                                </div>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </Fragment>
                    ))}
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label}>
                    You/Family member are politically exposed
                  </div>
                  <div className={columnValue.field}>
                    <a className="hyper-link">
                      {accountDetails &&
                      accountDetails.is_political_exposed === 'YES'
                        ? 'Yes'
                        : 'No'}
                    </a>
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {jointUser &&
                        jointUser.joint_is_political_exposed === 'YES'
                          ? 'Yes'
                          : 'No'}
                      </a>
                    </div>
                  )}
                </div>
                <div className="row mb-2 pb-1">
                  <div className={columnValue.label} />
                  <div className={columnValue.field}>
                    {politicalOrganisation &&
                      politicalOrganisation.map((obj, idx) => {
                        return (
                          <div key={idx} className="row">
                            <div className="col font-weight-bold">
                              {obj.political_organization}
                            </div>
                            <div className="col font-weight-bold">
                              {obj.relation}
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  {isJointAccount && (
                    <div className={columnValue.field}>
                      {jointUser.joint_relation &&
                        jointUser.joint_relation.map((obj, idx) => {
                          return (
                            <div key={idx} className="row">
                              <div className="col font-weight-bold">
                                {obj.political_organization}
                              </div>
                              <div className="col font-weight-bold">
                                {obj.relation}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
                {accountDetails && accountDetails.time_horizon && (
                  <div className="row mb-2 pb-1">
                    <div
                      className={columnValue.label}
                    >{`Number of Years planned to invest to achieve particular financial goal`}</div>
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {accountDetails && accountDetails.time_horizon}
                      </a>
                    </div>
                    {isJointAccount && (
                      <div className={columnValue.field}>
                        <a className="hyper-link">N/A</a>
                      </div>
                    )}
                  </div>
                )}
                {accountDetails && accountDetails.liquidity_needs && (
                  <div className="row mb-2 pb-1">
                    <div
                      className={columnValue.label}
                    >{`Ability to quickly and easily convert all or a portion of the account assets into cash without experiencing significant loss`}</div>
                    <div className={columnValue.field}>
                      <a className="hyper-link">
                        {accountDetails && accountDetails.liquidity_needs}
                      </a>
                    </div>
                    {isJointAccount && (
                      <div className={columnValue.field}>
                        <a className="hyper-link">N/A</a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {this.state.active_tab === 'tab3' && (
              <div>
                <div className="row mb-2 pb-1">
                  <div className="col-sm-8 col-12">Name of the employer</div>
                  <div className="col-sm-4 col-12 text-left text-sm-right">
                    {accountDetails && accountDetails.employer_name}
                  </div>
                </div>
                <div className="row mb-2 pb-1">
                  <div className="col-sm-8 col-12">Occupation</div>
                  <div className="col-sm-4 col-12 text-left text-sm-right">
                    <a className="hyper-link">
                      {accountDetails && accountDetails.occupation}
                    </a>
                  </div>
                </div>
              </div>
            )}

            {this.state.active_tab === 'tab4' && (
              <div>
                <Documents
                  apexStatus={apexStatus}
                  showSuspendedAccount={showSuspendedAccount}
                  fetchProfile={this.fetchProfile}
                  apex_account_id={apex_account_id}
                  accountData={accountData}
                  apex_account_no={apex_account_no}
                />
              </div>
            )}
            {this.state.active_tab === 'tab5' && (
              <div>
                <div className="row mb-2 pb-1">
                  <div className="col-sm-8 col-12">
                    {this.state.accountData &&
                    this.state.accountData.bank &&
                    this.state.accountData.bank[0]
                      ? this.state.accountData.bank[0].bank_name
                        ? this.state.accountData.bank[0].bank_name
                        : this.state.accountData.bank[0].bank_account_owner_name
                      : 'Bank account is not linked.'}
                  </div>
                  <div className="col-sm-4 col-12 text-left text-sm-right font-weight-bold">
                    {last5Digits ? `XXXXX${last5Digits}` : ''}
                  </div>
                </div>
              </div>
            )}
            {this.state.active_tab === 'tab6' && (
              <div>
                <RequestTab
                  data={account_request}
                  onClick={this.closeAccountAction}
                />
              </div>
            )}
            {this.state.active_tab === 'tab7' && (
              <MoreTab selectedAccount={accountDetails} />
            )}
          </div>
        </div>

        {this.state.accountData &&
          this.state.accountData.ach &&
          this.state.accountData.ach.length > 0 && (
            <div className="content-box">
              <div className="section-detail">
                <div className="row">
                  <div className="col mb-2">
                    <label>ACH Relationship</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Bank Mandate</label>
                  </div>
                </div>
                {this.state.accountData &&
                  this.state.accountData.ach &&
                  this.state.accountData.ach.length > 0 &&
                  this.state.accountData.ach.map((obj, idx) => {
                    let className =
                      this.state.accountData.ach.length === 1
                        ? 'row relationship-row border-0'
                        : 'row relationship-row border-1'
                    return (
                      <div className={className} key={idx}>
                        <div className="col-sm-10">
                          <div className="relationship-name mb-1">
                            <label>
                              {obj.nickname} : {obj.bank_account_no}
                            </label>
                          </div>
                          <div className="d-flex">
                            <div className="from-to">
                              <p>Medthod</p>
                              <p>ACH ID</p>
                            </div>
                            <span className="hyper-link pt-3 pr-2 pl-2">
                              {/*<i className="fa fa-arrow-right" />*/}
                            </span>
                            <div className="from-to">
                              <p>{obj.approval_method}</p>
                              <p>{obj.ach_id}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2 pt-3">
                          <button className="blue-btn active-btn float-right mb-1">
                            {obj.status}
                          </button>
                          <p className="text-right w-100 float-right d-block">
                            Created{' '}
                            {moment(obj.creation_time).format('MM/DD/YYYY')}
                          </p>
                          {this.state.accountData.isLoading && <Loader />}
                          <div
                            className="cancel-ach-btn"
                            onClick={() => this.onClickCancel()}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>{' '}
                            Cancel
                          </div>
                        </div>
                        <ReasonModal
                          isOpen={this.state.showReasonModal}
                          onExit={this.handleCloseReasonModal}
                          id={obj.id}
                          handleConfirm={this.cancelACH}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
        {recentTransactions &&
          recentTransactions.deposits &&
          recentTransactions.deposits.length > 0 &&
          this.state.active_tab === 'tab8' && (
            <div className="content-box">
              <div className="section-detail">
                <div className="row">
                  <div className="col">
                    <label>Recent Bank Transactions</label>
                  </div>
                </div>
                <div className="row relationship-row">
                  <div className="col-4 text-left mb-2 mb-sm-0">
                    <label>Amount</label>
                  </div>
                  <div className="col-3 text-left mb-2 mb-sm-0">
                    <label>Type</label>
                  </div>
                  <div className="col-2 text-left mb-2 mb-sm-0">
                    <label>Status</label>
                  </div>
                  <div className="col-3 text-right mb-2 mb-sm-0">
                    <label>Date</label>
                  </div>
                </div>
                {recentTransactions &&
                  recentTransactions.deposits &&
                  recentTransactions.deposits.length > 0 &&
                  recentTransactions.deposits.map((obj, idx) => {
                    let className =
                      recentTransactions.deposits.length === 1
                        ? 'row relationship-row border-0'
                        : 'row relationship-row border-1'
                    return (
                      <div className={className} key={idx}>
                        <div className="col-4 text-left mb-2 mb-sm-0">
                          <p>
                            <span
                              className={
                                obj.amount > 0
                                  ? 'hyper-link approved'
                                  : 'hyper-link rejected'
                              }
                            >
                              ${obj.amount}
                            </span>
                          </p>
                        </div>
                        <div className="col-3 text-left mb-2 mb-sm-0">
                          <p>{obj.amount > 0 ? 'Deposit' : 'Withdrawal'}</p>
                        </div>
                        <div className="col-2 text-left mb-2 mb-sm-0">
                          <p>{obj.status}</p>
                        </div>
                        <div className="col-3 text-right mb-2 mb-sm-0">
                          <p>{moment(obj.created_at).format('MM/DD/YYYY')}</p>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          )}

        {recentTransactions &&
          recentTransactions.trade_line_data &&
          recentTransactions.trade_line_data.length > 0 &&
          this.state.active_tab === 'tab8' && (
            <div className="content-box">
              <div className="section-detail">
                <div className="row">
                  <div className="col">
                    <label>Recent Orders</label>
                    {/* <a className="hyper-link float-right">More <i className="fa fa-angle-right" /></a> */}
                  </div>
                </div>
                <div className="row relationship-row">
                  <div className="col-4 text-left">
                    <label>Ticker</label>
                  </div>
                  <div className="col-3 text-left">
                    <label>Type</label>
                  </div>
                  <div className="col-2 text-left">
                    <label>Status</label>
                  </div>
                  <div className="col-3 text-right">
                    <label>Date</label>
                  </div>
                </div>

                {recentTransactions &&
                  recentTransactions.trade_line_data &&
                  recentTransactions.trade_line_data.length > 0 &&
                  recentTransactions.trade_line_data.map((obj, idx) => {
                    let className =
                      recentTransactions.trade_line_data.length === 1
                        ? 'row relationship-row border-0'
                        : 'row relationship-row border-1'

                    return (
                      <div className={className} key={idx}>
                        <div className="col-4 text-left">
                          <p>
                            {obj.ticker}({obj.quantity} Qty. @ $
                            {obj.order_price})
                          </p>
                        </div>
                        <div className="col-3 text-left">
                          <p>{obj.order_type}</p>
                        </div>
                        <div className="col-2 text-left">
                          <p>{obj.order_status}</p>
                        </div>
                        <div className="col-3 text-right">
                          <p>{moment(obj.trade_date).format('MM/DD/YYYY')}</p>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  let returnState = {}

  returnState.isFetching = createLoadingSelector(['ACCOUNT_DATA'])({ state })
  returnState.accountData =
    state.account && state.account.account ? state.account.account : {}
  returnState.user = state.auth.user
  returnState.auth = state.auth
  returnState.loggedIn = state.auth.loggedIn
  returnState.error = state.account.error

  return returnState
}

const mapDispatchToProps = {
  tradingAccountDetailRequest,
  fetchAccountStatus,
  updateAccountStatus,
  cancelACHRequest,
  setAccountState
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
