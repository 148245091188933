import { takeEvery, fork, call, put, all } from 'redux-saga/effects'
import qs from 'query-string'
import get from 'lodash/get'
import ActionTypes from 'action-types';
import * as actions from 'actions/sponsers';
import * as SponserApis from 'apis/sponsers';

function* getSponsers(action) {
  const { payload } = action;
  yield put(actions.setSponserLoading(true));
  try {
    yield put(actions.setSponserError(""));
    const response = yield call(SponserApis.fetchSponsersList, qs.stringify(payload))
    if(Array.isArray(response.data.result)){
      yield put(actions.setSponserSuccess({...response.data, page: payload.page}));
    }else{
      yield put(actions.setSponserError("No Sponsers Yet!"));
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.setSponserError(errorDetails));
  }
  yield put(actions.setSponserLoading(false));
}

function* sponserSaga() {
  yield all([
    fork(takeEvery, ActionTypes.GET_SPONSERS, getSponsers),
  ])
}

export default sponserSaga
