import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, NavLink, Redirect } from 'react-router-dom'
import { Nav, Button } from 'reactstrap'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import { UserMenuPopover } from './UserMenuPopover'
import * as AuthUtils from 'common/auth-utils'
import { addEventToAnalytics, csvToJSON } from 'Utils'

function UserMenuComponent({
  onLogout,
  isLoggedIn,
  showSinginIcon,
  className
}) {
  const changeRoute = profile => {
    if (profile === 'Profile') {
      addEventToAnalytics(
        'User Menu Profile',
        'User Menu Profile',
        'USER_MENU_PROFILE',
        { url: window.location.href },
        true
      )
    } else if (profile === 'Connected Broker') {
      addEventToAnalytics(
        'User Menu Brokers',
        'User Menu Brokers',
        'USER_MENU_BROKERS',
        { url: window.location.href },
        true
      )
    } else if (profile === 'Sign-out') {
      addEventToAnalytics(
        'User Menu Signout',
        'User Menu Signout',
        'USER_MENU_SIGNOUT',
        { url: window.location.href },
        true
      )
    } else {
      addEventToAnalytics('User Profile', 'User Profile', 'USER_PROFILE', {
        url: window.location.href,
        profile
      })
    }
  }

  const inputFile = useRef(null)
  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  let [width, setWidth] = useState(getWidth())
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [notify, setNotify] = useState(false)
  const [clientOption, setClientOption] = useState('')
  useEffect(() => {
    const resizeListener = () => {
      setWidth(getWidth())
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const class_type = window.localStorage.getItem('self_role')
  const { firstName = '', lastName = '' } = AuthUtils.getAuthToken()
  const userName = `${firstName} ${lastName}`
  const shortName = `${firstName && firstName[0]}${firstName && lastName[0]}`
  const portalLink =
    'https://portal.magnifi.us?at=' + window.localStorage.getItem('token')

  return (
    <div className={className}>
      <Nav className="ml-auto" navbar>
        {!isLoggedIn ? (
          <Link className="navbar-brand mx-auto h1" to="/login">
            <Button
              color="Link"
              className="nav-link text-secondary"
              title="Sign-in"
              onClick={() => {
                addEventToAnalytics(
                  'Sign In Result Page Button',
                  'Sign In Result Page Button',
                  'SIGN_IN_RESULT_PAGE_BUTTON',
                  { clickBtn: 'Sign In' }
                )
              }}
            >
              <i className="far fa-fw fa-sign-in-alt"></i>
            </Button>
          </Link>
        ) : (
          <>
            {/* <a id="user-popover">
              <i className="far fa-fw fa-user text-secondary" />
            </a>
            <UserMenuPopover className={className} onLogout={onLogout} /> */}
            {/* <NavLink
                      to="/settings/referral?invite=true"
                      className="referral-link"
                    >
                      Referrals
                    </NavLink> */}

            <UncontrolledDropdown className="user-profile">
              <DropdownToggle tag="a">
                {/* <i className="far fa-fw fa-user text-secondary" /> */}
                <span className="user-menu__name-badge">
                  {shortName && shortName.toUpperCase()}
                </span>
              </DropdownToggle>
              <DropdownMenu tag="ul" className=" ">
                <DropdownItem
                  header
                  tag="li"
                  className="user-menu__item user-menu__item--user-info"
                >
                  {/* <span className="user-menu__name-badge">
                    {shortName && shortName.toUpperCase()}
                  </span> */}
                  <span className="user-menu__user-name">{userName}</span>
                  {/* {(typeof class_type !== 'undefined' && class_type !== '' && class_type != 'all')
                       && (
                         <div className="user-tooltip">
                            <img id="class_type" className="card-user-class-icon" src={`/assets/images/${class_type}.png`} width='10' />
                            <Tooltip className="col-grey" placement="right" isOpen={tooltipOpen} target="class_type" toggle={toggle}>
                            {class_type === 'retail' ? 'Retail Class': <div>Institutional <br/>Class</div>}
                            </Tooltip>
                         </div>
                        )
                    } */}
                </DropdownItem>

                {/* <DropdownItem divider /> */}
                <DropdownItem
                  className="user-menu__item"
                  tag="li"
                  onClick={() => changeRoute('Sign-out')}
                >
                  <Link to="#" className="user-menu__link" onClick={onLogout}>
                    <i className="fa fa-sign-out mr-2" />
                    Sign-out
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        )}
      </Nav>
    </div>
  )
}

const mapStateToProps = ({
  auth: { user_advisor_menu, client_exist },
  profile
}) => ({
  user_advisor_menu,
  client_exist
})

const mapDispatchToProps = dispatch => ({})

export const UserMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenuComponent)

UserMenu.propTypes = {
  showSinginIcon: PropTypes.bool
}

UserMenu.defaultProps = {
  showSinginIcon: true
}
