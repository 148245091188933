import * as Yup from 'yup'
import config from '../../../env'
import axios from 'axios'
import { ApiHeaders } from '../../../DataSet'
import { openNotificationWithIcon } from 'layouts/utils'
import { getNotification } from '../utils'

export const requiredValidation = Yup.object().shape({
  es_number: Yup.string()
    .required('This is a required field')
    .min(8, 'Must be at least 8 characters')
})

export const populateHoldings = async (values, setLoading) => {
  setLoading(true)
  try {
    const data = await axios.get(
      `${config.profileBaseUrl}/profile/populate/es/holdings/${values.es_number}`,
      { headers: ApiHeaders() }
    )
    setLoading(false)
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    setLoading(false)
    getNotification(
      'error',
      error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
    )
  }
}

export const populatePortfolio = async (values, setLoading) => {
  setLoading(true)
  try {
    const data = await axios.get(
      `${config.profileBaseUrl}/profile/populate/es/portfolio/${values.es_number}`,
      { headers: ApiHeaders() }
    )

    setLoading(false)
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    setLoading(false)
    getNotification(
      'error',
      error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
    )
  }
}

export const populateProfile = async (values, setLoading) => {
  setLoading(true)
  try {
    const data = await axios.get(
      `${config.profileBaseUrl}/profile/populate/es/profile/${values.es_number}`,
      { headers: ApiHeaders() }
    )
    setLoading(false)
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    setLoading(false)
    getNotification(
      'error',
      error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
    )
  }
}
