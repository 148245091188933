import { PREMIUM_ACCESS } from 'DataSet'

export function getAuthToken() {
  var c = document.cookie.split('; ').reduce((prev, current) => {
    const [name, value] = current.split('=')
    prev[name] = value
    return prev
  }, {})
  const email = window.localStorage.getItem('email')
  const firstName = window.localStorage.getItem('firstName') || ''
  const lastName = window.localStorage.getItem('lastName') || ''
  var hostname = window.location.hostname
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '')
    .split(/[/?#]/)[0]

  if (!c.magnifi_session && hostname === 'discover.magnifi.com') {
    if (email && firstName && lastName)
      document.cookie =
        'magnifi_session=' +
        JSON.stringify({ email, firstName, lastName }) +
        ';domain=.magnifi.com;path=/;'
  }

  return {
    token: window.localStorage.getItem('token'),
    email: window.localStorage.getItem('email'),
    firstName: window.localStorage.getItem('firstName') || '',
    lastName: window.localStorage.getItem('lastName') || '',
    isGoogleLogin: window.localStorage.getItem('isGoogleLogin') === 'true',
    termsAgreed: parseInt(window.localStorage.getItem('termsAgreed')),
    self_role: window.localStorage.getItem('self_role') || '',
    client_exist: window.localStorage.getItem('client_exist') === 'true',
    user_advisor_menu:
      window.localStorage.getItem('user_advisor_menu') === 'true'
  }
}

export function isPremiumUser(data) {
  const email = data.email || window.localStorage.getItem('email')
  return PREMIUM_ACCESS.includes(email)
}

export function storeAuthToken({
  token,
  email,
  firstName = '',
  lastName = '',
  isGoogleLogin = false,
  termsAgreed = 0,
  self_role = '',
  client_exist,
  user_advisor_menu,
  userId
}) {
  window.localStorage.setItem('auth-type', 'user')
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('email', email)
  window.localStorage.setItem('firstName', firstName || '')
  window.localStorage.setItem('lastName', lastName || '')
  window.localStorage.setItem('isGoogleLogin', isGoogleLogin)
  window.localStorage.setItem('termsAgreed', termsAgreed)
  window.localStorage.setItem('self_role', self_role)
  window.localStorage.setItem('client_exist', client_exist)
  window.localStorage.setItem('user_advisor_menu', user_advisor_menu)
  window.localStorage.setItem('user_id', userId)
  var hostname = window.location.hostname
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '')
    .split(/[/?#]/)[0]
  if (hostname === 'discover.magnifi.com') {
    document.cookie =
      'magnifi_session=' +
      JSON.stringify({ email, firstName, lastName }) +
      ';domain=.magnifi.com;path=/;'
  }
}

export function deleteAuthToken() {
  window.localStorage.removeItem('auth-type')
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('email')
  window.localStorage.removeItem('firstName')
  window.localStorage.removeItem('lastName')
  window.localStorage.removeItem('isGoogleLogin')
  window.localStorage.removeItem('termsAgreed')
  window.sessionStorage.removeItem('query')
  window.localStorage.removeItem('self_role')
  window.localStorage.removeItem('client_exist')
  window.localStorage.removeItem('user_advisor_menu')
  var hostname = window.location.hostname
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '')
    .split(/[/?#]/)[0]
  if (hostname === 'discover.magnifi.com') {
    document.cookie =
      'magnifi_session=; path=/; domain=.magnifi.com; expires=' +
      new Date(0).toUTCString()
  }
}

export function updateToken(token) {
  window.localStorage.setItem('token', token)
}

export function updateQuery(query) {
  window.sessionStorage.setItem('query', query)
}

export function getQuery(query) {
  return window.sessionStorage.getItem('query')
}
