import ActionTypes from '../action-types'

export function fetchTrades(payload, callback) {
  return {
    type: ActionTypes.FETCH_ALL_TRADES,
    payload,
    callback
  }
}

export function fetchCustomTrades(payload, callback) {
  return {
    type: ActionTypes.FETCH_CUSTOM_TRADES,
    payload,
    callback
  }
}
export function successfulFetchCustomTrades(trades) {
  return {
    type: ActionTypes.CUSTOM_TRADES_SUCCESS,
    trades
  }
}

export function successfulFetchTrades(trades) {
  return {
    type: ActionTypes.TRADES_SUCCESS,
    trades
  }
}
export function failedCustomFetchTrades(error) {
  return {
    type: ActionTypes.CUSTOM_TRADES_FAILURE,
    error
  }
}

export function failedFetchTrades(error) {
  return {
    type: ActionTypes.TRADES_FAILURE,
    error
  }
}
