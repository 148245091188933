const devConfig = {
  apiBaseUrl: 'https://api-dev.magnifi.com/main/api',
  profileBaseUrl: 'https://api-dev.magnifi.com/profile/api',
  adminBaseUrl: 'https://api-dev.magnifi.com/admin-api/',
  tradeServiceBaseUrl: 'https://api-dev.magnifi.com/trade-service/api',
  oktaClientId: '0oa1k4uyv06twnAW8697',
  oktaIssuer: 'dev.auth.magnifi.com',
  goService: 'https://api-dev.magnifi.com/go-profile-service'
}

export default devConfig
