import moment from 'moment'
import _ from 'lodash'
import enquire from 'enquire-js'
import { ViewNames, cn, QuinItems, SectorsAttrs } from './DataSet'
import { getCookie } from './layouts/utils'
import { isNull, isUndefined } from 'util'
const parseDomain = require('parse-domain')

export const getImage = img => {
  const obj = {
    TrackRecord: '/assets/images/icons/TrackRecord.svg',
    OutPerform: '/assets/images/icons/OutPerformance.svg',
    Yield: '/assets/images/icons/Yield.svg',
    Sharpe: '/assets/images/icons/Sharpe.svg',
    Assets: '/assets/images/icons/Assets.svg',
  };
  return obj[img];
}

export const formatNumber = n => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export const getWindowScreen = () => {
  let size = {
    mobileDevice: false,
    tabDevice: false,
    smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
    desktopScreen: false,
    bigScreen: false
  }

  if (window.innerWidth > 1850) {
    // hd desktop resol or bigScreen
    size = {
      mobileDevice: false,
      tabDevice: false,
      smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
      desktopScreen: false,
      bigScreen: true
    }
  } else if (window.innerWidth >= 1281 && window.innerWidth <= 1680) {
    // desktop resol or desktopScreen
    size = {
      mobileDevice: false,
      tabDevice: false,
      smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
      desktopScreen: true,
      bigScreen: false
    }
  } else if (window.innerWidth >= 1024 && window.innerWidth <= 1280) {
    // smallScreen
    const media = 'only screen and (min-width: 1024px) and (max-width: 1280px)'
    size = {
      mobileDevice: false,
      tabDevice: false,
      smallScreen: true, //only screen and (min-width: 1024px) and (max-width: 1280px)
      desktopScreen: false,
      bigScreen: false
    }
  } else if (window.innerWidth <= 1023) {
    // mobileDevice
    const media = 'only screen and (min-width: 320px) and (max-width: 1023px)'
    size = {
      mobileDevice: true,
      tabDevice: false,
      smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
      desktopScreen: false,
      bigScreen: false
    }
  }

  return size
}

export const enquireScreen = (cb, media) => {
  if (media !== '') {
    enquire.register(media, {
      match: () => {
        cb && cb(true)
      },
      unmatch: () => {
        cb && cb()
      }
    })
  }
}

export const findAndUpdateArray = ({ array, item, searchKey, targetKey }) => {
  if (array && array.length && searchKey && item && targetKey) {
    let _item = array.find(e => cn(e, searchKey) === cn(item, searchKey))
    if (_item) _item[targetKey] = item[targetKey]
    return array
  }
}

const applyRegex = (str, re) => {
  const result = str.replace(re, function(m, g1, g2) {
    return g1 ? g1 : g2 + '\r'
  })
  const arr = result.split('\r')
  return arr
}

export const paraToSentence = string => {
  const regex = /\b(\w\.\w\.)|([.?!])\s+(?=[A-Za-z])/g
  const arr = applyRegex(string, regex)
  return arr
}

export const getImageOrFallback = (path, fallback) => {
  return new Promise(resolve => {
    const img = new Image()
    img.src = path
    img.onload = () => resolve(path)
    img.onerror = () => resolve(fallback)
  })
}

// returns either array or object
export const recursiveSearch = (arr, childArr, key, value) => {
  if (typeof arr !== 'undefined') {
    if (Object.prototype.toString.call(arr[childArr]) === '[object Array]') {
      const check = arr[childArr].find(item => item[key] === value)
      if (typeof check !== 'undefined') {
        return check
      } else {
        return arr[childArr].map(item => {
          if (
            Object.prototype.toString.call(item[childArr]) === '[object Array]'
          ) {
            return item[childArr].find(i => {
              if (typeof i !== 'undefined') {
                if (i[key] === value) {
                  return i
                }
                recursiveSearch(i, childArr, key, value)
              }
            })
          }
        })
      }
    }
  } else {
    return `Not an array`
  }
}

export const randomValue = val => {
  const r = Math.random()
  return val * (0.9 + 0.2 * r)
}

export const getQuintile = (val, ref) => {
  if (val < 0.6 * ref) return 1
  else if (val >= 0.6 * ref && val < 0.9 * ref) return 2
  else if (val >= 0.9 * ref && val < 1.1 * ref) return 3
  else if (val >= 1.1 * ref && val < 1.5 * ref) return 4
  else if (val >= 1.5 * ref) return 5
}

export const quintileClass = (value, reverse = false) => {
  var v = reverse ? 6 - value : value
  switch (v) {
    case 1:
      return 'danger'
    case 2:
    case 3:
    case 4:
      return 'warning'
    case 5:
      return 'success'
    default:
      return 'muted'
  }
}

export const quintileSize = value => {
  switch (value) {
    case 1:
      return 1
    case 2:
    case 3:
    case 4:
      return 2
    case 5:
      return 3
    default:
      return 1
  }
}

export const quintileText = value => ['Low', 'Average', 'High'][value - 1]

export const arrayToHash = (arr, key) => {
  let out = {}
  arr.forEach(e => {
    out[e[key]] = e
  })
  return out
}

export const queryStr = q => {
  return q ? `?q=${encodeURIComponent(q)}` : ''
}



/* getNest(['user', 'posts', 0, 'comments'], dataObj) */
export const getNest = (path, obj) =>
  path.reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : undefined), obj)
export const getNestDefault = (path, obj, val) =>
  path.reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : val), obj)

export const uniqValues = (arr, key) => [...new Set(arr.map(e => e[key]))]



export const monthToDate = str => moment('01 ' + str, 'DD MMM YYYY')
export const dateToMonth = date => date.format('MMM YYYY')

export const isString = x => {
  return Object.prototype.toString.call(x) === '[object String]'
}

export const toNumber = v => {
  if (isString(v)) {
    return Number(v)
  } else {
    return v
  }
}

export const numFmt = (num, prec = 2) =>
  num
    ? num.toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: prec
      })
    : ''
// export const numFmt = (num, prec = 2) => (num || 0).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: prec });
export const numDc = (num, prec = 2) => +(num || 0.0).toFixed(prec)

export const arrSum = arr => arr.reduce((a, b) => a + b, 0)

export const validWeight = num => !/\D/.test(num) && num >= 0 && num <= 50

export const validWeight2 = num => {
  const _num = +num
  return Number.isInteger(_num) && _num >= 0 && _num <= 50
}

export const sortFn = attr => (a, b) => {
  let [_a, _b] = [a[attr], b[attr]]
  if (_a !== undefined && _b !== undefined) {
    if (_a < _b) return 1
    else if (_a > _b) return -1
  }
  return 0
}

export const closeTraditWindow = () => {
  if (window._tradeItWindow) {
    window._tradeItWindow.close()
  }
}

export const convertToCapitalCase = str => {
  var frags = str.split('_')
  if (frags.length <= 1) frags = frags[0].split(' ')

  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

Number.prototype.before = function() {
  var value = parseInt(this.toString().split('.')[0], 10) //before
  return value ? value : 0
}

Number.prototype.after = function() {
  var value = parseInt(this.toString().split('.')[1], 10) //after
  return value ? value : 0
}

export const formatTickerForView = str => {
  return str.split('.')[0].substring(0, 4)
}

export const getViewNameList = (showCarousel, view) =>
  (showCarousel && view && view.split(' & ')) || []

export const isViewPresentInChartTypes = view => {
  return ViewNames.some(item => item.name === view)
}

export const CapitalizeEachWord = string => {
  return string.replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase()
  })
}
export const addEventToAnalytics = (
  //heapEvt,
  //krakenEvt,
  googleEvt,
  data,
  queryUUID
) => {
    //to make analytics event async
    setTimeout(()=>{
      const query_uuid = window.sessionStorage.getItem('query_uuid')
      if (queryUUID == true && query_uuid) {
        data = {
          ...data,
          query_uuid: query_uuid
        }
      }
      // try{
      //   //window.addKrakenEvent && typeof window.addKrakenEvent==="function" && window.addKrakenEvent({ name: krakenEvt, body: data })
      //   window.gtag('event', googleEvt, {
      //     event_label: JSON.stringify(data)
      //   })
      // }catch (e) {
      //   console.log(e)
      // }
    },0)
}

export const changeLogo = () => {
  let logo = '/assets/images/MagnifiLogo/Magnifi_Logo_Charcoal_RGB.png'

}

export const getAdditionalDetailsForRegister = () => {
  const campaignDetails = JSON.parse(
    getCookie('magnifiRegisterCampaign') || '{}'
  )
  let utm_source =
    sessionStorage.getItem('utm_source') || campaignDetails.utm_source
  let utm_medium =
    sessionStorage.getItem('utm_medium') || campaignDetails.utm_medium
  let utm_campaign =
    sessionStorage.getItem('utm_campaign') || campaignDetails.utm_campaign

  let domain = parseDomain(utm_source)
  if (domain && domain.domain) {
    utm_source = domain.domain
  }

  let domain1 = parseDomain(utm_campaign)
  if (domain1 && domain1.domain) {
    utm_campaign = domain1.domain
  }

  return {
    ...(utm_source && { utm_source }),
    ...(utm_medium && { utm_medium }),
    ...(utm_campaign && { utm_campaign })
  }
}

export const clearUtmSession = () => {
  sessionStorage.removeItem('utm_source')
  sessionStorage.removeItem('utm_medium')
  sessionStorage.removeItem('utm_campaign')
}

export const FormatCurrency = (value, prec = 0, curr = '$', type) => {
  let _prefix = value < 0 ? '-' : ''
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec]

  if (_value >= 1e9) [_value, _suffix, _prec] = [_value / 1e9, 'B', 2]
  else if (_value >= 1e6) [_value, _suffix, _prec] = [_value / 1e6, 'M', 2]
  else if (_value >= 1e3) [_value, _suffix, _prec] = [_value / 1e3, 'K', 2]

  if (_value >= 100 && _prec >= 0) _prec = 0
  else if (_value >= 10 && _prec >= 0) _prec = 1

  if (type == 'unf') return `${_prefix}${curr}${numFmt(Math.abs(value))}`
  return `${_prefix}${curr}${numFmt(_value, _prec)}${_suffix}`
}

export const getDelimeter = lines => {
  //supported delimeters
  //check for split length for top 10 lines
  //return the first match
  // 245,"sid", sid, 23
  const supported_delimeters = [',', '|', ';', '\t']
  let loop_length = lines.length < 10 ? lines.length : 10
  let identified_delimeter = ''
  for (let y = 0; y < supported_delimeters.length; y++) {
    let current_delimeter = supported_delimeters[y]
    let split_length = -1
    for (let i = 0; i < loop_length; i++) {
      if (lines[i] == '') {
        break
      }
      let current_delimeter_split = lines[i].split(current_delimeter)
      current_delimeter_split = current_delimeter_split.map(l => {
        l = l.trim()
        return l
      })
      console.log(
        'split_length',
        i,
        lines[i],
        split_length,
        current_delimeter_split.length
      )
      if (current_delimeter_split.length == 1) {
        identified_delimeter = ''
        break
      } else if (split_length == -1 && current_delimeter_split.length > 1) {
        console.log(
          'current_delimeter set',
          current_delimeter,
          identified_delimeter
        )
        split_length = current_delimeter_split.length
      } else {
        // other than first row
        if (split_length == current_delimeter_split.length) {
          console.log(
            'current_delimeter',
            current_delimeter,
            identified_delimeter
          )
          identified_delimeter = current_delimeter
        } else {
          //did not matches the first row split length
          console.log(
            'current_delimeter',
            current_delimeter,
            identified_delimeter
          )
          identified_delimeter = ''
          break
        }
      }
    }
    console.log('current_delimeter', current_delimeter, identified_delimeter)
    if (identified_delimeter != '') {
      break
    }
  }
  console.log('identified_delimeter', identified_delimeter)
  return identified_delimeter
}

export const convertToFloatvalues = val => {
  if (val) {
    let number = String(val).replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
    number = parseFloat(number)
    return number
  }
}

export const convertToUpperCase = (val, withQuotes = false) => {
  if (val) {
    let value = withQuotes
      ? String(val)
          .trim()
          .toUpperCase()
      : removeWrappingQuotes(
          String(val)
            .trim()
            .toUpperCase()
        )
    return value
  }
}

export const convertToLowerCase = (val, withQuotes = false) => {
  if (val) {
    let value = withQuotes
      ? String(val)
          .trim()
          .toLowerCase()
      : removeWrappingQuotes(
          String(val)
            .trim()
            .toLowerCase()
        )
    return value
  }
}

export const wholeNumberCheck = (val, prec = 2) => {
  if (val) {
    const number = convertToFloatvalues(val)
    const isWholeNumber = number - Math.floor(number) == 0
    if (isWholeNumber) {
      return parseInt(number)
    } else {
      return Number(number.toFixed(prec))
    }
  }
}

export const removeWrappingQuotes = str => {
  if (str) {
    if (String(str.charAt(0)) == '"' || String(str.charAt(0)) == "'") {
      str = str.substring(1, str.length)
    }
    if (
      String(str.charAt(str.length - 1)) == '"' ||
      String(str.charAt(str.length - 1)) == "'"
    ) {
      str = str.substring(0, str.length - 1)
    }
    return str
  }
}

export const findDelimiter = text => {
  const supportedDelimeters = [',', '|', ';', '\t']
  let identified_delimeter = supportedDelimeters.filter(checkDelimiter)
  let commaDelimiter = /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g
  if (identified_delimeter[0] && identified_delimeter[0] == ',') {
    identified_delimeter[0] = commaDelimiter
  }
  return identified_delimeter.length > 0 ? identified_delimeter[0] : commaDelimiter;
  function checkDelimiter (delimiter) {
      var cache = -1;
      return text.split('\n').every(checkLength);

      function checkLength (line) {
          if (!line) {
              return true;
          }

      var length = line.split(delimiter).length
      if (cache < 0) {
        cache = length
      }
      return cache === length && length > 1
    }
  }
}

export const mapCsvToJSON = (csv, filter, realTimeTickerPrice, noData) => {
  // if(noData){
  //   const obj = {
  //     error: 'No tickers present in file. Kindly upload a new one',
  //   };
  //   return JSON.stringify(obj);
  // }
  // let lines=csv.split("\n");
  let lines = csv.split(/\r\n|\n/)
  const identified_delimeter = findDelimiter(csv)
  if (lines.length > 1) {
    let result = []
    let headers = lines[0].split(identified_delimeter)

    headers = headers
      .map(l => {
        l = convertToLowerCase(l)
        if (l == 'lot date') l = 'date'
        return l
      })
      .filter(item => item)

    if (!headers.length) {
      const obj = {
        error:
          'Please upload a file with the ticker column and at least one of these columns - shares, weight or amount.',
        headers
      }
      return JSON.stringify(obj)
    }

    if (headers.length === 1) {
      if (headers[0] == 'ticker') {
        const obj = {
          error:
            'Only ticker column available! Please upload a file with the ticker column and at least one of these columns - shares, weight or amount.'
        }
        return JSON.stringify(obj)
      }
    }

    if (!headers.includes('ticker')) {
      const obj = {
        error: 'Ticker column is missing. Please add the ticker details.'
      }
      return JSON.stringify(obj)
    }

    if (filter) {
      const { headersArray, maxHeadersCount, addHeader } = filter
      if (headersArray && headersArray.length > 0 && maxHeadersCount) {
        if (headers.length <= maxHeadersCount) {
          const datePresent = _.includes(headersArray, 'date')

          let found = headers.filter(l => {
            // console.log(l, l.length);
            if (_.includes(headersArray, l)) {
              return l
            }
          })

          // if (datePresent) {
          //   const checkDatePresent = found.filter((g) => g === 'date');
          //   if (checkDatePresent.length === 0) {
          //     found.push("date");
          //   }
          // }

          // console.log(found);

          if (found.length === headers.length) {
            // console.log(found);
            if (addHeader && addHeader.length) {
              // console.log(result);
              found = [...found, ...addHeader]
            }
            // console.log(found);
            let uniqueTickers = []
            lines.forEach((item, i) => {
              let obj = {}
              const commaDelimiter = /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g
              let line_delimeter = identified_delimeter
              if (identified_delimeter == ',') {
                line_delimeter = commaDelimiter
              }
              let currentline = lines[i].split(line_delimeter)
              // console.log(found);
              found.forEach((y, k) => {
                obj[found[k]] = currentline[k]
              })
              // Add duplicate values of tickers shares, amount and weight
              if (
                obj.ticker &&
                !uniqueTickers.includes(convertToUpperCase(obj.ticker))
              ) {
                result.push(obj)
                uniqueTickers.push(convertToUpperCase(obj.ticker))
              } else {
                result = result.map(item => {
                  if (
                    convertToUpperCase(item.ticker) ==
                    convertToUpperCase(obj.ticker)
                  ) {
                    if (item.shares && obj.shares) {
                      item.shares =
                        convertToFloatvalues(item.shares) +
                        convertToFloatvalues(obj.shares)
                    }
                    if (item.amount && obj.amount) {
                      item.amount =
                        convertToFloatvalues(item.amount) +
                        convertToFloatvalues(obj.amount)
                    }
                    if (item.weight && obj.weight) {
                      item.weight =
                        convertToFloatvalues(item.weight) +
                        convertToFloatvalues(obj.weight)
                    }
                    return item
                  } else {
                    if (item.ticker) {
                      return item
                    }
                  }
                })
              }
              // result.push(obj);
            })

            if (addHeader && addHeader.length) {
              // console.log(result);
              let sumOfPriceAndShares = 0,
                sumOfShares = 0
              const invalidPriceFormat = new RegExp(
                /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/
              )
              let isDateValid = true

              result.map((y, k) => {
                // Convert ticker to lowercase to maintain consistency
                if (y.ticker && convertToLowerCase(y.ticker) == 'ticker') {
                  y.ticker = convertToLowerCase(y.ticker)
                }
                if (
                  y.ticker &&
                  y.ticker !== 'ticker' &&
                  y.ticker !== '' &&
                  typeof y.ticker !== 'undefined'
                ) {
                  // console.log(' shares before---> ', y.shares);
                  y.ticker = convertToUpperCase(y.ticker)
                  y.shares = wholeNumberCheck(y.shares)
                  // console.log(' shares after --- ', y.shares);
                  if (typeof y.price !== 'undefined') {
                    if (invalidPriceFormat.test(y.price)) {
                      // console.log(y.ticker, 'invalid price format ----', y.price);
                      y.price = y.price.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
                    }
                    // console.log(y.ticker, ' valid for PRICE format ', y.price);
                    y.price = Number(y.price)
                    y.values = y.shares && typeof y.shares !== 'undefined' ? y.price * y.shares : 'NA'
                    // y.price = parseInt(y.price);
                  }

                  // Add real-time price for each ticker
                  if (realTimeTickerPrice) {
                    let tickerDetails = realTimeTickerPrice.funds.find(a => a.ticker.toUpperCase() == y.ticker.toUpperCase())
                    y.realTimePrice = tickerDetails ? tickerDetails.price : 'NA'
                    y.price = y.price; // ? y.price : y.realTimePrice
                    if (
                      typeof y.realTimePrice !== 'undefined' &&
                      y.realTimePrice &&
                      y.realTimePrice != 'NA'
                    ) {
                      // Scenario where user uploads only ticker and weight
                      if (
                        y.weight &&
                        typeof y.weight != 'undefined' &&
                        (!y.shares || y.shares == 'undefined') &&
                        !y.amount && typeof y.amount == 'undefined'
                      ) {
                        y.weight = convertToFloatvalues(y.weight)
                        const portfolioValue = 750000
                        y.shares = wholeNumberCheck(
                          ((y.weight / 100) * portfolioValue) / y.realTimePrice
                        )
                      }
                      // Scenario where user uploads ticker and amount
                      if(y.amount && typeof y.amount != "undefined" && (!y.shares || y.shares == "undefined" || typeof y.shares === 'undefined')){
                        y.amount = convertToFloatvalues(y.amount);
                        y.shares = wholeNumberCheck(parseFloat(y.amount / y.realTimePrice));
                      }

                      y.values = y.shares && typeof y.shares !== 'undefined' ? y.realTimePrice * y.shares : 'NA'

                      if (typeof y.shares !== 'undefined' && y.shares !== 'NA' && y.shares !== 'shares') {
                        // console.log(y.shares);
                        sumOfPriceAndShares += y.realTimePrice * y.shares
                      }
                    } else {
                      if (typeof y.shares !== 'undefined' &&
                      y.shares !== 'NA' &&
                      y.shares !== 'shares' &&
                      typeof y.price !== 'undefined' &&
                      y.price !== 'NA' &&
                      y.price !== 'price'
                    ) {
                        sumOfPriceAndShares += y.price * y.shares
                      }
                    }
                  }

                  // Date Validation
                  if(typeof y.date !== "undefined" && y.date && isDateValid){
                    let checkDate = moment(String(y.date)).isValid();
                    if(!checkDate){
                      checkDate = moment(String(y.date), 'DD-MM-YYYY').isValid();
                    }
                    isDateValid = checkDate;
                  }
                }
              })

              if (!isDateValid) {
                const obj = {
                  error: "Date format is invalid. Please provide dates in the correct format.",
                  headers,
                };
                return JSON.stringify(obj);
              }

              result.forEach((y, k) => {
                if (
                  y.ticker &&
                  y.ticker !== 'ticker' &&
                  y.ticker !== '' &&
                  typeof y.ticker !== 'undefined' &&
                  typeof y.shares !== 'undefined' &&
                  y.shares !== 'NA' &&
                  y.shares !== 'shares'
                ) {
                  // console.log(y, Object.keys(y));
                  const objArr = Object.keys(y)
                  objArr.map((o, n) => {
                    if (_.includes(addHeader, objArr[n])) {
                      if (
                        objArr[n].includes('weight') &&
                        typeof y.realTimePrice !== 'undefined' &&
                        y.realTimePrice !== 'NA'
                      ) {
                         // console.log('additional header-->', objArr[n],'for ticker', y.ticker, y.shares, sumOfPriceAndShares, ((y.realTimePrice*y.shares)/sumOfPriceAndShares)*100);
                        y[objArr[n]] = ((y.realTimePrice * y.shares) / sumOfPriceAndShares) * 100
                      } else {
                        if (
                          objArr[n].includes('weight') &&
                          typeof y.price !== 'undefined' &&
                          y.price !== 'NA'
                        ) {
                          // console.log('additional header-->', objArr[n],'for ticker', y.ticker, y.shares, sumOfPriceAndShares, ((y.price*y.shares)/sumOfPriceAndShares)*100);
                          y[objArr[n]] = ((y.price * y.shares) / sumOfPriceAndShares) * 100
                        } else {
                          y[objArr[n]] = 'NA'
                        }
                      }
                    }
                  })
                }  else {
                  console.log(`------ Failed to calculate weight, SHARES is missing for ${y.ticker} ------`);
                }
              })
            }

            if (result.length < 2) {
              const obj = {
                error: `Please upload a file with details in the columns - ${headers.toString()}`,
                headers,
              };
              return JSON.stringify(obj);
            }

            return JSON.stringify(result)
          } else {
            const obj = {
              error: `Invalid csv format, ${headers.toString()}`,
              headers
            }
            return JSON.stringify(obj)
          }
        } else {
          const obj = {
            error: `Invalid csv format, ${headers.toString()}`,
            headers
          }
          return JSON.stringify(obj)
        }
      } else {
        const obj = {
          error:
            'Filters parameter needs to have headersArray and maxHeadersCount'
        }
        return JSON.stringify(obj)
      }
    } else {
      // console.log(lines.splice(0, 1));
      lines.forEach((item, i) => {
        let obj = {}
        let currentline = lines[i].split(identified_delimeter)
        headers.forEach((y, k) => {
          obj[headers[k]] = currentline[k]
        })
        result.push(obj)
      })
      return JSON.stringify(result)
    }
  } else {
    console.log('not enough lines', lines)
    const obj = {
      error: 'Not Enough Lines'
    }
    return JSON.stringify(obj)
  }
}

export const csvToJSON = csv => {
  var lines = csv.split('\n')
  var result = []
  var existingAccount = []
  var headers = lines[0].split(',')
  var inc = 1
  for (var i = 1; i < lines.length; i++) {
    var obj = {}
    var currentline = lines[i].split(',')
    if (!existingAccount.includes(currentline[1])) {
      // Null value check
      if (
        currentline[0] &&
        currentline[1] &&
        currentline[2] &&
        currentline[3] &&
        currentline[4]
      ) {
        existingAccount.push(currentline[1])
        obj.refID = inc
        obj.name = formatValues(currentline[0])
        obj.account_number = formatValues(currentline[1])
        obj.custodian = formatValues(currentline[2])
        obj.holdings = [
          {
            ticker: formatValues(currentline[3]),
            shares: parseInt(formatValues(currentline[4])),
            price: parseFloat(formatValues(currentline[5]))
          }
        ]
        inc++
      }
    } else {
      // Null value check
      if (
        currentline[0] &&
        currentline[1] &&
        currentline[2] &&
        currentline[3] &&
        currentline[4]
      ) {
        for (var j = 0; j < result.length; j++) {
          if (result[j].account_number == currentline[1]) {
            result[j].holdings.push({
              ticker: formatValues(currentline[3]),
              shares: parseInt(formatValues(currentline[4])),
              price: parseFloat(formatValues(currentline[5]))
            })
          }
        }
      }
    }

    if (Object.entries(obj).length) result.push(obj)
  }

  return result //JSON
}

const formatValues = val => {
  return val.replace(/(\r\n|\n|\r)/gm, '')
}

export const replaceTicker = obj => {
  if (obj.view_name && obj.view_name.includes('ticker')) {
    if (Object.prototype.toString.call(obj.data) === '[object Array]') {
      obj.view_name = obj.view_name.replace('ticker', obj.data[0])
    } else if (typeof obj.data === 'string') {
      obj.view_name = obj.view_name.replace('ticker', obj.data)
    }
  }
  return obj
}

export const deflatLastNode = (obj,isAllListRequired=false) => {
  let allItems = []
  for (const [key, value] of Object.entries(obj)) {
    let uncatagorized = [];
    for (const [key1, value1] of Object.entries(obj[key])) {
      if (Array.isArray(obj[key][key1])) {
        if(isAllListRequired){
          for (const item of obj[key][key1]) {
            allItems.push(item)
          }
        }
        if (obj[key][key1].length === 1) {
          uncatagorized.push(obj[key][key1][0])
          delete obj[key][key1]
        }
      }
    }
    if (uncatagorized.length) {
      obj[key]['uncatagorized'] = uncatagorized
    }
  }
  return { obj,allItems }
}

export const updateTickerData = tickerData => {
  let allTickers = tickerData.slice(0)
  let sumOfPriceAndShares = 0,
    sumOfShares = 0
  const invalidPriceFormat = new RegExp(
    /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/
  )
  let uniqueTickers = []
  let result = []

  allTickers.map(obj => {
    if (!uniqueTickers.includes(obj.ticker)) {
      result.push(obj)
      uniqueTickers.push(obj.ticker)
    } else {
      let copyObj = Object.assign({}, obj)
      result = result.map(data => {
        let item = Object.assign({}, data)
        if (item.ticker == obj.ticker) {
          if (item.shares && obj.shares) {
            item.shares =
              convertToFloatvalues(item.shares) +
              convertToFloatvalues(obj.shares)
            // copyObj.shares = item.shares;
          }
          if (item.amount && obj.amount) {
            item.amount =
              convertToFloatvalues(item.amount) +
              convertToFloatvalues(obj.amount)
            // copyObj.amount = item.amount;
          }
          if (item.weight && obj.weight) {
            item.weight =
              convertToFloatvalues(item.weight) +
              convertToFloatvalues(obj.weight)
            // copyObj.weight = item.weight;
          }
          // if(item.realTimePrice){
          //   copyObj.realTimePrice = item.realTimePrice;
          // }
          return item
        } else {
          return item
        }
      })
      // result.push(copyObj);
    }
  })

  result.map((y, k) => {
    if (y.ticker && convertToLowerCase(y.ticker) == 'ticker') {
      y.ticker = convertToLowerCase(y.ticker)
    }
    if (
      y.ticker &&
      y.ticker !== 'ticker' &&
      y.ticker !== '' &&
      typeof y.ticker !== 'undefined'
    ) {
      y.ticker = convertToUpperCase(y.ticker)
      y.shares = wholeNumberCheck(y.shares)
      if (typeof y.price !== 'undefined') {
        if (invalidPriceFormat.test(y.price)) {
          y.price = y.price.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
        }
        y.price = Number(y.price)
      }

      // Add real-time price for each ticker
      if (
        typeof y.realTimePrice !== 'undefined' &&
        y.realTimePrice &&
        y.realTimePrice != 'NA'
      ) {
        y.price = y.price ? y.price : y.realTimePrice
        // Scenario where user uploads only ticker and weight
        if (
          y.weight &&
          typeof y.weight != 'undefined' &&
          (!y.shares || y.shares == 'undefined') &&
          !y.amount && typeof y.amount == 'undefined'
        ) {
          y.weight = convertToFloatvalues(y.weight)
          const portfolioValue = 750000
          y.shares = wholeNumberCheck(
            ((y.weight / 100) * portfolioValue) / y.realTimePrice
          )
        }
        // Scenario where user uploads ticker and amount
        if(y.amount && typeof y.amount != "undefined" && (!y.shares || y.shares == "undefined")){
          y.amount = convertToFloatvalues(y.amount);
          y.shares = wholeNumberCheck(parseFloat(y.amount / y.realTimePrice));
        }
        y.values =
          y.shares && typeof y.shares !== 'undefined'
            ? y.realTimePrice * y.shares
            : 'NA'
        sumOfPriceAndShares += y.realTimePrice * y.shares
      }
    }
  })

  // Weights computation
  result.map((y, k) => {
    if (typeof y.realTimePrice !== 'undefined' && y.realTimePrice && y.realTimePrice != 'NA') {
      y['weight(%)'] = ((y.realTimePrice*y.shares)/sumOfPriceAndShares)*100
    }else {
      if (typeof y.price !== 'undefined' && y.price && y.price != 'NA') {
        y['weight(%)'] = ((y.price * y.shares) / sumOfPriceAndShares) * 100
      }
    }
  })

  return result
}

export const buildComputedSearchFundsData = (funds, feeRiskReturn) => {
    const updatedFunds = funds.map(item => {
      let newItem = Object.assign({}, item);
      if ('assetTypeData' in newItem) {
        newItem.asset_alloc = newItem.assetTypeData;
        delete newItem.assetTypeData;
      }
      if(!newItem.Fee_Risk_Return){
        const curFeeRiskReturn = feeRiskReturn ? feeRiskReturn.find(obj => (item.ticker in obj)) : {};
        newItem.Fee_Risk_Return = curFeeRiskReturn ? curFeeRiskReturn[item.ticker].Fee_Risk_Return : {};
      }
      return newItem;
    })
    return updatedFunds;
}

export const buildAllFundsData = (chartReportData, itemView) => {
  let allFunds = chartReportData.allFunds.funds
  // let allFunds = _.uniqBy([...chartReportData.allFunds.funds, ...chartReportData.selectedFunds],'ticker');
  let additionalFundData = {
    Assets: {},
    'Dividend Yield': {},
    Regions: {},
    Sectors: {},
    Volume: {},
    Themes: {},
    'Top Holdings': { funds: [] },
    'Asset Allocation': []
  }
  const view = itemView.view
  allFunds.map(item => {
    additionalFundData['Assets'][item.ticker] = getAssetData(item)
    additionalFundData['Dividend Yield'][item.ticker] = getDividentYieldData(
      item
    )
    additionalFundData['Regions'][item.ticker] = item.region || []
    additionalFundData['Sectors'][item.ticker] = getSectorData(item)
    additionalFundData['Volume'][item.ticker] = getVolumeData(item)
    additionalFundData['Themes'][item.ticker] = getThemesData(item)
    additionalFundData['Top Holdings']['funds'].push(getHoldingsData(item))
    additionalFundData['Asset Allocation'].push(item.asset_alloc)
    if (view === 'Diversification') {
      additionalFundData['Diversification'] =
        additionalFundData['Diversification'] || []
      additionalFundData['Diversification'].push(item.diverseData)
    }
    if (view === 'Return Quality') {
      additionalFundData['Return Quality'] = additionalFundData[
        'Return Quality'
      ]
        ? { ...additionalFundData['Return Quality'] }
        : {}
      additionalFundData['Return Quality'][item.ticker] = getReturnQualityChart(
        item
      )
    }
    if (!isViewPresentInChartTypes(view) && isDynamicChart(view, item)) {
      additionalFundData[view] = additionalFundData[view]
        ? { ...additionalFundData[view] }
        : {}
      additionalFundData[view][item.ticker] = getDynamicChartData(item, view)
    }
    item.query_var = chartReportData.allFunds.query_var
  })

  // Remove unnecessary keys to reduce payload
  removeKeys.forEach(item => {
    if (item in chartReportData.allFunds) {
      delete chartReportData.allFunds[item]
    }
  })

  chartReportData.allFunds = {
    ...chartReportData.allFunds,
    ...additionalFundData
    // funds: allFunds,
  }
  chartReportData.initialAllFunds = JSON.parse(
    JSON.stringify(chartReportData.allFunds)
  )
  return chartReportData
}

const removeKeys = [
  'data',
  'http_status',
  'http_statusText',
  'date',
  'page_number',
  'q',
  'related_queries',
  'sponsors_tickers',
  'total_funds',
]

export const updateAllFundsData = (
  chartReportData,
  itemView,
  isSelectedFundExist,
  selectedFund,
  query_var
) => {
  const view = itemView.view
  if (!isSelectedFundExist) {
    chartReportData.allFunds['Assets'][selectedFund.ticker] = getAssetData(
      selectedFund
    )
    chartReportData.allFunds['Dividend Yield'][
      selectedFund.ticker
    ] = getDividentYieldData(selectedFund)
    chartReportData.allFunds['Regions'][selectedFund.ticker] =
      selectedFund.region || []
    chartReportData.allFunds['Sectors'][selectedFund.ticker] = getSectorData(
      selectedFund
    )
    chartReportData.allFunds['Volume'][selectedFund.ticker] = getVolumeData(
      selectedFund
    )
    chartReportData.allFunds['Themes'][selectedFund.ticker] = getThemesData(
      selectedFund
    )
    chartReportData.allFunds['Top Holdings']['funds'].push(
      getHoldingsData(selectedFund)
    )
    chartReportData.allFunds['Asset Allocation'].push(selectedFund.asset_alloc)
    if (view === 'Diversification') {
      chartReportData.allFunds['Diversification'].push(selectedFund.diverseData)
    }
    if (view === 'Return Quality') {
      chartReportData.allFunds['Return Quality'][
        selectedFund.ticker
      ] = getReturnQualityChart(selectedFund)
    }
    if (
      !isViewPresentInChartTypes(view) &&
      isDynamicChart(view, selectedFund)
    ) {
      chartReportData.allFunds[view][selectedFund.ticker] = getDynamicChartData(
        selectedFund,
        view
      )
    }
    selectedFund.query_var = query_var
    chartReportData.allFunds.funds.push(selectedFund)
  } else {
    delete chartReportData.allFunds['Assets'][selectedFund.ticker]
    delete chartReportData.allFunds['Dividend Yield'][selectedFund.ticker]
    delete chartReportData.allFunds['Regions'][selectedFund.ticker]
    delete chartReportData.allFunds['Sectors'][selectedFund.ticker]
    delete chartReportData.allFunds['Volume'][selectedFund.ticker]
    delete chartReportData.allFunds['Themes'][selectedFund.ticker]
    chartReportData.allFunds['Top Holdings'][
      'funds'
    ] = chartReportData.allFunds['Top Holdings']['funds'].filter(
      item => item.ticker !== selectedFund.ticker
    )
    chartReportData.allFunds['Asset Allocation'] = chartReportData.allFunds[
      'Asset Allocation'
    ].filter(item => item.ticker !== selectedFund.ticker)
    if (view === 'Diversification') {
      chartReportData.allFunds['Diversification'] = chartReportData.allFunds[
        'Diversification'
      ].filter(item => item.ticker !== selectedFund.ticker)
    }
    if (view === 'Return Quality') {
      delete chartReportData.allFunds['Return Quality'][selectedFund.ticker]
    }
    if (
      !isViewPresentInChartTypes(view) &&
      isDynamicChart(view, selectedFund)
    ) {
      delete chartReportData.allFunds[view][selectedFund.ticker]
    }
  }
  return chartReportData
}

const getAssetData = item => {
  if (!item._cstats) return
  const qi = QuinItems[2]
  const mult = qi.name === 'Assets' ? 1000 * 1000 : 1
  const _min = item._cstats[qi.col + '_min'],
    _max = item._cstats[qi.col + '_max'],
    _val = qi.name === 'Assets' ? item[qi.col] : item[qi.col] * mult,
    _pc = item[qi.pcCol]
  return {
    max: _max,
    min: _min,
    pctile: _pc,
    q: 5,
    value: _val
  }
}

const getDividentYieldData = item => {
  const name = 'Dividend Yield'
  const mult = name === 'Dividend Yield' ? 100 : 1
  if (
    isNull(item.dividend_yield) ||
    isUndefined(item.dividend_yield) ||
    item.dividend_yield == 'null' ||
    typeof item.dividend_yield == 'undefined'
  )
    return ''
  if (isNull(item.dividendMin) || isUndefined(item.dividendMin)) return ''
  if (
    isNull(item.dividendMax) ||
    isUndefined(item.dividendMax) ||
    item.dividendMax <= 0.0
  )
    return ''
  if (item.dividendMax == item.dividendMin) return ''

  let _min = item.dividendMin,
    _max = item.dividendMax,
    _val = item.dividend_yield

  return {
    max: _max,
    min: _min,
    value: _val
  }
}

const getSectorData = item => {
  const name = 'Sectors'
  var mult = name === 'Theme' ? 100 : 1
  const attrs = SectorsAttrs
  let data = {}
  attrs.forEach(
    v =>
      (data[v == 'Other Sectors' ? 'Others Non Classified' : v] = cn(item, v)
        ? parseFloat(cn(item, v) * mult)
        : 0)
  )
  return data
}

const getVolumeData = item => {
  if (!item._cstats) return
  const qi = QuinItems[1]
  const mult = qi.name === 'Assets' ? 1000 * 1000 : 1
  const _min = item._cstats[qi.col + '_min'],
    _max = item._cstats[qi.col + '_max'],
    _val = qi.name === 'Assets' ? item[qi.col] : item[qi.col] * mult,
    _pc = item[qi.pcCol]
  return {
    max: _max,
    min: _min,
    pctile: _pc,
    q: 5,
    value: _val
  }
}

const getThemesData = item => {
  const name = 'Theme Score'
  // const mult = name === 'Theme Score' ? 100 : 1; //Check this multiplier might vary, just using for now to match it with computed search
  const mult = 1
  const data = item.themeScore
    .filter(e => e.value > 1e-5)
    .map((e, i) => {
      return {
        name: e.name,
        value: +e.value * mult
      }
    })
  return data
}

const getHoldingsData = item => {
  let newItem = {}
  newItem.ticker = item.ticker
  newItem.holdings = item.holdingData.map(holding => {
    return {
      name: holding.name,
      weight: holding.value
    }
  })
  return newItem
}

const isDynamicChart = (view, item) => {
  let chartData = item.chartData.length
    ? item.chartData.filter(data => {
        return data.display_view_name === view
      })
    : []
  if (chartData.length) {
    if (!chartData[0].Static && chartData[0].chart_type === 'odometer') {
      return true
    }
  }
  return false
}

const getReturnQualityChart = item => {
  if (!item.riskAdjReturn) return ''
  const dataViews = ['sharpe', 'sortino', 'trackingError']
  const returnQualityData = dataViews.map(singleView => {
    const currentView =
      singleView == 'sharpe'
        ? 'Sharpe'
        : singleView == 'sortino'
        ? 'Sortino'
        : singleView == 'trackingError'
        ? 'Tracking Error'
        : ''
    const riskAdjReturn = item.riskAdjReturn.filter(e => e.name === currentView)
    if (!riskAdjReturn.length) return '';
    let data = {}
    data.name = currentView
    data.max = item[singleView + 'Max']
    data.min = item[singleView + 'Min']
    data.value = riskAdjReturn[0].value
    return data.max !== data.min ? data : ''
  })
  return returnQualityData
}

const getDynamicChartData = (item, view) => {
  if (typeof item.chartData === 'undefined') return ''
  const currentChartData = item.chartData.filter(
    e => e.display_view_name == view
  )[0]
  if (typeof currentChartData === 'undefined') return ''
  const mult =
    typeof currentChartData !== 'undefined'
      ? currentChartData.multiplier != ''
        ? currentChartData.multiplier
        : 1
      : 1
  if (isNaN(currentChartData.min)) return ''

  if (isNaN(currentChartData.max)) return ''

  if (isNaN(item[currentChartData.variable])) return ''

  //not present
  if (
    item[currentChartData.variable] == null ||
    typeof item[currentChartData.variable] == undefined
  )
    return ''

  //les then min
  if (item[currentChartData.variable] < currentChartData.min) return ''

  //les then max
  if (item[currentChartData.variable] > currentChartData.max) return ''

  //if same
  if (currentChartData.min == currentChartData.max) return ''

  let _min = currentChartData.min,
    _max = currentChartData.max,
    _val = item[currentChartData.variable],
    decimal = currentChartData.decimal,
    suffix = currentChartData.suffix

  return {
    max: _max,
    min: _min,
    decimal,
    multiplier: mult,
    suffix,
    value: _val
  }
}

export const deepMergeObjects = (source, destination) => {
  let out = JSON.parse(JSON.stringify(source))
  Object.keys(destination).map(key => {
    if (key in out) {
      if (isObject(out[key])) {
        if (key === 'Top Holdings') {
          out[key].funds = _.uniqBy(
            [...out[key].funds, ...destination[key].funds],
            'ticker'
          )
        } else {
          out[key] = { ...out[key], ...destination[key] }
        }
      } else if (Array.isArray(out[key])) {
        if (Array.isArray(destination[key])) {
          out[key] = [...out[key], ...destination[key]]
          if (key === 'funds' || key === 'Asset Allocation') {
            out[key] = _.uniqBy(out[key], 'ticker')
          }
        } else {
          out[key] = [...out[key], destination[key]]
        }
      } else {
        out[key] = [out[key], destination[key]]
      }
    } else {
      out[key] = destination[key]
    }
  })
  return out
}

export const isObject = val => {
  if (val) {
    if (Object.prototype.toString.call(val) === '[object Object]') {
      return true
    } else {
      return false
    }
  }
  return false
}

export const getPortfolioBenchmarkOfSelectedTicker = (data, ticker) => {
  if (data) {
    let returns = null
    for (let item of data) {
      if (item.ticker === ticker) {
        returns = item.returns
        break
      }
    }
    return returns
  }
  return null
}

export const getDefaultScreenerValue = (attr) => {
  let out;
  if(attr.weight_name == 'expense_ratio'){
    out = {
      criteria: [{
        id: 1,
        value: 50,
        suffix: '% Peers',
        condition: '<=',
        status: false,
        type: 'select',
        options: [0, 25, 50, 75, 100],
        optMapping: {
          0: 'min_expense_ratio',
          25: 'expense_ratio_25pct',
          50: 'median_expense_ratio',
          75: 'expense_ratio_75pct',
          100: 'max_expense_ratio',
        },
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'alpha_5y'){
    out = {
      criteria: [{
        id: 1,
        value: 0,
        suffix: '%',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'alpha_10y'){
    out = {
      criteria: [{
        id: 1,
        value: 0,
        suffix: '%',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'manager_tenure'){
    out = {
      criteria: [{
        id: 1,
        value: 2,
        suffix: 'Yr',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
          onlyPositive: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'outperformance_3y'){
    out = {
      criteria: [{
        id: 1,
        value: 0,
        suffix: '%',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'downside_capture_5y'){
    out = {
      criteria: [{
        id: 1,
        value: 100,
        suffix: '%',
        condition: '<=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'upside_capture_5y'){
    out = {
      criteria: [{
        id: 1,
        value: 100,
        suffix: '%',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'upside_capture_10y'){
    out = {
      criteria: [{
        id: 1,
        value: 100,
        suffix: '%',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'aumAC'){
    out = {
      criteria: [{
        id: 1,
        value: 100,
        suffix: 'Mn',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
          onlyPositive: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'downside_capture_10y'){
    out = {
      criteria: [{
        id: 1,
        value: 100,
        suffix: '%',
        condition: '<=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'outperformance_5y'){
    out = {
      criteria: [{
        id: 1,
        value: 0,
        suffix: '%',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  if(attr.weight_name == 'outperformance_10y'){
    out = {
      criteria: [{
        id: 1,
        value: 0,
        suffix: '%',
        condition: '>=',
        status: false,
        type: 'input',
        validations: {
          isRequired: true,
        },
      }]
    }
  }
  return out;
}


export const immutableObject = (val) => {
  if(typeof val === 'object' && val !== null){
    return JSON.parse(JSON.stringify(val));
  }else{
    return val;
  }
}
