import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { isEmpty, uniqBy, reverse, orderBy } from 'lodash';
import { notification } from 'antd';

import {
  arrayToHash,
  queryStr,
  uniqValues,
  getNest,
  getNestDefault,
  convertToCapitalCase,
  findAndUpdateArray,
  monthToDate,
  convertToFloatvalues,
  quintileSize,
  quintileText,
  nameToDateRange,
} from 'Utils';

import {
  FilterAttrData,
  SortAttrs,
  DefaultSortAttr,
  DateRange,
  ViewNames,
  Api,
  ApiHeaders,
  cn,
  CN,
  MaxResult,
  Universe,
  BrokersComms,
  DefaultYears,
  RelatedInsightsToggle,
  mappingRequiredKeys,
  skipRequiredKeys,
  SectorsAttrs,
} from 'DataSet';

import { calcStats } from 'Stats';
import { RegionColors, SectorColors } from 'data/Colors';
import { getInsightHashKey } from 'common/theme';

import config from '../env';
//import { getFilteredListByRange } from './WebDashboard/SFM/utils';

// find, compare and update response data with reducer SFM Items
export const customScroll = () => {
  const elems = document.querySelectorAll('.custom-scroll');
  if (elems.length) {
    [].forEach.call(elems, function (el) {
      if (el.scrollHeight > el.clientHeight) {
        el.style.paddingRight = '.25rem';
        el.style.paddingLeft = '.40rem';
      } else el.style.paddingRight = '0rem';
    });
  }
}


export const getCookie = (cname) => {
  let name = cname + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default {
  customScroll,

  getCookie,

};

export const openNotificationWithIcon = ({ type, message, description, ...props }) => {
  notification[type]({
    ...props,
    message,
    description,
  });
};
