import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Spinner as RSSpinner } from 'reactstrap'
import { Link, NavLink, Redirect, Switch } from 'react-router-dom'
import { fetchTrades } from 'actions/trades'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'
import { Modal, notification, Pagination } from 'antd'
import moment from 'moment';
import { getListAlerts } from 'actions/alerts'
import InfiniteScroll from 'react-infinite-scroll-component';
import { get } from 'lodash';

class AlertsListing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      trades: [],
      isFetching: false,
      fetchError: false,
      activePage: 1
    }
  }

  componentDidMount = () => {
    this.props.getListAlerts({ page: this.state.activePage });
  }

  getBack = () => {
    this.props.history.push('/trades');
  }

  getValue = (value) => {
    if (value) {
      return value;
    }
    return "-";
  }

  loadFunc = (page, pageSize) => {
    const { alerts, currentPage } = this.props;
    if (currentPage < alerts.total_pages) {
      this.props.getListAlerts({ page: page });
    }
  }

  paginate = (page, pageSize) => {
    this.setState({ activePage: page })
    this.props.getListAlerts({ page: page })
  }

  render() {
    const { alerts, isFetching, currentPage, error } = this.props;
    const { getValue } = this;
    const alertsResultHaveData = get(alerts, 'result') && get(alerts, 'result').length;
    let hasMoreDataCondition = false;
    if (alertsResultHaveData) {
      hasMoreDataCondition = currentPage < alerts.total_pages;
    }

    return (
      <div className="container-wrapper homeWrapper">

        <Spinner isFetching={isFetching} />
        <h2>Error Accounts</h2>
        {/* <a onClick={() => this.getBack()} className="back-button"><i className="fa fa-arrow-left" /> Back</a> */}

        <div className="content-box">
          <div className="section-detail">
            <div className="row relationship-row">
              <div className="col-1 text-left mb-2 mb-sm-0">
                <label>#</label>
              </div>
              <div className="col-3 text-left mb-2 mb-sm-0">
                <label>Name</label>
              </div>
              <div className="col-3 text-left mb-2 mb-sm-0">
                <label>Email</label>
              </div>
              <div className="col-2 text-left mb-2 mb-sm-0">
                <label>Account Number</label>
              </div>
              <div className="col-3 text-left mb-2 mb-sm-0">
                <label>Created At</label>
              </div>
            </div>
            <div
              id="scrollableDiv"
            // style={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}
            >
              {/* <InfiniteScroll
                dataLength={alertsResultHaveData ? get(alerts, 'result').length : 0}
                next={this.loadFunc}
                hasMore={hasMoreDataCondition}
                loader={<div style={{ display: "flex", justifyContent: "center" }}><RSSpinner /></div>}
                scrollableTarget="scrollableDiv"
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>
                      {alertsResultHaveData ? "Yay! You have seen it all" : "No data"}</b>
                  </p>
                }
              > */}
              {error ? <div className="w-100 text-center p-2">
                <span>{error}</span>
              </div> : null}
              {alertsResultHaveData ? alerts.result.map((obj, idx) => {
                let className = alerts.length === 1 ? 'row relationship-row border-0' : 'row relationship-row border-1';

                return (
                  <div key={idx} className={className}>
                    <div className="col-1 text-left mb-2 mb-sm-0">
                      <p>{getValue(obj.user_id)}</p>
                    </div>
                    <div className="col-3 text-left mb-2 mb-sm-0">
                      <p>{`${getValue(obj.first_name)} ${getValue(obj.last_name)}`}</p>
                    </div>
                    <div className="col-3 text-left mb-2 mb-sm-0">
                      <p>{getValue(obj.account_no)}</p>
                    </div>
                    <div className="col-2 text-left mb-2 mb-sm-0">
                      <p>{getValue(obj.account_no)}</p>
                    </div>
                    <div className="col-3 text-left mb-2 mb-sm-0">
                      <p>{moment(obj.created_at).format('MM/DD/YYYY')}</p>
                    </div>
                  </div>
                )
              })
                : null}
              {/* </InfiniteScroll> */}
            </div>
          </div>
          <div style={{ padding: '20px 10px' }}>
            <Pagination
              showSizeChanger={false}
              defaultPageSize={20}
              // onShowSizeChange={onShowSizeChange}
              current={this.state.activePage}
              total={alerts && alerts.total_pages ? (alerts.total_pages * 20) : 0}
              onChange={this.paginate}
            />
          </div>
        </div>

      </div>

    )
  }
}

function mapStateToProps(state) {
  let returnState = {};

  returnState.isFetching = state.alerts.alertsData.isLoading;
  returnState.loggedIn = state.auth.loggedIn;
  returnState.alerts = state.alerts.alertsData.data;
  returnState.currentPage = state.alerts.alertsData.page;
  returnState.error = state.alerts.alertsData.error;

  return returnState;
}


const mapDispatchToProps = {
  getListAlerts
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AlertsListing))
