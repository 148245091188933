import React, { Component } from 'react'
import moment from 'moment-timezone'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { isEmpty, debounce } from 'lodash'
import { Pagination, Badge, Switch, message, Typography } from 'antd'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Alert
} from 'reactstrap'

import {
  tradingAccountListRequest,
  tradingAccountDetailRequest,
  userRestrictRequest
} from 'actions/account'
import { createLoadingSelector } from 'selectors/loading-selector'

import { Spinner } from 'shared-components/'
import { AUTO_APPROVAL_ALLOW_USERS } from '../../../../DataSet'
import {
  getApexAccountStatus,
  updateUserAccountType,
  updateRestrictUserStatus,
  resubmitRequestToApex,
  exportAccountList,
  uploadReviewImage,
  saveForReview,
  updateSnapStatus,
  validateAddress,
  makeAppeal
} from 'apis/account'

import ErrorAlert from '../../Elements/ErrorAlert'

const { Paragraph, Text } = Typography

const FileSaver = require('file-saver')
const SUSPENDED_PENDING_DOCS = 'SUSPENDED_PENDING_DOCS'
const TRANSFER_REQUEST = 'TRANSFER_REQUEST'
const SUSPENDED = 'SUSPENDED'
const ACTION_REQUIRED = 'ACTION_REQUIRED'

const autoLoadImageStyle = {
  position: 'fixed',
  top: 0,
  opacity: 0,
  display: 'none',
  visibility: 'hidden'
}

class AccountsUsersListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accounts: [],
      // isFetching: false,
      fetchError: false,
      dropdownOpen: false,
      appliedFilter:
        this.props.history.location.state &&
        this.props.history.location.state.appliedFilter
          ? this.props.history.location.state.appliedFilter
          : '',
      search: '',
      activePage:
        this.props.history.location.state &&
        this.props.history.location.state.active
          ? this.props.history.location.state.active
          : 1,
      accountsCount: this.props.accountsCount || 0,
      allStatus: [],
      errorObj: {},
      errorAlertModal: false,
      apexStatus: null,
      errorStateLoad: false,
      downloading: false,
      pending_documents: '',
      transfer_request: '',
      autoLoadImagePath: '/assets/images/address-verification.png',
      invalidAutoUploadImagePath: null
    }
    this.debounceFetchAccounts = debounce(
      this.props.tradingAccountListRequest,
      500
    )
  }

  componentDidMount() {
    const { tradingAccountListRequest } = this.props
    const { appliedFilter, activePage } = this.state
    if (tradingAccountListRequest) {
      // this.setState({ isFetching: true })
      try {
        if (appliedFilter === SUSPENDED_PENDING_DOCS) {
          tradingAccountListRequest({
            page: activePage,
            status: SUSPENDED,
            searchKey: this.state.search,
            pending_documents: true,
            transfer_request: this.state.transfer_request
          })
        } else if (appliedFilter === TRANSFER_REQUEST) {
          tradingAccountListRequest({
            page: activePage,
            status: '',
            searchKey: this.state.search,
            pending_documents: this.state.pending_documents,
            transfer_request: true
          })
        } else {
          tradingAccountListRequest({
            page: activePage,
            status: appliedFilter,
            searchKey: this.state.search,
            pending_documents: this.state.pending_documents,
            transfer_request: this.state.transfer_request
          })
        }
      } catch (e) {
        console.log('<---- UNABLE TO GET TRADE ACCOUNTS DATA----->')
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accounts && this.props.accounts !== undefined) {
      // if ((this.props.accounts && this.props.accounts.data && this.props.accounts.data.apex_accounts) && this.props.accounts !== prevProps.accounts) {
      if (this.props.accounts !== prevProps.accounts) {
        try {
          let userID =
            this.props.user && this.props.user.profile
              ? this.props.user.profile.user
              : 0
          if (
            process.env.REACT_APP_ENV === 'prod' &&
            userID > 0 &&
            AUTO_APPROVAL_ALLOW_USERS.includes(userID)
          ) {
            // let suspendedApexAccDetails = this.props.accounts.data.apex_accounts.filter(c => c.is_active == false && (c.status === 'SUSPENDED' || c.status === 'ACTION_REQUIRED'))
            let suspendedApexAccDetails = this.props.accounts.filter(
              c =>
                c.is_active == false &&
                (c.status === 'SUSPENDED' || c.status === 'ACTION_REQUIRED')
            )
            this.getApexAccountDetails(suspendedApexAccDetails)
          }
        } catch (error) {
          console.log(error)
        }
        this.setState({
          // isFetching: false,
          accounts: this.props.accounts,
          allStatus: this.props.allStatus,
          accountsCount: this.props.accountsCount || 0
          // accounts: this.props.accounts.data.apex_accounts,
          // accountsCount: this.props.accounts.data.total_pages || 0,
        })
      }
      if (
        this.props.error !== prevProps.error &&
        (this.props.accounts.status !== 200 || this.props.error !== undefined)
      ) {
        this.setState({
          fetchError: true,
          // isFetching: false,
          error: this.props.error
        })
      }
    }
  }

  paginate = (page, pageSize) => {
    const { tradingAccountListRequest } = this.props
    const { appliedFilter } = this.state
    if (tradingAccountListRequest) {
      this.setState({
        activePage: page
        // isFetching: true
      })
      try {
        if (appliedFilter === SUSPENDED_PENDING_DOCS) {
          tradingAccountListRequest({
            page: page,
            status: SUSPENDED,
            searchKey: this.state.search,
            pending_documents: true,
            transfer_request: this.state.transfer_request
          })
        } else if (appliedFilter === TRANSFER_REQUEST) {
          tradingAccountListRequest({
            page: page,
            status: '',
            searchKey: this.state.search,
            pending_documents: this.state.pending_documents,
            transfer_request: true
          })
        } else {
          tradingAccountListRequest({
            page: page,
            status: appliedFilter,
            searchKey: this.state.search,
            pending_documents: this.state.pending_documents,
            transfer_request: this.state.transfer_request
          })
        }
      } catch (e) {
        console.log('<---- UNABLE TO GET TRADE ACCOUNTS DATA----->')
      }
    }
  }

  checkForAccountError = async data => {
    let showSuspendedAccount = false
    this.setState({ errorStateLoad: true })
    let apexStatus = await getApexAccountStatus(data.id)
    apexStatus = apexStatus.result
    !isEmpty(apexStatus.investigations) &&
      (apexStatus.status === 'SUSPENDED' ||
        apexStatus.status === 'ACTION_REQUIRED') &&
      apexStatus.investigations.forEach(investigation => {
        if (!showSuspendedAccount) {
          !isEmpty(investigation.sketchId) &&
            !investigation.sketchId.error &&
            investigation.sketchId.cipCategories.length &&
            investigation.sketchId.cipCategories.forEach(cat => {
              if (
                cat.categoryState === 'REJECTED' &&
                cat.requestedDocuments.length > 0
              ) {
                showSuspendedAccount = true
              }
            })
        }
      })
    let errorObj = this.state.errorObj
    if (showSuspendedAccount) {
      this.setState({ apexStatus: apexStatus })
    } else {
      this.setState({ apexStatus: null })
    }
    this.setState({ errorObj, errorAlertModal: true, errorStateLoad: false })
  }

  getDetails = obj => {
    const { history } = this.props
    if (history) {
      history.push({
        pathname: `/account/${obj.id}`,
        state: {
          active: this.state.activePage,
          appliedFilter: this.state.appliedFilter
        }
      })
    }
  }
  getApexAccountDetails = async suspendedApexAccDetails => {
    try {
      suspendedApexAccDetails &&
        suspendedApexAccDetails.length > 0 &&
        suspendedApexAccDetails.map(async (data, idx) => {
          let showSuspendedAccount = false
          // let isInValidAddress = false
          //this.setState({ errorStateLoad: true })
          let apexStatus = await getApexAccountStatus(data.id)
          apexStatus = apexStatus.result
          !isEmpty(apexStatus.investigations) &&
            (apexStatus.status === 'SUSPENDED' ||
              apexStatus.status === 'ACTION_REQUIRED') &&
            apexStatus.investigations.forEach(investigation => {
              if (!showSuspendedAccount) {
                !isEmpty(investigation.sketchId) &&
                  !investigation.sketchId.error &&
                  investigation.sketchId.cipCategories.length &&
                  investigation.sketchId.cipCategories.forEach(cat => {
                    if (
                      cat.name === 'ADDRESS' &&
                      cat.categoryState === 'REJECTED'
                    ) {
                      showSuspendedAccount = true
                      // isInValidAddress = true

                      const dowJonesHasError =
                        apexStatus &&
                        apexStatus.investigations &&
                        apexStatus.investigations.length &&
                        apexStatus.investigations[0] &&
                        apexStatus.investigations[0].sketchId &&
                        apexStatus.investigations[0].sketchId.status ===
                          'REJECTED' &&
                        apexStatus.investigations[0].sketchId.result &&
                        apexStatus.investigations[0].sketchId.result
                          .dowJonesResult &&
                        apexStatus.investigations[0].sketchId.result
                          .dowJonesResult.profiles &&
                        apexStatus.investigations[0].sketchId.result
                          .dowJonesResult.profiles.length > 0

                      if (!dowJonesHasError) {
                        if (
                          apexStatus.investigations[0].sketchId.request &&
                          apexStatus.investigations[0].sketchId.request
                            .identity &&
                          apexStatus.investigations[0].sketchId.request.identity
                            .homeAddress
                        ) {
                          const {
                            city,
                            country,
                            postalCode,
                            state,
                            streetAddress
                          } = apexStatus.investigations[0].sketchId.request.identity.homeAddress
                          if (
                            city &&
                            country &&
                            postalCode &&
                            state &&
                            streetAddress
                          ) {
                            let street = streetAddress
                            if (
                              typeof streetAddress === 'array' &&
                              streetAddress.length > 0
                            ) {
                              street = streetAddress[0]
                            }
                            const addressPayload = {
                              street,
                              city: city,
                              PostalCode: postalCode,
                              CountryCode: country
                            }
                            validateAddress(addressPayload).then(resdata => {
                              if (
                                resdata.data.rdi === 'R' &&
                                resdata.data.status === 'VALID'
                              ) {
                                if (
                                  this.canAutoApprove(apexStatus.investigations)
                                ) {
                                  console.log(
                                    `Address auto approve for ${apexStatus.account_no} processing....`
                                  )
                                  const APEX_ACCOUNT_DATA = {
                                    ...data,
                                    apexStatus,
                                    apex_account_id: data.id,
                                    account_no: apexStatus.account_no
                                  }
                                  this.automateAddressApproveProcess(
                                    APEX_ACCOUNT_DATA
                                  )
                                  console.log(
                                    `Processing for ${apexStatus.account_no} completed.`
                                  )
                                }
                              }
                            })
                          }
                        } else {
                          console.log(
                            '--- MISSING "homeAddress" data in -> sketchId.request.identity <- ---'
                          )
                        }
                      } else {
                        console.log(`============== DJ ERROR ==============`)
                        console.log(
                          `Terminating address auto-approval process for account number -> ${apexStatus.account_no} `
                        )
                        console.log(`======================================`)
                      }
                    }
                  })
              }
            })
        })
    } catch (error) {
      console.log(error)
    }
  }

  canAutoApprove = investigation => {
    let status = true
    investigation &&
      investigation.forEach(data => {
        let cip = data.sketchId.cipCategories
        for (let i = 0; i < cip.length; i++) {
          if (
            cip[i].categoryState === 'REJECTED' &&
            cip[i].name !== 'ADDRESS'
          ) {
            status = false
          }
        }
      })
    return status
  }

  onBrokenImage = () => {
    this.setState({
      invalidAutoUploadImagePath: true
    })
  }

  automateAddressApproveProcess = async accountData => {
    console.log('automateAddressApproveProcess ->', accountData)
    if (this.state.invalidAutoUploadImagePath) {
      // NOTE: In-Valid Image, Check image path again.
      // -------------------------------------------------
      console.log('---- Check auto-upload image path ----')
      // -------------------------------------------------
      return null
    } else {
      // NOTE: Valid Image, execute next Upload Image step
      // -------------------------------------------------

      //step 1 upload image
      // const { apex_account_id, accountData } = this.state;
      // const blob = new Blob([ADDRESSVERIFYIMG], { type: "image/png"});

      // NOTE: fetch Image from PATH
      // -------------------------------------------------
      const blob = await fetch(
        '/assets/images/address-verification.png'
      ).then(r => r.blob())
      // NOTE: Convert Blob to File
      const AUTO_UPLOAD_FILE = new File([blob], 'address-verification', {
        lastModified: new Date().getTime(),
        type: blob.type
      })
      // NOTE: Upload File as FormData
      let formData = new FormData()
      formData.append('file', AUTO_UPLOAD_FILE)
      formData.append('tag', 'AFFILIATED_APPROVAL')
      formData.append('account', accountData.id)

      try {
        let upload = await uploadReviewImage(formData)
        // console.log(upload);

        // // NOTE: To test AutoUploadImage process, trigger 'this.onDownloadSnap' to download auto-uploaded image
        // // -----------------un-comment-below--------------------------------
        // if (upload && upload.record_id) {
        //   this.onDownloadSnap('AutoUploadImage', upload.record_id)
        // }
        // // -------------------------------------------------

        //step 2 save address
        let payload = {
          apex_account_id: accountData.id,
          data: [
            {
              sketchId: accountData.sketch_ids,
              snapIDs: [
                {
                  id: upload.record_id,
                  type: 'ADDRESS',
                  proof: 'ALL_PASSING_CIP_RESULTS',
                  status: 'uploaded'
                }
              ]
            }
          ]
        }
        let saveReview = await saveForReview(payload)
        if (saveReview && saveReview.data && saveReview.data.message) {
          // message.success(saveReview.data.message);
          console.log(
            `=== ${saveReview.data.message} for "${accountData.name}" - "${accountData.id}" ===`
          )
        }

        //step 3 update address with status approved
        let updatePayload = {
          snapId: upload.record_id,
          comment:
            'Verified residential address through 3rd party address validator',
          status: 'approved'
        }
        let update = await updateSnapStatus(updatePayload)

        // this.setState({ isFetching: true });

        //step 4 make apeal
        let appealPayload = {
          apex_account_id: accountData.id,
          sketchId: accountData.sketch_ids,
          action: 'APPEALED',
          text: 'auto appealing identity verification',
          cip: {
            snapIDs: [upload.record_id]
          }
        }

        let appeal = await makeAppeal(appealPayload).then(null, e => {
          console.log(e)
        })

        if (appeal && appeal.status === 200) {
          // message.success("APPEALED Successfully!");
          console.log(
            `=== APPEALED successfully for "${accountData.name}" - "${accountData.id}" ===`
          )
          this.props.tradingAccountDetailRequest(accountData.id)
        }

        this.setState({
          // isFetching: false,
          appeal: 'APPEALED'
        })
      } catch (e) {
        console.log(e)
        // this.setState({ isFetching: false });
      }
    }
  }

  toggleFilter = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  applyThisFilter = type => {
    const { tradingAccountListRequest } = this.props
    if (tradingAccountListRequest) {
      this.setState(
        {
          appliedFilter: type,
          dropdownOpen: !this.state.dropdownOpen,
          // isFetching: true,
          activePage: 1
        },
        () => {
          try {
            if (type === SUSPENDED_PENDING_DOCS) {
              tradingAccountListRequest({
                page: this.state.activePage,
                status: SUSPENDED,
                searchKey: this.state.search,
                pending_documents: true,
                transfer_request: this.state.transfer_request
              })
            } else if (type === TRANSFER_REQUEST) {
              tradingAccountListRequest({
                page: this.state.activePage,
                status: '',
                searchKey: this.state.search,
                pending_documents: this.state.pending_documents,
                transfer_request: true
              })
            } else if (type === ACTION_REQUIRED) {
              tradingAccountListRequest({
                page: this.state.activePage,
                status: ACTION_REQUIRED,
                searchKey: '',
                pending_documents: true,
                transfer_request: ''
              })
            } else {
              tradingAccountListRequest({
                page: this.state.activePage,
                status: this.state.appliedFilter,
                searchKey: this.state.search,
                pending_documents: this.state.pending_documents,
                transfer_request: this.state.transfer_request
              })
            }
          } catch (e) {
            console.log(
              '<---- UNABLE TO GET FILTERED TRADE ACCOUNTS DATA----->'
            )
          }
        }
      )
    }
  }

  handleInputChange = event => {
    const { appliedFilter } = this.state
    const { tradingAccountListRequest } = this.props
    if (tradingAccountListRequest && event && event.target) {
      this.setState({
        search: event.target.value,
        activePage: 1
      })
      try {
        if (appliedFilter === SUSPENDED_PENDING_DOCS) {
          this.debounceFetchAccounts({
            page: 1,
            status: SUSPENDED,
            searchKey: encodeURIComponent(event.target.value),
            pending_documents: true,
            transfer_request: this.state.transfer_request
          })
        } else if (appliedFilter === TRANSFER_REQUEST) {
          this.debounceFetchAccounts({
            page: 1,
            status: '',
            searchKey: encodeURIComponent(event.target.value),
            pending_documents: this.state.pending_documents,
            transfer_request: true
          })
        } else {
          this.debounceFetchAccounts({
            page: 1,
            status: appliedFilter,
            searchKey: encodeURIComponent(event.target.value),
            pending_documents: this.state.pending_documents,
            transfer_request: this.state.transfer_request
          })
        }
      } catch {
        console.log('<---- UNABLE TO GET SEARCH TRADE ACCOUNTS DATA----->')
      }
    }
  }

  handleTestChange = async (status, data) => {
    try {
      let userUpdate = await updateUserAccountType(status, data.user__id)
      message.success(userUpdate.data.result)
    } catch (e) {
      console.log(e)
    }
  }

  handleRestrictUserChange = async data => {
    try {
      let userUpdate = await updateRestrictUserStatus(data.user__id)
      this.props.tradingAccountListRequest({
        page: this.state.activePage,
        status: this.state.appliedFilter,
        searchKey: this.state.search,
        pending_documents: '',
        transfer_request: ''
      })
      message.success(userUpdate.data.message)
    } catch (e) {
      console.log(e)
    }
  }

  handleRestrictSSNChange = async (status, data) => {
    const { userRestrictRequest } = this.props
    try {
      if (data && data.account_no) {
        let userRestrictPayload = {
          apex_account: data.account_no,
          apex_account_restricted: status
        }
        await userRestrictRequest(userRestrictPayload, this.onSuccess)
      }
    } catch (e) {
      console.log(e)
    }
  }

  onSuccess = responseOBJ => {
    try {
      responseOBJ &&
        responseOBJ.data &&
        message.success(responseOBJ.data.message || responseOBJ.data.error)
      this.props.tradingAccountListRequest({
        page: this.state.activePage,
        status: this.state.appliedFilter,
        searchKey: this.state.search,
        pending_documents: '',
        transfer_request: ''
      })
    } catch (e) {
      console.log(e)
    }
  }

  exportAccountList = async For => {
    try {
      this.setState({ downloading: true })
      let payload = {}
      if (For === 'Funding') {
        payload = {
          funds: true // it should return funded accounts
        }
      }
      let download = await exportAccountList(payload)
      this.setState({ downloading: false })

      const newBlob = new Blob([download.data], {
        type: download.headers['content-type']
      })
      const extension = download.headers['content-type'].split('/')[1]

      FileSaver.saveAs(newBlob, `Account List.${extension}`)
    } catch (e) {
      console.log(e)
    }
  }
  resubmitAccount = async accDetails => {
    console.log('accDetails', { accDetails })
    try {
      const response = await resubmitRequestToApex(accDetails.id)
      if (
        response &&
        response.data &&
        response.data.result &&
        typeof response.data.result === 'string'
      )
        message.success(response.data.result)
      this.props.tradingAccountListRequest({
        page: this.state.activePage,
        status: this.state.appliedFilter,
        searchKey: this.state.search,
        pending_documents: '',
        transfer_request: ''
      })
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.result &&
        typeof error.response.data.result === 'string'
      )
        message.error(error.response.data.result)
      console.log('error', { error })
    }
  }
  render() {
    const defaultPageLimit = 50
    const { accounts, accountsCount, allStatus: allFilters } = this.props
    const { autoLoadImagePath } = this.state
    // let allFilters = this.state.allStatus;
    let additionalFiltersData = [
      SUSPENDED_PENDING_DOCS,
      TRANSFER_REQUEST
    ] /*Additional Filter condition except response of status */
    return (
      <div className="container-wrapper homeWrapper">
        <img
          style={autoLoadImageStyle}
          src={autoLoadImagePath}
          onError={error =>
            this.onBrokenImage({ error, url: autoLoadImagePath })
          }
        />
        <Modal
          // centered
          isOpen={this.state.errorAlertModal}
          toggle={() =>
            this.setState({ errorAlertModal: !this.state.errorAlertModal })
          }
        >
          <ModalHeader
            toggle={() =>
              this.setState({ errorAlertModal: !this.state.errorAlertModal })
            }
          >
            Error
          </ModalHeader>
          <ModalBody>
            {this.state.apexStatus ? (
              <Alert color={'danger'}>
                <ErrorAlert
                  investigations={this.state.apexStatus.investigations}
                />
              </Alert>
            ) : (
              'No documents required'
            )}
          </ModalBody>
        </Modal>
        <Spinner isFetching={this.props.loading || this.state.errorStateLoad} />
        <div className="row">
          <div className="col-sm-3">&nbsp;</div>
          <div className="col-sm-6">
            <div className="listing-search mb-1 mb-sm-0">
              <input
                type="text"
                placeholder="Search By Name, Email ID, Account No."
                name="search"
                value={this.state.search}
                onChange={this.handleInputChange}
              />
              <a
                onClick={() =>
                  this.handleInputChange({
                    target: { value: '', name: 'search' }
                  })
                }
                className="cancel-icon"
              >
                <i className="fa fa-times" />
              </a>
              <a className="search-icon">
                <i className="fa fa-search" />
              </a>
            </div>
          </div>
          <div className="col-sm-3 text-left text-sm-right filter-holder">
            <a
              className="back-button export"
              onClick={() =>
                !this.state.downloading && this.exportAccountList('Funding')
              }
            >
              Funding
            </a>
            <a
              className="back-button export"
              onClick={() =>
                !this.state.downloading && this.exportAccountList()
              }
            >
              Export
            </a>
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={() => this.toggleFilter()}
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={this.state.dropdownOpen}
              >
                <a className="back-button">
                  <i className="fa fa-filter" /> Filter
                </a>
              </DropdownToggle>
              <DropdownMenu>
                <div className="filter-menu-section">Account State</div>
                {allFilters &&
                  allFilters.length > 0 &&
                  allFilters.map((obj, idx) => {
                    return (
                      <div
                        key={idx}
                        className="filterMenuItem"
                        onClick={() => this.applyThisFilter(obj)}
                      >
                        {this.state.appliedFilter === obj ? <b>{obj}</b> : obj}
                      </div>
                    )
                  })}
                <div
                  className="filterMenuItem"
                  onClick={() => this.applyThisFilter('')}
                >
                  {this.state.appliedFilter === '' ? <b>ALL</b> : 'ALL'}
                </div>
                <hr className="hr-divider" />
                <div className="filter-menu-section">Notifications</div>
                <div
                  className="filterMenuItem"
                  onClick={() => this.applyThisFilter('ACTION_REQUIRED')}
                >
                  {this.state.appliedFilter === ACTION_REQUIRED ? (
                    <b>ACTION_REQUIRED_DOCS</b>
                  ) : (
                    'ACTION_REQUIRED_DOCS'
                  )}
                </div>

                {additionalFiltersData &&
                  additionalFiltersData.length > 0 &&
                  additionalFiltersData.map((obj, idx) => {
                    return (
                      <div
                        key={idx}
                        className="filterMenuItem"
                        onClick={() => this.applyThisFilter(obj)}
                      >
                        {this.state.appliedFilter === obj ? <b>{obj}</b> : obj}
                      </div>
                    )
                  })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="table-responsive">
          <div className="custom-table">
            <div className="table-head table-row">
              <div className="table-col text-left col-3">Name</div>
              <div className="table-col col-3">Email ID</div>
              <div className="table-col col-2">Account Number</div>
              <div className="table-col col-2">Date</div>
              <div className="table-col col-2">Bank Linked</div>
              <div className="table-col col-2">Error</div>
              <div className="table-col col-2">Status</div>
              <div className="table-col col-2">Utm Campaign</div>
              <div className="table-col col-2">Utm Medium</div>
              <div className="table-col col-2">Utm Source</div>
              <div className="table-col col-2">Test</div>
              <div className="table-col col-2">Restrict User</div>
              <div className="table-col col-2">Restrict SSN</div>
            </div>
            {accounts &&
              accounts.length > 0 &&
              accounts
                .filter(account => ![null, ''].includes(account.status))
                .map((obj, idx) => {
                  console.log(
                    'obj.apex_account_restricted -> ',
                    obj.apex_account_restricted
                  )
                  return (
                    <div key={idx} className="table-row">
                      <div
                        title={obj.name}
                        onClick={() => this.getDetails(obj)}
                        className="table-col text-left col-3"
                      >
                        <Paragraph
                          style={{ color: '#5FB0EA', cursor: 'pointer' }}
                          copyable
                        >
                          {obj.name}
                        </Paragraph>
                      </div>
                      <div
                        className="table-col col-2"
                        style={{ wordBreak: 'break-word' }}
                        title={obj.user__email}
                      >
                        <Paragraph copyable>{obj.user__email}</Paragraph>
                      </div>
                      <div className="table-col col-2">
                        <Paragraph copyable>{obj.account_no}</Paragraph>
                      </div>
                      <div className="table-col col-2">
                        {moment
                          .tz(moment.utc(obj.request_time).format(), 'utc')
                          .clone()
                          .tz('America/New_York')
                          .format('MM/DD/YYYY, hh:mm A')}
                      </div>
                      <div className="table-col col-2">
                        {obj.bank_account ? 'YES' : 'NO'}
                      </div>
                      <div className="table-col col-2">
                        {!this.state.errorObj[`is-error${obj.id}`] ? (
                          <a
                            className="back-button"
                            id={`view-error${obj.id}`}
                            onClick={() => this.checkForAccountError(obj)}
                          >
                            View
                          </a>
                        ) : null}
                        {this.state.errorObj[`is-error${obj.id}`] &&
                        this.state.errorObj[`is-error${obj.id}`] === 'error' ? (
                          <span id={`is-error${obj.id}`}>True</span>
                        ) : null}
                        {this.state.errorObj[`is-error${obj.id}`] &&
                        this.state.errorObj[`is-error${obj.id}`] === 'no' ? (
                          <span id={`no-error${obj.id}`}>False</span>
                        ) : null}
                      </div>
                      <div className="table-col col-2 position-relative ">
                        <span className="hyper-link rejected">
                          <Text
                            className="hyper-link rejected"
                            style={{ width: 67, color: '#5FB0EA' }}
                            ellipsis={{ tooltip: obj.status }}
                          >
                            {obj.status}
                          </Text>
                        </span>
                        {obj.status === 'SUBMIT_FAILED' && (
                          <a
                            className="back-button"
                            onClick={() => this.resubmitAccount(obj)}
                            style={{
                              margin: '8px 0px'
                            }}
                          >
                            Submit
                          </a>
                        )}
                        {obj.status !== 'COMPLETE' &&
                          obj.status !== 'SUSPENDED' && (
                            <i className="table-dot" />
                          )}
                        {obj.status === 'SUSPENDED' &&
                        obj.pending_documents &&
                        obj.pending_documents > 0 ? (
                          <div
                            style={{ position: 'absolute', right: 5, top: 5 }}
                          >
                            <Badge
                              style={{ backgroundColor: '#56a9e8' }}
                              count={obj.pending_documents}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="table-col col-2 text-ellipsis">
                        <Text
                          style={{ width: 67, color: '#5FB0EA' }}
                          ellipsis={{
                            tooltip:
                              obj.profile_utm && obj.profile_utm[0].utm_campaign
                                ? obj.profile_utm[0].utm_campaign
                                : '-'
                          }}
                        >
                          {obj.profile_utm && obj.profile_utm[0].utm_campaign
                            ? obj.profile_utm[0].utm_campaign
                            : '-'}
                        </Text>
                      </div>
                      <div className="table-col col-2 text-ellipsis">
                        <Text
                          style={{ width: 67, color: '#5FB0EA' }}
                          ellipsis={{
                            tooltip:
                              obj.profile_utm && obj.profile_utm[0].utm_medium
                                ? obj.profile_utm[0].utm_medium
                                : '-'
                          }}
                        >
                          {obj.profile_utm && obj.profile_utm[0].utm_medium
                            ? obj.profile_utm[0].utm_medium
                            : '-'}
                        </Text>
                      </div>
                      <div className="table-col col-2 text-ellipsis">
                        <Text
                          style={{ width: 67, color: '#5FB0EA' }}
                          ellipsis={{
                            tooltip:
                              obj.profile_utm && obj.profile_utm[0].utm_source
                                ? obj.profile_utm[0].utm_source
                                : '-'
                          }}
                        >
                          {obj.profile_utm && obj.profile_utm[0].utm_source
                            ? obj.profile_utm[0].utm_source
                            : '-'}
                        </Text>
                      </div>
                      <div className="table-col col-2">
                        <Switch
                          checkedChildren="Test"
                          defaultChecked={obj.user__is_test}
                          unCheckedChildren="Normal"
                          onChange={status =>
                            this.handleTestChange(status, obj)
                          }
                        />
                      </div>
                      <div className="table-col col-2 restrict-switch-wrapper">
                        <Switch
                          checked={obj.is_restricted}
                          onChange={status =>
                            this.handleRestrictUserChange(obj)
                          }
                        />
                      </div>
                      <div className="table-col col-2 restrict-switch-wrapper">
                        <Switch
                          defaultChecked={obj.apex_account_restricted}
                          onChange={status =>
                            this.handleRestrictSSNChange(status, obj)
                          }
                        />
                      </div>
                      {/* <div className="table-col col-2">
                        <a className="back-button" onClick={() => {}}>
                          Submit
                        </a>
                      </div> */}
                    </div>
                  )
                })}
            <div style={{ padding: '20px 10px' }}>
              <Pagination
                defaultPageSize={defaultPageLimit}
                total={accountsCount}
                showSizeChanger={false}
                current={this.state.activePage}
                // onShowSizeChange={onShowSizeChange}
                onChange={page => this.paginate(page)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// function mapStateToProps(state) {
//   let returnState = {};
//   returnState.isFetching = createLoadingSelector(['ACCOUNT'])({ state });
//   returnState.accounts = (state.account && state.account.account) ? state.account.account : [];
//   returnState.user = state.auth.user;
//   returnState.auth = state.auth;
//   returnState.loggedIn = state.auth.loggedIn;
//   returnState.error = state.account.error;
//   returnState.isLoading = state.account.isLoading;
//   return returnState
// }

function mapStateToProps({ auth, account, loading }) {
  return {
    auth,
    user: auth.user,
    error: account.error,
    loggedIn: auth.loggedIn,
    isLoading: account.isLoading,
    allStatus: account.allStatus,
    accountsCount: account.accountsCount,
    accounts: account && account.accounts ? account.accounts : [],
    loading: createLoadingSelector(['ACCOUNT', 'TRADING_ACCOUNT_LIST'])({
      loading
    })
  }
}

const mapDispatchToProps = {
  tradingAccountListRequest,
  tradingAccountDetailRequest,
  userRestrictRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsUsersListing))
