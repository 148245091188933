import zipObject from 'lodash/zipObject'

const actions = [
  // 'FETCH_ALL_ACCOUNTS',
  // 'ACCOUNTS_SUCCESS',
  // 'ACCOUNTS_FAILURE',

  // 'FETCH_ACCOUNT_DATA',
  // 'ACCOUNT_DATA_SUCCESS',
  // 'ACCOUNT_DATA_FAILURE',

  // 'CANCEL_ACH_TRANSACTION',
  // 'CANCEL_ACH_TRANSACTION_SUCCESS',
  // 'CANCEL_ACH_TRANSACTION_FAILURE',

  'FETCH_ACCOUNT_STATUS',
  'ACCOUNT_STATUS_SUCCESS',
  'ACCOUNT_STATUS_FAILURE',

  'UPDATE_ACCOUNT_STATUS',
  'ACCOUNT_UPDATE_STATUS_SUCCESS',
  'ACCOUNT_UPDATE_STATUS_FAILURE',

  'TRADING_ACCOUNT_LIST_REQUEST',
  'TRADING_ACCOUNT_LIST_FETCH',
  'TRADING_ACCOUNT_LIST_SUCCESS',
  'TRADING_ACCOUNT_LIST_FAILED',

  'TRADING_ACCOUNT_DETAIL_REQUEST',
  'TRADING_ACCOUNT_DETAIL_FETCH',
  'TRADING_ACCOUNT_DETAIL_SUCCESS',
  'TRADING_ACCOUNT_DETAIL_FAILED',

  'CANCEL_ACH_REQUEST',
  'CANCEL_ACH_FETCH',
  'CANCEL_ACH_SUCCESS',
  'CANCEL_ACH_FAILED',

  'CLOSE_ACCOUNT_REQUEST',
  'CLOSE_ACCOUNT_FETCH',
  'CLOSE_ACCOUNT_SUCCESS',

  'SET_ACCOUNT_STATE',

  'USER_RESTRICT_REQUEST',
  'USER_RESTRICT_FETCH',
  'USER_RESTRICT_SUCCESS',
  'USER_RESTRICT_FAILED',
]

export default zipObject(actions, actions)
