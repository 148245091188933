import { Formik } from 'formik'
import React from 'react'
import { Col, Button, Input } from 'antd'
import { Wrapper } from './styles'

const Mcom = ({ handleSubmit, validationSchema }) => {
  return (
    <Wrapper>
      <h4 className="label">Mcom Access</h4>
      <Formik validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, handleChange, errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="form">
            {getMcomFields(values).map(item => (
              <Col span={4}>
                <Input
                  placeholder={item.placeHolder}
                  type={item.type}
                  name={item.name}
                  onChange={handleChange}
                  value={item.value}
                  className="input__wrapper"
                />
                <p className="error__text">
                  {errors[item.name] && errors[item.name]}
                </p>
              </Col>
            ))}

            <Col span={3} className="buttonWrapper">
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Col>
          </form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default Mcom

export const getMcomFields = values => {
  return [
    {
      name: 'email',
      placeHolder: 'Enter Email',
      type: 'email',
      value: values.email
    },
    {
      name: 'division_userid',
      placeHolder: 'Enter Division ID',
      type: 'text',
      value: values.division_userid
    },
    {
      name: 'division',
      placeHolder: 'Enter Division',
      type: 'text',
      value: values.division
    },
    {
      name: 'firstname',
      placeHolder: 'Enter First Name',
      type: 'text',
      value: values.firstname
    },
    {
      name: 'lastname',
      placeHolder: 'Enter Last Name',
      type: 'text',
      value: values.lastname
    }
  ]
}
