import { getNotification } from '../utils'
import { logout } from 'actions/auth'
import config from '../../../env'
import axios from 'axios'
const handleDelete = async ({ id, setLoading, dispatch, setRefetch }) => {
  try {
    setLoading(true)
    const { data } = await axios.delete(
      `${config.adminBaseUrl}/cashmaster/${id}`,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification(
        'success',
        data.ticker && `Ticker - ${data.ticker} DELETED`
      )
    }
    setLoading(false)
    setRefetch(prev => prev + 1)
  } catch (error) {
    setLoading(false)
  } finally {
    setLoading(false)
  }
}

const handleEdit = async ({ id, setLoading, dispatch, setRefetch }) => {
  try {
    const editTicker = window.prompt('Edit Ticker')
    if (editTicker === null || editTicker === '' || editTicker === undefined)
      return
    setLoading(true)
    const { data } = await axios.patch(
      `${config.adminBaseUrl}/cashmaster/${id}`,
      {
        ticker: editTicker.toUpperCase()
      },
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        },
        body: {
          ticker: editTicker
        }
      }
    )
    if (data) {
      getNotification(
        'success',
        data.ticker && `Ticker - ${data.ticker} EDITED`
      )
    }
    setLoading(false)
    setRefetch(prev => prev + 1)
  } catch (error) {
    setLoading(false)
    getNotification('error', error.response.data.error)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}
async function handleAdd({ setLoading, payload, setRefetch }) {
  try {
    setLoading(true)
    console.log('payload', payload)
    await axios.post(`${config.adminBaseUrl}/cashmaster`, payload, {
      headers: {
        Authorization: `${localStorage.getItem('token')}`
      }
    })
    getNotification('success', 'Ticker ADDED')
    setRefetch(prev => prev + 1)
  } catch (error) {
    getNotification('error', 'There was an error')
    // if (error.response && error.response.status === 401) {
    //   if (error.response.data.detail === 'Invalid token.') dispatch(logout())
    // }
  } finally {
    setLoading(false)
  }
}
const handleGet = async () => {
  try {
    const { data } = await axios.get(`${config.adminBaseUrl}/cashmaster`, {
      headers: {
        Authorization: `${localStorage.getItem('token')}`
      }
    })
    return data
  } catch (error) {
    return null
  }
}
export { handleDelete, handleEdit, handleGet, handleAdd }
