import React, { useState } from 'react'
import { FormItem } from './FormItem'
import { requiredValidation } from './utils'
import { Spinner } from 'shared-components/'
import { useDispatch } from 'react-redux'
import { populateHoldings, populatePortfolio, populateProfile } from './utils'
import { PopulateDetailsWrapper } from './styles'
import { UploadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import config from '../../../env'
import axios from 'axios'
import { Input } from 'reactstrap'

const PopulateDetails = () => {
  const [isLoading, setLoading] = useState(false)

  const handlePopulateHoldings = values => {
    populateHoldings(values, setLoading)
  }
  const handlePopulatePortfolio = values => {
    populatePortfolio(values, setLoading)
  }
  const handlePopulateProfile = values => {
    populateProfile(values, setLoading)
  }

  const onChangeHandler = async event => {
    const apiUrl = `${config.tradeServiceBaseUrl}/v1/admin/sod/upload_manual_trade_line_entries`
    const csrfToken =
      '6fXVGKgmJ5G6Ico7sCQk0GEDjxjNuwbZo8Y2Ti7KMUKW8GTuMR3ViUoASr2JEugx'
    if (event.target.files && event.target.files[0]) {
      let formData = new FormData()

      formData.append('file', event.target.files[0])

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            Cookie: `csrftoken=${csrfToken}`,
            authorization: `${window.localStorage.getItem('token')}`
          }
        })

        if (response.status === 200) {
          message.success('File Uploaded')
          event.target.value = ''
        }
      } catch (err) {
        message.error('Something Went Wrong')
        console.log('<--- upload doc api error --->', err)
      }
    }
  }

  return (
    <PopulateDetailsWrapper>
      <Spinner isFetching={isLoading} />
      <div className="container-wrapper homeWrapper">
        <h2 style={{ marginBottom: '30px' }}>Populate Details</h2>
        <FormItem
          label="Populate Holdings"
          handleSubmit={handlePopulateHoldings}
          validationSchema={requiredValidation}
          isBorderRequired
        />
        <FormItem
          label="Populate Portfolio"
          handleSubmit={handlePopulatePortfolio}
          validationSchema={requiredValidation}
          isBorderRequired
        />
        <FormItem
          label="Populate Profile"
          handleSubmit={handlePopulateProfile}
          validationSchema={requiredValidation}
        />
        <hr />
        <div className="manual-upload">
          <h4 className="label">Manual Upload</h4>
          <Input
            type="file"
            name={`uploadDoc`}
            id={`uploadDoc`}
            onChange={onChangeHandler}
          />
        </div>
      </div>
    </PopulateDetailsWrapper>
  )
}

export default PopulateDetails
