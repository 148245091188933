import ActionTypes from '../action-types'

export function updatePremiumState(payload) {
  return {
    type: ActionTypes.UPDATE_PREMIUM_STATE,
    payload
  }
}

export function setAuthState(payload) {
  return {
    type: ActionTypes.SET_AUTH_STATE,
    payload
  }
}

export function auth(payload) {
  return {
    type: ActionTypes.AUTH_REQUEST,
    payload
  }
}

export function successfulAuth(response) {
  return {
    type: ActionTypes.AUTH_SUCCESS,
    response
  }
}

export function failedAuth(error) {
  return {
    type: ActionTypes.AUTH_FAILURE,
    error
  }
}

export function authGoogle(payload) {
  return {
    type: ActionTypes.GOOGLE_AUTH_REQUEST,
    payload
  }
}

export function successfulGoogleAuth(response) {
  return {
    type: ActionTypes.GOOGLE_AUTH_SUCCESS,
    response
  }
}

export function failedGoogleAuth(error) {
  return {
    type: ActionTypes.GOOGLE_AUTH_FAILURE,
    error
  }
}

export function termsAgreement(payload) {
  return {
    type: ActionTypes.TERMS_AGREEMENT_REQUEST,
    payload
  }
}

export function successfulTermsAgreement(response) {
  return {
    type: ActionTypes.TERMS_AGREEMENT_SUCCESS,
    response
  }
}

export function failedTermsAgreement(error) {
  return {
    type: ActionTypes.TERMS_AGREEMENT_FAILURE,
    error
  }
}

export function logout(payload) {
  return {
    type: ActionTypes.LOGOUT_REQUEST,
    payload
  }
}

export function successfulLogout(response) {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    response
  }
}

export function failedLogout(error) {
  return {
    type: ActionTypes.LOGOUT_FAILURE,
    error
  }
}

export function sendChangePwdEmail(payload, callback) {
  return {
    type: ActionTypes.SEND_CHANGE_PWD_EMAIL,
    payload,
    callback,
  }
}

export function verifyChangePwdToken(payload) {
  return {
    type: ActionTypes.VERIFY_CHANGE_PWD_TOKEN,
    payload
  }
}

export function changePassword(payload) {
  return {
    type: ActionTypes.FORGOT_CHANGE_PASSWORD,
    payload,
  }
}

export function saveverifyChangePwdToken(payload){
  return{
    type:ActionTypes.SAVE_VERIFY_CHANGE_PWD_TOKEN,
    payload
  }
}

export function successfulSetPassword(response) {
  return {
    type: ActionTypes.SAVE_SET_PASSWORD,
    response
  }
}

export function successfulSetAdvisorData(response) {
  return {
    type: ActionTypes.SET_ADVISOR_DATA,
    response
  }
}
