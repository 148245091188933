import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from 'layouts/WebDashboard/Login'
import SignOut from 'layouts/WebDashboard/Login/SignOut'
import AuthCallback from 'layouts/WebDashboard/AuthCallback'

import { PrivateRoute } from 'shared-components'
import { OktaProvider } from './features/okta'

class ChokidarRouter extends React.Component {
  render() {
    const { Layout } = this.props
    return (
      <OktaProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/auth/callback" component={AuthCallback} />
          <Route exact path="/signout" component={SignOut} />
          <PrivateRoute path="/" bypass component={Layout} />
        </Switch>
      </OktaProvider>
    )
  }
}

export default ChokidarRouter
