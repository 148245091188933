import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import enquire from 'enquire-js'

import { PrivateRoute } from 'shared-components'

import { logout } from './actions/auth'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'ion-rangeslider/css/ion.rangeSlider.css'
import 'ion-rangeslider/css/ion.rangeSlider.skinFlat.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import 'third-party/jquery-flipster/jquery.flipster.min.css'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from './shared-components'

import WebDashboardLayout from 'layouts/WebDashboard'
import ChokidarRouter from './ChokidarRouter'

import config from './env'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import Authorized from 'common/Authorized'

const { AuthorizedRoute } = Authorized

class App extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.logoutSuccess !== prevProps.logoutSuccess) {
      if (this.props.logoutSuccess) {
        window.location.href = REDIRECT_DOMAIN_PATH
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Spinner isFetching={this.props.isLogoutFetching} />
        <ConnectedRouter history={this.props.history}>
          <Switch>
            <ChokidarRouter Layout={WebDashboardLayout} />
          </Switch>
        </ConnectedRouter>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, loading, global }) => ({
  logoutSuccess: auth.logoutSuccess,
  isLogoutFetching: createLoadingSelector(['LOGOUT'])({ loading })
})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
