import { useOktaContext } from 'features/okta'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import Spinner from 'shared-components/Spinner'

function AuthCallback({ history, loggedIn, authFailed }) {
  const { isLoginRedirect, parseTokenFromUrl } = useOktaContext()

  useEffect(() => {
    if (loggedIn) {
      history.push('/accounts')
    }
    if (authFailed) {
      history.push('/login')
    }
  }, [loggedIn, authFailed])

  useEffect(() => {
    if (isLoginRedirect()) {
      parseTokenFromUrl()
    } else {
      history.push('/login')
    }
  }, [])

  return <Spinner isFetching />
}

const mapStateToProps = ({ auth }) => ({
  loggedIn: auth.loggedIn,
  authFailed: auth.authFailed
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthCallback))
