import { CLIENT_ID, ISSUER, REDIRECT_URI, SCOPES } from './constants'

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  scopes: SCOPES,
  redirectUri: REDIRECT_URI,
  postLogoutRedirectUri: `${window.location.origin}`,
  pkce: true
}

export default config
