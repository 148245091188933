import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap';

const ReasonModal = ({isOpen, toggle, onExit, id, handleConfirm}) => {
    const [reason, setReason] = React.useState('');
    return ( 
    <Modal className="reason-modal" isOpen={isOpen} toggle={toggle} centered onClosed={onExit} >
      <ModalHeader className="modal-header">
          Are you sure you want to cancel ACH?
          {/* <i onClick={onExit} className="fal fa-times" /> */}
      </ModalHeader>
      <ModalBody className="reason-container" >
        <label>Reason (Optional!): </label>
        <Input type="textarea" className="reason-input" onChange={(e) => setReason(e.target.value)}/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="blue-btn-footer lined-btn" onClick={onExit}>Close</Button>
        <Button className="blue-btn-footer" onClick={() => handleConfirm(id,reason)}>Ok</Button>
      </ModalFooter>
    </Modal>)
  }
export default ReasonModal;