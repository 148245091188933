import AuthActionTypes from './auth'
import AccountActionTypes from './account'
import TradesActionTypes from './trades'
import AlertsActionTypes from './alerts'
import SponsersActionTypes from './sponsers'

export default {
  ...AuthActionTypes,
  ...AccountActionTypes,
  ...TradesActionTypes,
  ...AlertsActionTypes,
  ...SponsersActionTypes
}
