import config from 'env'

export const SCOPES = ['openid', 'profile', 'email', 'offline_access']
export const CLIENT_ID = config.oktaClientId
export const REDIRECT_URI = `${window.location.origin}/auth/callback`
export const ISSUER = `https://${config.oktaIssuer}/oauth2/default`
export const oktaErrorMappers = {
  'errors.E0000004':
    "Entered password is incorrect. Please note that your account was recently upgraded with an extra layer of security. If you haven't already changed your password please check your inbox or reach out to support@magnifi.com for further assistance.",
  'errors.E0000064': 'Password is expired',
  'idx.session.expired': 'Session expired',
  'registration.error.notUniqueWithinOrg':
    'A user with this Email already exists'
}
