import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { auth } from './auth'
import { loading } from './loading'
import { account } from './account'
import { trades } from './trades'
import { alerts } from './alerts'
import { sponsers } from './sponsers'


export default history =>
  combineReducers({
    auth,
    account,
    trades,
    loading,
    alerts,
    sponsers,
    router: connectRouter(history)
  })
