import ActionTypes from '../action-types'
/*  <----- Previous logic to fetch All Account on Trading Account-------->
// export function fetchAccounts(payload, callback) {

//   return {
//     type: ActionTypes.FETCH_ALL_ACCOUNTS,
//     payload,
//     callback,
//   }
// }

// export function successfulFetchAccount(account) {
//   return {
//     type: ActionTypes.ACCOUNTS_SUCCESS,
//     account
//   }
// }

// export function failedFetchAccount(error) {
//   return {
//     type: ActionTypes.ACCOUNTS_FAILURE,
//     error
//   }
// }
*/

/*<----- Previous logic to fetch Account Detail on Trading Account----->
export function fetchAccountData(payload, callback) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_DATA,
    payload,
    callback,
  }
}

export function successfulFetchAccountData(account) {
  return {
    type: ActionTypes.ACCOUNT_DATA_SUCCESS,
    account
  }
}

export function failedFetchAccountData(error) {
  return {
    type: ActionTypes.ACCOUNT_DATA_FAILURE,
    error
  }
}*/

/*<----- BELOW CODES WE ARE NOT USING CURRENTLY----->*/
export function fetchAccountStatus(payload, callback) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_STATUS,
    payload,
    callback,
  }
}

export function successfulFetchAccountStatus(account) {
  return {
    type: ActionTypes.ACCOUNT_STATUS_SUCCESS,
    account
  }
}

export function failedFetchAccountStatus(error) {
  return {
    type: ActionTypes.ACCOUNT_STATUS_FAILURE,
    error
  }
}

export function updateAccountStatus(payload, callback) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_STATUS,
    payload,
    callback,
  }
}

export function successfulUpdateAccountStatus(account) {
  return {
    type: ActionTypes.ACCOUNT_UPDATE_STATUS_SUCCESS,
    account
  }
}

export function failedUpdateAccountStatus(error) {
  return {
    type: ActionTypes.ACCOUNT_UPDATE_STATUS_FAILURE,
    error
  }
}

/*<----- ABOVE CODES WE ARE NOT USING CURRENTLY----->*/

// export function cancelAchRelationship(payload) {
//   return {
//     type: ActionTypes.CANCEL_ACH_TRANSACTION,
//     payload
//   }
// }

// export function successfulAchRelationship(payload) {
//   return {
//     type: ActionTypes.CANCEL_ACH_TRANSACTION_SUCCESS,
//     payload
//   }
// }

// export function failedAchRelationship(error) {
//   return {
//     type: ActionTypes.CANCEL_ACH_TRANSACTION_FAILURE,
//     error
//   }
// }

/* ---- GET ALL ACCOUNT LIST  ---- */

export function tradingAccountListRequest(payload, callback) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_LIST_REQUEST,
    payload,
    callback,
  }
}
export function tradingAccountListFetch(payload) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_LIST_FETCH,
    payload
  }
}
export function tradingAccountListSuccess(payload) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_LIST_SUCCESS,
    payload
  }
}
export function tradingAccountListFailed(payload) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_LIST_FAILED,
    payload
  }
}

/* ---- GET ACCOUNT DETAIL ---- */

export function tradingAccountDetailRequest(payload, callback) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_DETAIL_REQUEST,
    payload,
    callback,
  }
}
export function tradingAccountDetailFetch(payload) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_DETAIL_FETCH,
    payload
  }
}
export function tradingAccountDetailSuccess(payload) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_DETAIL_SUCCESS,
    payload
  }
}
export function tradingAccountDetailFailed(payload) {
  return {
    type: ActionTypes.TRADING_ACCOUNT_DETAIL_FAILED,
    payload
  }
}

/* ---- CANCEL ACHS RELATIONSHIP ---- */

export function cancelACHRequest(payload, callback) {
  return {
    type: ActionTypes.CANCEL_ACH_REQUEST,
    payload,
    callback,
  }
}
export function cancelACHFetch(payload) {
  return {
    type: ActionTypes.CANCEL_ACH_FETCH,
    payload
  }
}
export function cancelACHSuccess(payload) {
  return {
    type: ActionTypes.CANCEL_ACH_SUCCESS,
    payload
  }
}
export function cancelACHFailed(payload) {
  return {
    type: ActionTypes.CANCEL_ACH_FAILED,
    payload
  }
}

/* ---- GET ACCOUNT DETAIL ---- */
export function closeAccountRequest(payload, callback) {
  return {
    type: ActionTypes.CLOSE_ACCOUNT_REQUEST,
    payload,
    callback,
  }
}

export function closeAccountFetch(payload) {
  return {
    type: ActionTypes.CLOSE_ACCOUNT_FETCH,
    payload
  }
}

export function closeAccountSuccess(payload) {
  return {
    type: ActionTypes.CLOSE_ACCOUNT_SUCCESS,
    payload
  }
}

/* ---- GET ACCOUNT DETAIL ---- */
export function setAccountState(payload) {
  return {
    type: ActionTypes.SET_ACCOUNT_STATE,
    payload
  }
}

/* ---- GET USER RESTRICT  ---- */
export function userRestrictRequest(payload, callback) {
  return {
    type: ActionTypes.USER_RESTRICT_REQUEST,
    payload,
    callback,
  }
}
export function userRestrictRequestFetch(payload) {
  return {
    type: ActionTypes.USER_RESTRICT_FETCH,
    payload
  }
}
export function userRestrictRequestSuccess(payload, callback) {
  return {
    type: ActionTypes.USER_RESTRICT_SUCCESS,
    payload,
    callback
  }
}
export function userRestrictRequestFailed(payload) {
  return {
    type: ActionTypes.USER_RESTRICT_FAILED,
    payload
  }
}
