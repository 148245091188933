import get from 'lodash/get'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import ActionTypes from 'action-types'
import * as actions from 'actions/auth'

import * as AuthApi from 'apis/auth'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { oktaAuth } from 'features/okta'
import { addEventToAnalytics } from 'Utils'
import * as AuthUtils from '../common/auth-utils'

function* auth(action) {
  const { payload } = action
  try {
    const response = yield call(AuthApi.validateOktaUser, payload)
    const result = response.data
    console.log('result', result)
    if (result && !result.is_staff) {
      yield put(
        actions.failedAuth({ error: 'You are not authorized to login' })
      )
      return
    }
    AuthUtils.storeAuthToken({
      token: result.token,
      email: payload.email,
      userId: result.profile.user,
      firstName: result.profile.first_name,
      lastName: result.profile.last_name,
      termsAgreed: result.profile.terms_agreed,
      self_role: result.self_role,
      client_exist: result.client_exist,
      user_advisor_menu: result.user_advisor_menu
    })

    result.email = payload.email
    result.firstName = result.profile.first_name
    result.lastName = result.profile.last_name
    result.user_id = result.profile.user
    addEventToAnalytics('Login', 'Login', 'LOGIN', { email: payload.email })
    yield put(actions.successfulAuth(result))
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedAuth(errorDetails))
  }
}

function* logout(action) {
  try {
    AuthUtils.deleteAuthToken()
    oktaAuth.signOut()
    window.location.href = REDIRECT_DOMAIN_PATH
  } catch (error) {
    AuthUtils.deleteAuthToken()
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedLogout(errorDetails))
  }
}

function* loginSaga() {
  yield all([
    fork(takeEvery, ActionTypes.AUTH_REQUEST, auth),
    fork(takeEvery, ActionTypes.LOGOUT_REQUEST, logout)
  ])
}

export default loginSaga
