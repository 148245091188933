import ActionTypes from '../action-types'

import { getAuthToken, isPremiumUser } from '../common/auth-utils'

const tokenInfo = getAuthToken()

const cleanState = {
  loggedIn: false,
  loggingIn: false,
  user: {
    email: ''
  },
  token: '',
  newUser: 0,
  termsAgreed: parseInt(window.localStorage.getItem('termsAgreed')) || 0,
  authFailed: false,
  authSuccess: false,
  isGoogleLogin: false,
  logoutSuccess: false,
  logoutFailed: false,
  accConfirmedModal: false,
  client_exist: false,
  user_advisor_menu: false
}

const initialState = {
  ...cleanState,
  loggedIn: Boolean(tokenInfo.token),
  user: {
    email: tokenInfo.email || '',
    firstName: tokenInfo.firstName || '',
    lastName: tokenInfo.lastName || '',
    premiumUser: false, //isPremiumUser(tokenInfo),
    planName: '',
    plan_start_date: '',
    plan_end_date: '',
    subscriber_since: '',
    subscription_id: '',
    renews_on: '',
    cancellation_request_status: false,
    cancels_on: ''
  },
  token: tokenInfo.token || '',
  isGoogleLogin: tokenInfo.isGoogleLogin,
  verifypwdtoken: '',
  setPasswordSuccess: false,
  client_exist: tokenInfo.client_exist || false,
  user_advisor_menu: tokenInfo.user_advisor_menu || false,
  profile: []
}

export function auth(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.UPDATE_PREMIUM_STATE:
      return {
        ...state,
        user: action.payload.user,
      }
    case ActionTypes.SET_AUTH_STATE:
      return {
        ...cleanState,
        ...state,
        accConfirmedModal: action.payload.accConfirmedModal,
      }
    case ActionTypes.AUTH_REQUEST:
      return {
        ...cleanState,
        ...state,
        loggingIn: true,
        authSuccess: false,
        authFailed: false,
        logoutSuccess: false,
        newUser: 0,
        termsAgreed: 0,
        user: {},
        token: '',
        error: {}
      }
    case ActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        authSuccess: true,
        user: {
          ...action.response,
          premiumUser: false, //isPremiumUser(action.response),
        },
        userId: action.response.profile.user,
        profile: action.response.profile,
        token: action.response.token,
        newUser: action.response.profile.new_user,
        termsAgreed: action.response.profile.terms_agreed,
        client_exist: action.response.client_exist,
        user_advisor_menu: action.response.user_advisor_menu
      }
    case ActionTypes.AUTH_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        authFailed: true,
        error: action.error,
        newUser: 0,
        termsAgreed: 0
      }

    case ActionTypes.GOOGLE_AUTH_REQUEST:
      return {
        ...state,
        loggingIn: true,
        authSuccess: false,
        authFailed: false,
        user: {},
        token: '',
        error: {},
        newUser: 0,
        termsAgreed: 0
      }
    case ActionTypes.GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        authSuccess: true,
        isGoogleLogin: true,
        user: {},
        token: action.response.token,
        newUser: action.response.new_user,
        termsAgreed: action.response.terms_agreed
      }
    case ActionTypes.GOOGLE_AUTH_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        authSuccess: false,
        authFailed: true,
        user: {},
        token: '',
        newUser: 0,
        termsAgreed: 0
      }

    case ActionTypes.TERMS_AGREEMENT_REQUEST:
      return {
        ...state,
        termsAgreed: 0
      }
    case ActionTypes.TERMS_AGREEMENT_SUCCESS:
      return {
        ...state,
        termsAgreed: action.response.profile.terms_agreed,
        accConfirmedModal: action.response.profile.accConfirmedModal,
      }
    case ActionTypes.TERMS_AGREEMENT_FAILURE:
      return {
        ...state,
        termsAgreed: 0
      }

    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...cleanState,
        logoutSuccess: true
      }
    case ActionTypes.LOGOUT_FAILURE:
      return {
        ...cleanState,
        logoutSuccess: true // TODO: change later
      }
    case ActionTypes.SAVE_VERIFY_CHANGE_PWD_TOKEN:
      const { payload } = action;
      return {
        ...cleanState,
        ...state,
        verifypwdtoken: payload.token,
      }

    case ActionTypes.SAVE_SET_PASSWORD:
      return {
        ...state,
        setPasswordSuccess: true,
      }

    case ActionTypes.SET_ADVISOR_DATA:
      return {
        ...state,
        client_exist: action.response.client_exist,
        user_advisor_menu: action.response.user_advisor_menu
      }

    default:
      return state
  }
}
