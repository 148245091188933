import { createAPIInstance } from './api'
import config from '../env'
import axios from 'axios'

export const fetchAllTrades = async (pages = 1) => {
  const data = await axios.get(
    `${config.tradeServiceBaseUrl}/v1/admin/latest/trades?pages=${pages}`,
    {
      headers: {
        Authorization: `${localStorage.getItem('token')}`
      }
    }
  )
  return data
}

export function fetchCustomTrades(values) {
  const {
    page = 1,
    tradeDate,
    createdAt,
    accountNumber,
    id,
    ticker,
    action,
    option,
    sellAll,
    status,
    vehicle
  } = values
  return (
    createAPIInstance()
      // .get(
      //   `user/apex/latest/trades?${
      //     values.tradeDate ? `trade_date=${values.tradeDate}` : ''
      //   }&$${values.settleDate ? `settle_date=${values.settleDate}` : ''}&$${
      //     values.accountNumber ? `account=${values.accountNumber}` : ''
      //   }
      //   &$${values.id ? `id=${values.id}` : ''}
      //   &$${values.ticker ? `ticker=${values.ticker}` : ''}
      //   &$${values.action ? `side=${values.action}` : ''}
      //   &$${values.sellAll ? `sell_all=${values.sellAll}` : ''}
      //   &$${values.id ? `id=${values.id}` : ''}
      //   &$${values.status ? `status=${values.status}` : ''}
      //   &$${values.vehicle ? `vehicle=${values.vehicle}` : ''}`
      // )
      .get(
        'user/apex/latest/trades?page=' +
          page +
          'trade_date=' +
          tradeDate +
          '&created_at=' +
          createdAt +
          '&account=' +
          accountNumber +
          '&id=' +
          id +
          '&ticker=' +
          ticker +
          '&option=' +
          option +
          '&side=' +
          action +
          '&sell_all=' +
          sellAll +
          '&status=' +
          status +
          '&vehicle=' +
          vehicle
      )
      .then(response => response)
  )
}
