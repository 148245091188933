import { Formik } from 'formik'
import { Row, Col, Radio, Button, Input } from 'antd'
import React from 'react'
import { Wrapper } from './styles'
import { Divider } from 'antd'

export const FormItem = ({
  label,
  handleSubmit,
  isRadio = false,
  validationSchema,
  isBorderRequired = false
}) => {
  return (
    <Wrapper>
      <h4 className="label">{label}</h4>
      <Row>
        <Formik validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, handleChange, errors, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              {isRadio ? (
                <Col span={8}>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="user_email"
                    onChange={handleChange}
                    value={values.user_email}
                    className="input__wrapper"
                  />
                  <p className="error__text">
                    {isRadio
                      ? errors.user_email && errors.user_email
                      : errors.email && errors.email}
                  </p>
                  <Radio.Group
                    className="radio__group"
                    name="activate_mentor"
                    id="activate_mentor"
                    onChange={handleChange}
                    value={values.activate_mentor}
                  >
                    <Radio value={true}>Activate</Radio>
                    <Radio value={false}>Deactivate</Radio>
                  </Radio.Group>
                  <p className="error__text">
                    {isRadio &&
                      errors.activate_mentor &&
                      errors.activate_mentor}
                  </p>
                </Col>
              ) : (
                <Col span={8}>
                  <Input
                    placeholder="Enter apex account number eg: 8NA16576"
                    type="es_number"
                    name="es_number"
                    onChange={handleChange}
                    value={values.email}
                    className="input__wrapper"
                  />
                  <p className="error__text">
                    {errors.es_number && errors.es_number}
                  </p>
                </Col>
              )}
              <Col span={3} className="buttonWrapper">
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Col>
            </form>
          )}
        </Formik>
      </Row>
      {isBorderRequired && <Divider />}
    </Wrapper>
  )
}
