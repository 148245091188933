import React, { useState } from 'react'
import { FormItem, FormItemOldEmailToNewEmail } from './FormItem'
import {
  emailValidation,
  personalActivatedValidation,
  unregisterUser,
  clearMail,
  activatePersonal,
  accountReopenValidation,
  apexAccountOpen,
  reinitiateAccount,
  reinitiateValidation,
  unlinkBank,
  unlinkBankAccount,
  mcomAccess,
  mcomValidation,
  checkOkta,
  oktaValidation,
  changeOldEmailToNewEmail,
  oldToNewEmailValidation,
  deleteUserData,
  deleteUserValidation
} from './utils'
import { Spinner } from 'shared-components/'
import { useDispatch } from 'react-redux'
import Mcom from './Mcom'

const UserRequests = () => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)

  const handleUnRegister = values => {
    unregisterUser(values, setLoading, dispatch)
  }
  const handleClearMail = values => {
    clearMail(values, setLoading, dispatch)
  }
  const handleActivePersonal = values => {
    activatePersonal(values, setLoading, dispatch)
  }
  const handleAccountNo = values => {
    apexAccountOpen(values, setLoading, dispatch)
  }

  const handleReinitiate = values => {
    reinitiateAccount(values, setLoading, dispatch)
  }

  const handleUnlinkBank = values => {
    unlinkBankAccount(values, setLoading, dispatch)
  }

  const handleMCOMAccess = values => {
    mcomAccess(values, setLoading, dispatch)
  }

  const handleOkta = values => {
    checkOkta(values, setLoading, dispatch)
  }
  const handleDeleteUserData = values => {
    deleteUserData(values, setLoading, dispatch)
  }
  const handleOldToNewEmail = async values => {
    changeOldEmailToNewEmail(values, setLoading, dispatch)
  }
  return (
    <>
      <Spinner isFetching={isLoading} />
      <div className="container-wrapper homeWrapper">
        <h2 style={{ marginBottom: '30px' }}>User Requests</h2>
        <FormItem
          label="Unregister User"
          handleSubmit={handleUnRegister}
          validationSchema={emailValidation}
          isBorderRequired
          placeHolder={'Email'}
          name="email_id"
        />
        <FormItem
          label="Clear Back to Email and Password"
          handleSubmit={handleClearMail}
          validationSchema={emailValidation}
          isBorderRequired
          placeHolder={'Email'}
          name="email_id"
        />
        <FormItem
          label="Activate/Deactivate Personal Access"
          handleSubmit={handleActivePersonal}
          isRadio
          isBorderRequired
          validationSchema={personalActivatedValidation}
          placeHolder={'Email'}
          name="email_id"
        />
        <FormItem
          label="Reopen Closed Apex Account"
          handleSubmit={handleAccountNo}
          validationSchema={accountReopenValidation}
          type="text"
          isBorderRequired
          placeHolder={'Account Number'}
          name="account_no"
        />
        <FormItem
          label="Reinstate Account"
          handleSubmit={handleReinitiate}
          validationSchema={reinitiateValidation}
          isReinitiate
          isBorderRequired
        />
        <FormItem
          label="Unlink Bank Account"
          handleSubmit={handleUnlinkBank}
          validationSchema={unlinkBank}
          name="user_email"
          isBorderRequired
        />
        <FormItem
          label="Check Login Type (Social/Okta)"
          handleSubmit={handleOkta}
          validationSchema={oktaValidation}
          name="email"
          isBorderRequired
        />
        <FormItem
          label="Delete User Data"
          handleSubmit={handleDeleteUserData}
          validationSchema={deleteUserValidation}
          name="email"
          isBorderRequired
        />
        <FormItemOldEmailToNewEmail
          label={'Change Old Email to New Email'}
          handleSubmit={handleOldToNewEmail}
          validationSchema={oldToNewEmailValidation}
        />
        <Mcom
          handleSubmit={handleMCOMAccess}
          validationSchema={mcomValidation}
        />
      </div>
    </>
  )
}

export default UserRequests
