import React from 'react'
import { Spinner as SpinnerLoader} from 'reactstrap'

export default function Spinner({ isFetching }) {
  if (!isFetching) {
    return null
  }

  return (
    <div className="text-center spinner-wrapper">
      <SpinnerLoader style={{ width: '3rem', height: '3rem', color: '#e26b02' }} />
    </div>
  )
}
