import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Alert
} from 'reactstrap'

import {addEventToAnalytics} from '../../../Utils';

import { CustomFormFeedback, PrimaryButton } from 'shared-components'

const googleLogo = "/assets/images/google@3x.png";

export default class LoginForm extends Component {
  static propTypes = {
    formProps: PropTypes.object.isRequired,
  }

  render() {
    const { formProps, error } = this.props;
    const linkButton = (
      <FormGroup>
        <Breadcrumb className="sign-in__breadcrumb">
          <BreadcrumbItem className="sign-in__breadcrumb-item">
            <Link to="/register" className="sign-in__breadcrumb-link">
              Register
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem className="sign-in__breadcrumb-item">
            <Link to="/forgot-password" className="sign-in__breadcrumb-link">
              Forgot Password?
            </Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </FormGroup>
    );

    const forgetPassword = (
      <Link className="hyper-link" to="/forgot-password">Forgot Password?</Link>
    );

    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
    } = formProps
    return (
      <Form className="sign-in magnifi-auth-design-container" noValidate={true} onSubmit={handleSubmit}>
        <div className="header-area">
          <div className="header-label">Welcome to Magnifi</div>
        </div>

        <div className="body-wrapper">
          <FormGroup className="element-box">
            <Label htmlFor="email" className="label">
              Email Address
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.email && touched.email
                  ? 'sign-in__input-field sign-in__input-email is-invalid input-area'
                  : 'sign-in__input-field sign-in__input-email input-area'
              }
            />
            <CustomFormFeedback formProps={formProps} fieldName="email" />
          </FormGroup>

          <FormGroup className="element-box pos-rel">
            <Label htmlFor="password" className="label">
              Password
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              //placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.password && touched.password
                  ? 'sign-in__input-field sign-in__input-password is-invalid input-area'
                  : 'sign-in__input-field sign-in__input-password input-area'
              }
            />
            <CustomFormFeedback formProps={formProps} fieldName="password" />
            {error && error.error && <Alert color="danger">{error.error}</Alert>}
            <div onClick={() => addEventToAnalytics('Forget Password', 'Forget Password', 'FORGET_PASSWORD', {}, false)}>
              <Link className="hyper-link" style={{ fontSize:'0.8rem'}} to="/forgotpwd">Forgot Password?</Link>
            </div>
          </FormGroup>

          <FormGroup className="cta-wrapper">
            <PrimaryButton
              type="submit"
              className="btn primary-btn"
              block
              disabled={isSubmitting}
            >
              Sign in
            </PrimaryButton>

          </FormGroup>
        </div>

      </Form>
    )
  }
}
