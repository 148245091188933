import { logout } from 'actions/auth'
import axios from 'axios'
import config from '../../../env'
import { getNotification } from '../utils'

const handleGet = async () => {
  try {
    const { data } = await axios.get(
      `${config.adminBaseUrl}/cryptocurrency-master`,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    return data
  } catch (error) {
    return null
  }
}

const handleApprovalChange = async ({
  id,
  setLoading,
  dispatch,
  setRefetch,
  approval
}) => {
  try {
    setLoading(true)
    const { data } = await axios.patch(
      `${config.adminBaseUrl}/cryptocurrency-master/${id}`,
      {
        is_approved: approval
      },
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification(
        'success',
        data.ticker &&
          `Ticker - ${data.ticker} ${approval ? 'APPROVED' : 'DISAPPROVED'}`
      )
    }
    setLoading(false)
    setRefetch(prev => prev + 1)
  } catch (error) {
    setLoading(false)
    getNotification('error', error.response.data.error)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}

const handleDelete = async ({ id, setLoading, dispatch, setRefetch }) => {
  try {
    setLoading(true)
    const { data } = await axios.delete(
      `${config.adminBaseUrl}/cryptocurrency-master/${id}`,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification('success', 'Ticker DELETED')
    }
    setLoading(false)
    setRefetch(prev => prev + 1)
  } catch (error) {
    setLoading(false)
    getNotification('error', error.response.data.error)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}
export { handleApprovalChange, handleDelete, handleGet }

