import React, { Component } from 'react'
import { logout } from 'actions/auth'

import { connect } from 'react-redux'
import { Col, Container, Nav, Row } from 'reactstrap'
import { Link, NavLink, Redirect, Switch } from 'react-router-dom'
import AlertCollapse from './Pages/AlertList';

class AlertsComponent extends Component {

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
    } 
  }


  componentWillUnmount() {
    // document.body.style.paddingTop = "";
    // document.body.classList.remove('main');
    // document.body.classList.remove('ht0');
  }

  render() {
    const { firstName, lastName } = this.props

    return (
      <>
          <AlertCollapse/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  auth: state.auth,
  loggedIn: state.auth.loggedIn,
})

const mapDispatchToProps = {
  logoutHandler: logout
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsComponent)
