import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Spinner } from 'shared-components/'
import { handleAdd, handleDelete, handleEdit, handleGet } from './utils'
import _ from 'lodash'
const TYPE = 'CASH'
const ITEMS_PER_PAGE = 20

const EditButton = ({ handleEdit }) => {
  return (
    <div className="d-flex justify-content-center">
      <button className="btn btn-primary btn-sm" onClick={handleEdit}>
        Edit
      </button>
    </div>
  )
}
const DeleteButton = ({ handleDelete }) => {
  return (
    <div className="d-flex justify-content-center">
      <button className="btn btn-danger btn-sm" onClick={handleDelete}>
        Delete
      </button>
    </div>
  )
}

const Cash = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [refetch, setRefetch] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  // Function to fetch data
  const fetchData = async () => {
    const fetchedData = await handleGet()
    if (fetchedData) {
      setData(fetchedData)
      setTotalPages(Math.ceil(fetchedData.length / ITEMS_PER_PAGE))
    } else {
      console.log('No data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [refetch])
  const [search, setSearch] = useState('')
  const debouncedSearch = _.debounce(val => {
    setSearch(val)
  }, 100)
  const handlePageChange = newPage => {
    setCurrentPage(newPage)
  }
  const filteredData = data
    .filter(item => item.ticker.includes(search.toUpperCase()))
    .sort((a, b) => a.ticker.localeCompare(b.ticker))

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / ITEMS_PER_PAGE))
  }, [filteredData])

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const paginatedData =
    search !== ''
      ? filteredData
      : filteredData.slice(startIndex, startIndex + ITEMS_PER_PAGE)
  // watch paginatedData
  return (
    <div style={{ padding: 40 }}>
      <Spinner isFetching={isLoading} />
      <div className="content-box">
        {/* add button */}
        <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 10
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={e => debouncedSearch(e.target.value)}
            style={{
              width: 200,
              padding: 5,
              margin: 5
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            padding: 10
          }}
        >
          <div
            className="text-left mb-2 mb-sm-0 d-flex align-items-center"
            style={{
              display: 'flex',
              justifyContent: 'start',
              gap: 5,
              marginLeft: 10
            }}
          >
            <i
              className="fa fa-circle"
              style={{
                color: 'green',
                fontSize: 10
              }}
            ></i>
            is available
          </div>
          <div
            className="text-left mb-2 mb-sm-0 d-flex align-items-center"
            style={{
              display: 'flex',
              justifyContent: 'start',
              gap: 5,
              marginLeft: 10
            }}
          >
            <i
              className="fa fa-circle"
              style={{
                color: 'red',
                fontSize: 10
              }}
            ></i>
            not available
          </div>
        </div>
        <button
          className="btn btn-primary"
          style={{
            margin: 15
          }}
          onClick={async () => {
            const ticker = window.prompt('Add Ticker')
            if (!ticker) return
            await handleAdd({
              setLoading,
              dispatch,
              payload: {
                type: TYPE,
                ticker: ticker.toUpperCase(),
                user_id: window.localStorage.getItem('user_id')
              },
              setRefetch
            })
          }}
        >
          Add
        </button>
        <div className="section-detail" style={{ minHeight: '1121px' }}>
          <div className="row relationship-row d-flex justify-content-between px-3">
            <div className="col-1 text-left mb-2 mb-sm-0">
              <label>Ticker</label>
            </div>
            <div className="col-2 text-left mb-2 mb-sm-0">
              <label>Type</label>
            </div>
            <div className="col-2 text-left mb-2 mb-sm-0">
              <label>Action</label>
            </div>
          </div>
          <div id="scrollableDiv">
            {paginatedData.length > 0 &&
              paginatedData.map((item, idx) => {
                return (
                  <CashRow
                    key={idx}
                    ticker={item.ticker}
                    type={item.type}
                    isAvailable={item.isAvailableInEtfMfSecurityMaster}
                    handleEdit={() => {
                      handleEdit({
                        id: item.id,
                        setLoading,
                        dispatch,
                        setRefetch
                      })
                    }}
                    handleDelete={() => {
                      handleDelete({
                        id: item.id,
                        setLoading,
                        dispatch,
                        setRefetch
                      })
                    }}
                  />
                )
              })}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  )
}

const CashRow = ({ ticker, type, handleEdit, handleDelete, isAvailable }) => {
  return (
    <div className="row relationship-row d-flex justify-content-between px-3 border-0">
      <div
        className="col-1 text-left mb-2 mb-sm-0 d-flex align-items-center"
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: 5
        }}
      >
        {ticker}
        <i
          className="fa fa-circle"
          style={{
            color: isAvailable ? 'green' : 'red',
            fontSize: 10
          }}
        ></i>
      </div>
      <div className="col-2 text-left mb-2 mb-sm-0 d-flex justify-content-start align-items-center">
        {type}
      </div>
      <div
        className="col-2 text-left mb-2 mb-sm-0 "
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: 10
        }}
      >
        <EditButton handleEdit={handleEdit} />
        <DeleteButton handleDelete={handleDelete} />
      </div>
    </div>
  )
}
const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const pages = [...Array(totalPages).keys()].map(num => num + 1)
  return (
    <div
      className="pagination"
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 10
      }}
    >
      {pages.map(page => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`btn ${
            currentPage === page ? 'btn-primary' : 'btn-light'
          }`}
        >
          {page}
        </button>
      ))}
    </div>
  )
}

export default Cash
