import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { auth, failedAuth } from 'actions/auth'
import { createLoadingSelector } from 'selectors/loading-selector'
import LoginForm from './LoginForm'

import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { useOktaContext } from 'features/okta'
import { oktaErrorMappers } from 'features/okta/helpers/constants'

const hasErrorInTransaction = transaction => {
  return transaction.messages &&
    transaction.messages.find(i => i && i.class === 'ERROR')
    ? true
    : false
}

const extractErrorFromTransaction = transaction => {
  if (hasErrorInTransaction(transaction)) {
    const messages =
      transaction.messages &&
      transaction.messages.find(i => i && i.class === 'ERROR')
    const message =
      oktaErrorMappers[
        messages && messages.i18n && messages.i18n.key ? messages.i18n.key : ''
      ]
    return message || 'Error occured while logging in'
  }
  return undefined
}

const magnifiLogo =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'
function Login(props) {
  const { history, loggedIn, failedAuth } = props
  const { oktaAuth, handleTransaction } = useOktaContext()

  const authSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email')
      .required('Email is a required field'),
    password: Yup.string().required('Password is a required field')
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      const transaction = await oktaAuth.idx.authenticate({
        username: values.email,
        password: values.password
      })
      if (transaction.status === 'SUCCESS') {
        handleTransaction(transaction)
      }
      const errorMessage = extractErrorFromTransaction(transaction)
      oktaAuth.transactionManager.clear({
        clearIdxResponse: true,
        clearSharedStorage: true
      })
      failedAuth({ error: errorMessage })
      setSubmitting(false)
    } catch (error) {
      oktaAuth.transactionManager.clear({
        clearIdxResponse: true,
        clearSharedStorage: true
      })
      setSubmitting(false)
    }
  }

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    if (loggedIn) {
      history.push('/accounts')
    }
  }, [loggedIn])

  return (
    <>
      <Row className="magnifi-auth-page-wrapper">
        <Col md="6" lg="6" className="left-side-panel-container-login">
          <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
            <img src={magnifiLogo} height={70} alt="magnifi" />
          </a>
        </Col>
        <Col md="6" lg="6" className="right-side-panel-container">
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleSubmit}
            validationSchema={authSchema}
            render={formProps => (
              <LoginForm formProps={formProps} error={props.error} />
            )}
          />
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = ({ auth, loading, router }) => ({
  location: router.location,
  loggedIn: auth.loggedIn,
  user: auth.user,
  error: auth.error,
  authFailed: auth.authFailed,
  authSuccess: auth.authSuccess,
  isFetching: createLoadingSelector(['AUTH'])({ loading })
})

const mapDispatchToProps = {
  auth,
  failedAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
