import React, { useEffect, useRef, useState } from 'react'
import { OktaContext, oktaAuth as constOktaAuth } from '.'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {} from '@okta/okta-auth-js'
import { auth } from 'actions/auth'

function OktaProvider({ children, ...props }) {
  const oktaAuth = useRef(constOktaAuth)
  const [authState, setAuthState] = useState(null)

  useEffect(() => {
    if (oktaAuth.current) {
      oktaAuth.current.authStateManager.subscribe(setAuthState)
      return () => {
        oktaAuth.current.authStateManager.unsubscribe(setAuthState)
      }
    }
  }, [oktaAuth.current])

  const logout = () => {
    localStorage.clear()
    sessionStorage.clear()
    oktaAuth.current.signOut()
  }

  const handleTransaction = async transaction => {
    const { tokens } = transaction
    props.auth({
      access_token: tokens && tokens.accessToken.accessToken,
      email:
        tokens &&
        tokens.idToken &&
        tokens.idToken.claims &&
        tokens.idToken.claims.email
    })
  }

  const isLoginRedirect = () => {
    return oktaAuth.current ? oktaAuth.current.token.isLoginRedirect() : false
  }

  const parseTokenFromUrl = async () => {
    if (!oktaAuth.current) return
    const { tokens } = await oktaAuth.current.token.parseFromUrl()
    props.auth({
      access_token: tokens && tokens.accessToken.accessToken,
      email:
        tokens &&
        tokens.idToken &&
        tokens.idToken.claims &&
        tokens.idToken.claims.email
    })
  }

  return (
    <OktaContext.Provider
      value={{
        oktaAuth: oktaAuth.current,
        authState,
        logout,
        handleTransaction,
        isLoginRedirect,
        parseTokenFromUrl
      }}
    >
      {children}
    </OktaContext.Provider>
  )
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = {
  auth
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OktaProvider))
