import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Spinner } from 'shared-components/'
import { Pagination } from 'antd'
import moment from 'moment';
import { getListSponsers } from 'actions/sponsers'
import { get } from 'lodash';

class SponsersListing extends Component {

  constructor(props) {
    super(props);
    this.maxUrlLength = 30;
    this.state = {
      trades: [],
      isFetching: false,
      fetchError: false,
      activePage: 1
    }
  }

  componentDidMount = () => {
    this.props.getListSponsers({ page: this.state.activePage });
  }

  getBack = () => {
    this.props.history.push('/trades');
  }

  getValue = (value) => {
    if ( value ) {
      if (value.toString().length > this.maxUrlLength) {
        return value.toString().substring(0, this.maxUrlLength) + '...'
      } else {
        return value
      }
    } else {
      return "-"
    }
  }

  loadFunc = (page) => {
    const { alerts, currentPage } = this.props;
    if (currentPage < alerts.total_pages) {
      this.props.getListSponsers({ page: page });
    }
  }

  paginate = (page) => {
    this.setState({ activePage: page })
    this.props.getListSponsers({ page: page })
  }

  render() {
    const { alerts, isFetching, currentPage, error } = this.props;
    const { getValue } = this;
    const alertsResultHaveData = get(alerts, 'result') && get(alerts, 'result').length;
    let hasMoreDataCondition = false;
    if (alertsResultHaveData) {
      hasMoreDataCondition = currentPage < alerts.total_pages;
    }

    return (
      <div className="container-wrapper homeWrapper">

        <Spinner isFetching={isFetching} />
        <h2>Sponsers</h2>

        <div className="content-box">
          <div className="section-detail">
            <div className="row relationship-row">
              <div className="col-1 text-left mb-2 mb-sm-0">
                <label>#</label>
              </div>
              <div className="col-1 text-left mb-2 mb-sm-0">
                <label>Ticker</label>
              </div>              
              <div className="col-2 text-left mb-2 mb-sm-0">
                <label>Channel</label>
              </div>
              <div className="col-2 text-left mb-2 mb-sm-0">
                <label>Text</label>
              </div>
              <div className="col-2 text-left mb-2 mb-sm-0">
                <label>Node</label>
              </div>                            
              <div className="col-2 text-left mb-2 mb-sm-0">
                <label>Sponser Link</label>
              </div>
              <div className="col-2 text-left mb-2 mb-sm-0">
                <label>Media Url</label>
              </div>              
            </div>
            <div
              id="scrollableDiv"
            >
              {error ? <div className="w-100 text-center p-2">
                <span>{error}</span>
              </div> : null}
              {alertsResultHaveData ? alerts.result.map((obj, idx) => {
                let className = alerts.length === 1 ? 'row relationship-row border-0' : 'row relationship-row border-1';

                return (
                  <div key={idx} className={className}>
                    <div className="col-1 text-left mb-2 mb-sm-0">
                      <p>{getValue(obj.user_id)}</p>
                    </div>
                    <div className="col-1 text-left mb-2 mb-sm-0">
                      <p>{getValue(obj.ticker)}</p>
                    </div>                    
                    <div className="col-2 text-left mb-2 mb-sm-0" style={{ wordBreak: "break-all" }}>
                      <p>{getValue(obj.channel_name)}</p>
                    </div>
                    <div className="col-2 text-left mb-2 mb-sm-0" style={{ wordBreak: "break-all" }}>
                      <p>{getValue(obj.text)}</p>
                    </div>
                    <div className="col-2 text-left mb-2 mb-sm-0" style={{ wordBreak: "break-all" }}>
                      <p>{getValue(obj.node)}</p>
                    </div>                                        
                    <div className="col-2 text-left mb-2 mb-sm-0" style={{ wordBreak: "break-all" }}>
                      <a title={getValue(obj.link)}>
                        {getValue(obj.link)}
                      </a>                      
                    </div>
                    <div className="col-2 text-left mb-2 mb-sm-0" style={{ wordBreak: "break-all" }}>
                      <a title={getValue(obj.media_url)}>
                        {getValue(obj.media_url)}
                      </a>
                    </div>
                  </div>
                )
              })
                : null}
            </div>
          </div>
          <div style={{ padding: '20px 10px' }}>
            <Pagination
              showSizeChanger={false}
              defaultPageSize={20}
              current={this.state.activePage}
              total={alerts && alerts.total_pages ? (alerts.total_pages * 20) : 0}
              onChange={this.paginate}
            />
          </div>
        </div>

      </div>

    )
  }
}

function mapStateToProps(state) {
  let returnState = {};

  returnState.isFetching = state.sponsers.alertsData.isLoading;
  returnState.loggedIn = state.auth.loggedIn;
  returnState.alerts = state.sponsers.alertsData.data;
  returnState.currentPage = state.alerts.alertsData.page;
  returnState.error = state.sponsers.alertsData.error;

  return returnState;
}


const mapDispatchToProps = {
  getListSponsers
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SponsersListing))
