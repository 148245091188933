import { takeEvery, fork, call, put, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import get from 'lodash/get';
import ActionTypes from 'action-types';
import * as actions from 'actions/account';
import * as AccountApi from 'apis/account';
import { openNotificationWithIcon } from 'layouts/utils';

function* fetchAllAccounts(action) {
  const { payload } = action
  try {
    if (payload) {
      yield put(actions.tradingAccountListFetch({
        accounts: [],
        allStatus: [],
        accountsCount: 0,
      }))
      const response = yield call(AccountApi.fetchAllAccounts, payload)
      // yield put(actions.tradingAccountListSuccess(response));
      if (response.status === 200 && response.data && response.data.apex_accounts) {
        yield put(actions.tradingAccountListSuccess({
          accounts: response.data.apex_accounts,
          allStatus: response.data.status,
          accountsCount: response.data.total_pages,
        }))
      } else {
        yield put(actions.tradingAccountListSuccess({
          accounts: [],
          allStatus: [],
          accountsCount: 0,
        }))
      }
    } else {
      throw '-- Payload Missing --'
    }
  } catch (error) {
    console.log(error)
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.tradingAccountListFailed(errorDetails))
  }
}

function* fetchAccountData(action) {
  const { payload } = action
  try {
    const response = yield call(AccountApi.fetchAccountData, payload)
    if (response.status === 200) {
      yield put(actions.tradingAccountDetailSuccess(response));
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.tradingAccountDetailFailed(errorDetails))
  }
}
function* updateUserRestrictedStatus(action) {
  const { payload, callback } = action
  try {
    const response = yield call(AccountApi.updateRestrictedStatus, payload)
    if (response.status === 200) {
      yield put(actions.userRestrictRequestSuccess(response));
    }
    if (callback && typeof callback == 'function') {
      callback(response)
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.userRestrictRequestFailed(errorDetails))
  }
}

// function* fetchAccountStatus(action) {
//   const { payload } = action

//   try {
//     const response = yield call(AccountApi.fetchAccountStatus, payload)
//     if (response.status === 200) {
//       yield put(actions.successfulFetchAccountStatus(response));
//     }
//   } catch (error) {
//     const errorDetails = get(error, 'response.data', error.stack)
//     yield put(actions.failedFetchAccountStatus(errorDetails))
//   }
// }

// function* updateAccountStatus(action) {
//   const { payload } = action

//   try {
//     const response = yield call(AccountApi.updateAccountStatus, payload)
//     if (response.status === 200) {
//       yield put(actions.successfulUpdateAccountStatus(response));
//     }
//   } catch (error) {
//     const errorDetails = get(error, 'response.data', error.stack)
//     yield put(actions.failedUpdateAccountStatus(errorDetails))
//   }
// }

function* cancelAchRelationship(action) {
  const { payload } = action

  try {
    const response = yield call(AccountApi.cancelAchRelation, payload)
    if (response.status === 200) {
      yield put(actions.cancelACHSuccess(payload.id));
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.cancelACHFailed(errorDetails));
  }
}

// function* getUsersAccountsDetails(action) {
//   const { payload } = action
//   try {
//     const response = yield call(RegisterApi.getUsersAccountDetails, payload)
//     if (response.status === 200) {
//       yield put(response);
//     }
//   } catch (error) {
//     yield put(
//       //actions.linkTokenError(error.response && error.response.data)
//     )
//   }
// }
//
// function* createUserAccount(action) {
//   const { payload } = action
//   try {
//     const response = yield call(RegisterApi.createUserAccount, payload)
//     if (response.status === 200) {
//       yield put(response);
//     }
//   } catch (error) {
//     yield put(
//       //actions.linkTokenError(error.response && error.response.data)
//     )
//   }
// }

function* closeAccount(action) {
  try {
    if (action.payload) {
      const { callback, payload } = action;
      if (payload.account_id) {
        yield put(actions.closeAccountFetch());
        const [response] = yield all([
          call(AccountApi.closeAccount, payload)
        ]);
        if (response) {
          yield call(delay, 600);
          openNotificationWithIcon({
            type: 'success',
            message: 'Success',
            className: 'api-response-notification-class',
            description: 'Request Sent Successfully.',
          });
          yield put(actions.closeAccountSuccess({
            closeAccountStatus: 'SENT',
          }));
          if (callback && typeof callback === 'function') {
            callback()
          }
        } else {
          throw '-- api failed to respond --'
        }
      } else {
        throw '-- account ID is missing --'
      }
    } else {
      throw '-- payload is missing --'
    }
  } catch (error) {
    console.log(error)
    yield call(delay, 600);
    openNotificationWithIcon({
      type: 'error',
      message: 'Failed to process.',
      className: 'api-response-notification-class',
      description: 'Check API Response.',
    });
    yield put(
      actions.closeAccountSuccess({
        pageCrash: 'Failed to process. Check API Response.',
      })
    )
  }
}

function* accountSaga() {
  yield all([
    fork(takeEvery, ActionTypes.TRADING_ACCOUNT_LIST_REQUEST, fetchAllAccounts),
    fork(takeEvery, ActionTypes.TRADING_ACCOUNT_DETAIL_REQUEST, fetchAccountData),
    fork(takeEvery, ActionTypes.CANCEL_ACH_REQUEST, cancelAchRelationship),
    fork(takeEvery, ActionTypes.CLOSE_ACCOUNT_REQUEST, closeAccount),
    fork(takeEvery, ActionTypes.USER_RESTRICT_REQUEST, updateUserRestrictedStatus)
  ])
}

export default accountSaga
