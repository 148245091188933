import React, { useState } from 'react'
import ConfirmModal from '../../Elements/Confirm'

export let RequestTab=({data,onClick}) =>{

  const [modalState, updateModalState] = useState([{
    modalHeading    : 'Are you sure?',
    modalBtn1       : 'Yes',
    modalBtn2       : 'No',
    showModal       : false,
    classType       : '',
    action          : '',
    id              : ''
  }])

  let [comment, setComment] = useState('')


  let closeAccountData = [];
  // {
  //   id:'',
  //   status:'--'
  // }

  console.log('data --> ', data)

  data.length && data.forEach(request=>{
    if(request.request_type ==='CLOSE_ACCOUNT' || 'TRANSFER_ACCOUNT'){
      closeAccountData.push ({
        id: request.id,
        status: request.status,
        request_type: request.request_type,
        response_data: request.response_data,
        request_payload: request.request_payload,
      })
    }
  })

  let doAction = (action, id)=>{
    let payload = {
      comment: comment,
      user_request_id:id,
      action:action==='approve'?'APPROVED':'REJECTED'
    }

    onClick(payload)
  }

  let showConfirmation = () => {
    updateModalState({
      showModal       : true
    })
  }

  let hideModal = () => {
    modalState[0].showModal = false
    modalState[0].action    = ''
    modalState[0].id        = ''
    updateModalState([...modalState])
  }

  let showModal = (action, id) => {
    modalState[0].showModal = true
    modalState[0].action    = action
    modalState[0].id        = id
    modalState[0].classType = (action === 'approve') ? 'approved' : 'rejected'
    modalState[0].placeholder = 'Please enter a reason'
    updateModalState([...modalState])
    setComment('')
  }

  let handleModalAction = () => {
    modalState[0].showModal = false
    updateModalState([...modalState])
    doAction(modalState[0].action, modalState[0].id);
  }

  let _parseAndShowRequested = (request_payload) => {
    let arr = [];
    let reqs = null;
    try{
      reqs = JSON.parse(request_payload);
    }catch(e){
      console.log(e);
    }

    // console.log('reqs ---> ', reqs);
    if(reqs){
      arr.push(<div><b>Ref Id: </b><span>{reqs.contra_account_number}</span></div>);
      arr.push(<div><b>Apex account id: </b><span>{reqs.apex_account_id}</span></div>);
      arr.push(<div><b>Transfer Type: </b><span>{reqs.transfer_type}</span></div>);
      arr.push(<div><b>Participant Name: </b><span>{reqs.participant_name}</span></div>);
      arr.push(<div><b>Participant Number: </b><span>{reqs.participant_number}</span></div>);
      if(reqs.transfer_type === 'PARTIAL'){
        arr.push(<div><b>Cash: </b><span>{reqs.cash}</span></div>);
        arr.push(<div><b>Tickers: </b><span>{<table border="1"><tr><th>ticker</th><th>shares</th></tr>
              {reqs.tickers && reqs.tickers.map(tick => <tr><td>{tick.ticker}</td><td>{tick.shares}</td></tr>)}
            </table>}
            </span></div>);
      }
    }
    return arr;
  }

  let handleChange = (event) => {
    const target = event.target;
    const value  = target.value;

    setComment(value)
  }

  return closeAccountData.map((el,id) => {
    if (el.status === 'REQUESTED') {
      console.log(el.status, el.response_data)
    }
    return (
      <div key={id} className="row mb-2 pb-1 request-tab">
        { el.request_type == 'CLOSE_ACCOUNT' ?
          <div className="col-sm-8 col-12">Closing an account</div> :
          <div className="col-sm-8 col-12">
          <p>Transfer account:</p>
          {el.request_payload && _parseAndShowRequested(el.request_payload)}
        </div>}
        <div className="col-sm-4 col-12 text-left text-sm-right font-weight-bold">
          <span>{el.status}</span>
          {(el.status === 'REQUESTED' && !el.response_data) && (
            <div className="d-block d-sm-flex action-container">
              <a onClick={()=>showModal('approve', el.id)} className="line-btn green-btn text-uppercase mb-2 mb-sm-1 w-100 mx-1"><i
                className="fa fa-check" /></a>
              <a onClick={()=>showModal('reject', el.id)} className="line-btn red-btn text-uppercase mb-2 mb-sm-0 w-100 mx-1"><i
                className="fa fa-times" /></a>
            </div>
          )}
          {(el.request_type === 'TRANSFER_ACCOUNT') && (
            <div style={{position: 'relative'}}>
              <p style={{fontWeight: 'normal'}}>{REQUESTS[el.status]}</p>
              {['REVIEW', 'REVIEW_ADJUST_DELIVERER', 'REVIEW_ERROR' , 'REVIEW_ACCELERATE','REVIEW_ADJUST_RECEIVER_ACCELERATE'].includes(el.status) && <div className="d-block d-sm-flex action-container" style={{right: -100}}>
                <a className="line-btn green-btn text-uppercase mb-2 mb-sm-1 w-100 mx-1"><i
                  className="fa fa-check" /></a>
                <a className="line-btn red-btn text-uppercase mb-2 mb-sm-0 w-100 mx-1"><i
                  className="fa fa-times" /></a>
              </div>}
            </div>
          )}
        </div>
        <>{modalState[0].showModal && <ConfirmModal heading={modalState[0].modalHeading} type={modalState[0].classType} btn1={modalState[0].modalBtn1} btn2={modalState[0].modalBtn2} closeModal={() => hideModal()} handleModalAction={() => handleModalAction()} userInput={true} placeholder={modalState[0].placeholder} onChange={(e) => handleChange(e)} userInputArea={comment}/>}</>
      </div>
    )
  });
}

const REQUESTS = {
  UNDEFINED: 'The internal state for transfer requests received via the API prior to NSCC assignment of the ACAT control number',
  INITIATE_REQUEST: 'New incoming transfer submission',
  REQUEST: 'Transfer submitted to NSCC successfully',
  REQUEST_ADJUST: 'Previously rejected ACAT has been resubmitted by the contra party firm',
  REQUEST_ADJUST_PAST: "The delivering firm did not respond to receiving firm's request in the allotted time",
  REQUEST_PAST: "The delivering firm did not respond to receiving firm's request in the allotted time",
  REQUEST_REJECT: '"Soft" reject by the DELIVERING firm that can be amended within 24 hours',
  REVIEW_ADJUST_DELIVERER: 'Both the receiving firm and delivering firm review the assets expected to transfer',
  REVIEW_ERROR: 'The delivering firm has an error on asset input',
  REVIEW_ACCELERATE: 'The receiving firm accelerated the transfer, reducing settlement by one day',
  REVIEW_ADJUST_RECEIVER_ACCELERATE: 'The receiving firm accelerated the transfer, reducing settlement by one day',
  SETTLE_PREP: 'The day before settlement',
  SETTLE_CLOSE: 'The transfer settled and assets were transferred to the account.',
  CLOSE_PURGE: 'Delivering firm did not respond to the request within the allotted time',
  REQUEST_500: 'Internal issues with the server',
  PARTIAL_REQUEST: 'Partial Transfer submitted to NSCC successfully',
  MEMO_PURGE_PARTIAL_TRANSFER_REQUEST_RECEIVER: 'Delivering firm did not respond to the request within the allotted time',
  REJECT: 'There was an issue with the transfer, processing was stopped',
  TRANSFER_COMPLETE: 'The transfer settled and assets were transferred. Client can now view account and see the transferred assets',
  ERROR: 'Issue with the transfer at NSCC',
  SYSTEM_PURGE: 'Assigned to transfers once NSCC notifies us the ACAT will be removed from the system'
}
// class Popup extends Component {
//
//   constructor(props) {
//     super(props)
//   }
//
//   state = {
//     value: '',
//     loader:false,
//     isMoneyAdded:false,
//     error:false,
//     response:'',
//   };
//
//   onChange(e) {
//     this.setState({value: e.target.value})
//   }
//
//   onOkClick = ()=>{
//     // this.props.callBack ?this.props.callBack(): this.props.toggleAddMoneyPopup()
//   }
//
//   // onConfirm = async ()=>{
//   //   this.setState({
//   //     loader:true
//   //   })
//   //   try {
//   //     let payload = {
//   //       data:{
//   //         amount: isWithdrawal?(this.state.value*-1):this.state.value,
//   //         memo:moment().toISOString()
//   //       }
//   //     }
//   //     let result = await addApexMoney(payload)
//   //     if(result.data.status==="Requested"){
//   //       this.setState({
//   //         loader:false,
//   //         isMoneyAdded : true,
//   //         response:'pending'
//   //       })
//   //       this.props.fetchProfile()
//   //     }
//   //     if(result.data.status==="Completed"){
//   //       this.setState({
//   //         loader:false,
//   //         isMoneyAdded : true,
//   //         response:'success'
//   //       })
//   //       this.props.fetchProfile()
//   //     }
//   //   }catch (e) {
//   //     console.log(e)
//   //     this.setState({
//   //       loader:false,
//   //       isMoneyAdded : true,
//   //       error:true,
//   //       response:'error'
//   //     })
//   //   }
//   // }
//
//   render() {
//     // const {
//     //   showAddMoneyPopup,
//     // } = this.props;
//
//     const { loader,isMoneyAdded,response } = this.state
//
//     return (
//       <Fragment>
//         <Modal
//           keyboard
//           centered
//           backdrop
//           unmountOnClose={true}
//           isOpen={true}
//           className="add-money-popup-sfm"
//           backdropClassName="premium-feature-process-modal-backdrop"
//           // toggle={!loader && !isMoneyAdded && this.onCloseIcon}
//         >
//           <ModalBody className="ssf-modal__body">
//             <div className={style.requestPopup}>
//
//               {/*{!loader && !isMoneyAdded &&*/}
//               // <i onClick={this.props.toggleAddMoneyPopup} className={`fal fa-times ${style.close}`} />}
//
//               {!loader && !isMoneyAdded && <div className={style.beforeAdd}>
//                 <p className={style.header}>Approve Trading Account Closure</p>
//                 <button className={style.confirm} onClick={this.onConfirm}> confirm</button>
//                 <button className={style.confirm} onClick={this.onConfirm}> cancel</button>
//               </div>}
//
//               {loader && <Spinner style={{ width: '3rem', height: '3rem', padding: `30px` }} color="dark" />}
//
//
//
//             </div>
//           </ModalBody>
//         </Modal>
//       </Fragment>
//     )
//   }
// }
