import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects'
import qs from 'query-string'
import get from 'lodash/get'
import { push } from 'connected-react-router'

import ActionTypes from 'action-types'
import * as actions from 'actions/trades'

import * as TradesApi from 'apis/trades'
import * as testDriveApis from 'apis/test-drive'
import * as AuthUtils from '../common/auth-utils'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { log } from 'util'
import { addEventToAnalytics } from 'Utils'

function* fetchAllTrades(action) {
  const { payload } = action

  try {
    const response = yield call(TradesApi.fetchAllTrades, payload)
    yield put(actions.successfulFetchTrades(response))
  } catch (error) {
    console.log(error, 'dasdasdasdasd')
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedFetchTrades(errorDetails))
  }
}

function* fetchCustomTrades(action) {
  const { payload } = action

  try {
    const response = yield call(TradesApi.fetchCustomTrades, payload)
    yield put(actions.successfulFetchCustomTrades(response))
  } catch (error) {
    console.log(error, 'dasdasdasdasd')
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedCustomFetchTrades(errorDetails))
  }
}

function* TradesSaga() {
  yield all([
    fork(takeEvery, ActionTypes.FETCH_ALL_TRADES, fetchAllTrades),
    fork(takeEvery, ActionTypes.FETCH_CUSTOM_TRADES, fetchCustomTrades)
  ])
}

export default TradesSaga
