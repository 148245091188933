import ActionTypes from '../action-types'

const initialState = {
  alertsData: {
    data: null,
    isLoading: false,
    error: null,
    page: 0,
    isLoadingMore: false
  },
  updateData: {
    data: null,
    isLoading: false,
    error: null
  }
}

export function sponsers(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case ActionTypes.GET_SPONSERS_LOADING: {
      const { alertsData } = state;
      alertsData.isLoading = payload;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.GET_SPONSERS_LOADING_MORE: {
      const { alertsData } = state;
      alertsData.isLoadingMore = payload;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.GET_SPONSERS_SUCCESS: {
      const { alertsData } = state;
      alertsData.page = payload.page;
      alertsData.data = payload;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.GET_SPONSERS_FAILURE: {
      const { alertsData } = state;
      alertsData.error = payload;
      return {
        ...state,
        alertsData,
      }
    }
    default:
      return state
  }
}
