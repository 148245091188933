import config from './helpers/config'
import React from 'react'

export const oktaAuth = window.OktaAuth ? new window.OktaAuth(config) : null

export const OktaContext = React.createContext(null)

export const useOktaContext = () => React.useContext(OktaContext)

export { default as OktaProvider } from './provider'
