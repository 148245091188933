import zipObject from 'lodash/zipObject'

const actions = [
  'GET_SPONSERS',
  'GET_SPONSERS_SUCCESS',
  'GET_SPONSERS_FAILURE',
  'GET_SPONSERS_LOADING',
  'GET_SPONSERS_LOADING_MORE',
]

export default zipObject(actions, actions)
