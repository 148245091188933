import { Formik } from 'formik'
import { Row, Col, Radio, Button, Input } from 'antd'
import React from 'react'
import { Wrapper } from './styles'
import { Divider } from 'antd'
// form item with two input fields and submit button with validation schema
export const FormItemOldEmailToNewEmail = ({
  label,
  handleSubmit,
  validationSchema,
  isBorderRequired = false
}) => {
  return (
    <Wrapper>
      <h4 className="label">{label}</h4>
      <Row>
        <Formik validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, handleChange, errors, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              <Col span={8}>
                <Input
                  placeholder={'Old Email'}
                  type={'email'}
                  name={'old_user_email'}
                  onChange={handleChange}
                  value={values.old_user_email}
                  className="input__wrapper"
                />
                <p className="error__text">
                  {errors.old_user_email && errors.old_user_email}
                </p>
              </Col>
              <Col span={8}>
                <Input
                  placeholder={'New Email'}
                  type={'email'}
                  name={'new_user_email'}
                  onChange={handleChange}
                  value={values.new_user_email}
                  className="input__wrapper"
                />
                <p className="error__text">
                  {errors.new_user_email && errors.new_user_email}
                </p>
              </Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </Row>
    </Wrapper>
  )
}
export const FormItem = ({
  label,
  handleSubmit,
  isRadio = false,
  validationSchema,
  isBorderRequired = false,
  type = 'email',
  placeHolder = 'Email',
  name = 'email_id',
  isReinitiate
}) => {
  return (
    <Wrapper>
      <h4 className="label">{label}</h4>
      <Row>
        <Formik validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, handleChange, errors, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              {isRadio ? (
                <Col span={8}>
                  <Input
                    placeholder={placeHolder}
                    type={type}
                    name="user_email"
                    onChange={handleChange}
                    value={values.user_email}
                    className="input__wrapper"
                  />
                  <p className="error__text">
                    {isRadio
                      ? errors.user_email && errors.user_email
                      : errors.email && errors.email}
                  </p>
                  <Radio.Group
                    className="radio__group"
                    name="activate_mentor"
                    id="activate_mentor"
                    onChange={handleChange}
                    value={values.activate_mentor}
                  >
                    <Radio value={true}>Activate</Radio>
                    <Radio value={false}>Deactivate</Radio>
                  </Radio.Group>
                  <p className="error__text">
                    {isRadio &&
                      errors.activate_mentor &&
                      errors.activate_mentor}
                  </p>
                </Col>
              ) : isReinitiate ? (
                <>
                  <Col span={4}>
                    <Input
                      placeholder={'Email'}
                      type={'email'}
                      name={'user_email'}
                      onChange={handleChange}
                      value={values.email}
                      className="input__wrapper"
                    />
                    <p className="error__text">
                      {errors.user_email && errors.user_email}
                    </p>
                  </Col>
                  <Col span={4}>
                    <Input
                      placeholder={'User Id'}
                      type="number"
                      name={'user_id'}
                      onChange={handleChange}
                      value={values.email}
                      className="input__wrapper"
                    />
                    <p className="error__text">
                      {errors.user_id && errors.user_id}
                    </p>
                  </Col>
                </>
              ) : (
                <Col span={8}>
                  <Input
                    placeholder={placeHolder}
                    type={type}
                    name={name}
                    onChange={handleChange}
                    value={values.email}
                    className="input__wrapper"
                  />
                  <p className="error__text">
                    {isRadio
                      ? errors.user_email && errors.user_email
                      : errors[`${name}`]}
                  </p>
                </Col>
              )}
              <Col span={3} className="buttonWrapper">
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Col>
            </form>
          )}
        </Formik>
      </Row>
      {isBorderRequired && <Divider />}
    </Wrapper>
  )
}
