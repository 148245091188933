import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import { IntlProvider } from 'react-intl'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'

import App from './App'
import apiMiddleware from './middleware/api-middleware'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import authSaga from './sagas/auth'
import accountSaga from './sagas/account'
import TradesSaga from './sagas/trades'
import AlertsSaga from './sagas/alerts'
import SponsersSaga from './sagas/sponsers'

import rootReducer from './reducers'

import './style/App.scss'
import './style/icomoon/style.css'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const middleware = [logger, sagaMiddleware] //apiMiddleware

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer(history),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
       ...middleware
     )
  )
)

sagaMiddleware.run(authSaga)
sagaMiddleware.run(accountSaga)
sagaMiddleware.run(TradesSaga)
sagaMiddleware.run(SponsersSaga)
sagaMiddleware.run(AlertsSaga)

history.listen(function (location) {
  // console.log('recording ga');
  // window.ga('set', 'page', location.pathname + location.search);
  // window.ga('send', 'pageview');
});

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en">
      <App history={history}/>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

export default store;
