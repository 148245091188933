  import zipObject from 'lodash/zipObject'

const actions = [
  'GET_ALERTS',
  'GET_ALERTS_SUCCESS',
  'GET_ALERTS_FAILURE',
  'GET_ALERTS_LOADING',
  'GET_ALERTS_LOADING_MORE',
]

export default zipObject(actions, actions)
