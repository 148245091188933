import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  Col,
  Container,
  Nav,
  Row,
  Dropdown,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

import { fetchTrades, fetchCustomTrades } from 'actions/trades'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'
import { Button, Pagination, Tooltip } from 'antd'
import moment from 'moment'

class TradesListing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      trades: [],
      tradesCount: 0,
      isFetching: false,
      fetchError: false,
      activePage: 1,
      dropdownOpen: false,
      filters: {
        tradeDate: '',
        createdAt: '',
        accountNumber: '',
        id: '',
        ticker: '',
        action: '',
        sellAll: null,
        option: '',
        status: '',
        vehicle: ''
      },
      showCustomTrades: false
    }
  }

  componentDidMount() {
    this.setState({ isFetching: true })
    this.props.fetchTrades(this.state.activePage)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.trades && this.props.trades !== undefined) {
      if (
        this.props.trades &&
        this.props.trades.data &&
        this.props.trades.data.trade_line_data.length > 0 &&
        this.props.trades !== prevProps.trades
      ) {
        this.setState({
          trades: this.props.trades.data.trade_line_data,
          tradesCount: this.props.trades.data.pages || 0,
          isFetching: false
        })
      }

      if (
        this.props.error !== prevProps.error &&
        (this.props.trades.status !== 200 || this.props.error !== undefined)
      ) {
        this.setState({
          fetchError: true,
          isFetching: false,
          error: this.props.error
        })
      }
    }
  }

  paginate = (page, pageSize) => {
    this.setState({ activePage: page, isFetching: true })
    if (this.state.showCustomTrades) {
      this.props.fetchCustomTrades({ ...this.state.filters, page })
    } else {
      this.props.fetchTrades(page)
    }
  }

  getBack = () => {
    this.props.history.push('/trades')
  }

  toggleFilter = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }
  setTradeDate = value => {
    this.state.filters.tradeDate = value
  }
  setCreatedAt = value => {
    this.state.filters.createdAt = value
  }
  setAccountNo = value => {
    this.state.filters.accountNumber = value
  }
  setTradeId = value => {
    this.state.filters.id = value
  }
  setTicker = value => {
    this.state.filters.ticker = value
  }
  setAction = value => {
    this.state.filters.action = value
  }
  setSellAll = value => {
    this.state.filters.sellAll = value
  }
  setOptions = value => {
    this.state.filters.option = value
  }
  setStatus = value => {
    this.state.filters.status = value
  }

  setVehicle = value => {
    this.state.filters.vehicle = value
  }
  onDropdownSubmit = () => {
    this.toggleFilter()
    this.props.fetchCustomTrades(this.state.filters)
    this.setState({
      trades: this.props.trades.data.trade_line_data
    })
    this.state.showCustomTrades = true
  }

  resetPage = () => {
    this.state.showCustomTrades = false
    this.setState({ isFetching: true })
    this.props.fetchTrades(1)
  }

  render() {
    const defaultPageLimit = 50
    const { user } = this.props
    return (
      <div className="container-wrapper homeWrapper">
        <Spinner isFetching={this.state.isFetching} />

        {/*<a onClick={() => this.getBack()} className="back-button"><i className="fa fa-arrow-left" /> Back</a>*/}
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <Dropdown
            isOpen={this.state.dropdownOpen}
            toggle={() => this.toggleFilter()}
          >
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              aria-expanded={this.state.dropdownOpen}
            >
              <a className="back-button">
                <i className="fa fa-filter" /> Filter
              </a>
            </DropdownToggle>
            <DropdownMenu>
              <div className="basket-filter-menu">
                <div className="menu-row">
                  <span>Create At: </span>
                  <input
                    type="date"
                    onChange={e => this.setCreatedAt(e.target.value)}
                  />
                </div>
                <div className="menu-row">
                  <span>Trade Date: </span>
                  <input
                    type="date"
                    onChange={e => this.setTradeDate(e.target.value)}
                  />
                </div>

                <div className="menu-row">
                  <span>Account Number: </span>
                  <input
                    type="text"
                    onChange={e => this.setAccountNo(e.target.value)}
                  />
                </div>
                <div className="menu-row">
                  <span>Trade ID: </span>
                  <input
                    type="text"
                    onChange={e => this.setTradeId(e.target.value)}
                  />
                </div>
                <div className="menu-row">
                  <span>Ticker: </span>
                  <input
                    type="text"
                    onChange={e => this.setTicker(e.target.value)}
                  />
                </div>
                <div className="menu-row">
                  <span>Action: </span>
                  <select
                    name="action"
                    id="action"
                    onChange={e => this.setAction(e.target.value)}
                  >
                    <option value="-1" selected>
                      Select value
                    </option>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </div>
                <div className="menu-row">
                  <span>Sell All: </span>
                  <select
                    name="seeAll"
                    id="seeAll"
                    onChange={e => this.setSellAll(e.target.value)}
                  >
                    <option value="-1" selected>
                      Select value
                    </option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </div>
                <div className="menu-row">
                  <span>Option: </span>
                  <select
                    name="option"
                    id="option"
                    onChange={e => this.setOptions(e.target.value)}
                  >
                    <option value="-1" selected>
                      Select value
                    </option>
                    <option value="units">Units</option>
                    <option value="values">Values</option>
                  </select>
                </div>
                <div className="menu-row">
                  <span>Status: </span>
                  <select
                    name="status"
                    id="status"
                    onChange={e => this.setStatus(e.target.value)}
                  >
                    <option value="-1" selected>
                      Select value
                    </option>
                    <option value="REJECTED_FOR_EXECUTION">
                      Rejected for Execution
                    </option>
                    <option value="REJECTED">Rejected</option>
                    <option value="NO_SHARES">No Shares</option>
                    <option value="SHARES_BOOKED">Shares Booked</option>
                    <option value="COMPLETE">Complete</option>
                    <option value="CANCEL">Cancel</option>
                    <option value="CANCELLED">Cancelled</option>
                    <option value="SHARES_TO_BOOK">Shares to Book</option>
                    <option value="CANCELED">Canceled</option>
                    <option value="QUEUED">Queued</option>
                  </select>
                </div>
                <div className="menu-row">
                  <span>Vehicle: </span>
                  <select
                    name="vehicle"
                    id="vehicle"
                    onChange={e => this.setVehicle(e.target.value)}
                  >
                    <option value="-1" selected>
                      Select value
                    </option>
                    <option value="etf">ETF</option>
                    <option value="mf">MF</option>
                    <option value="shares">Shares</option>
                    <option value="basket">Basket</option>
                    <option value="etn">ETN</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className="menu-row">
                  <Button
                    type="button"
                    className="dropdown-menu-btn"
                    onClick={this.onDropdownSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          <Button
            type="button"
            className="dropdown-menu-btn-reset"
            onClick={this.resetPage}
          >
            Reset
          </Button>
        </div>
        <div className="table-responsive">
          <div className="custom-table">
            <div className="table-head table-row">
              <div className="table-col text-left col-3">Created At</div>
              <div className="table-col text-left col-3">Trade Date</div>

              <div className="table-col col-2">Account Number</div>

              <div className="table-col col-2">Trade ID</div>

              <div className="table-col col-2">Ticker</div>
              <div className="table-col col-2">Action</div>
              <div className="table-col col-2">Sell All</div>
              <div className="table-col col-2">Option</div>
              <div className="table-col col-2">Quantity</div>

              <div className="table-col col-2">Transaction Price</div>
              <div className="table-col col-2">Status</div>
              <div className="table-col col-2">Vehicle</div>
            </div>
            {this.state.trades &&
              this.state.trades.length > 0 &&
              this.state.trades.map((obj, idx) => {
                return (
                  <div key={idx} className="table-row">
                    <div className="table-col col-2 trade-row">
                      <p>
                        {obj.created_at
                          ? moment(obj.created_at).format('MM/DD/YYYY')
                          : '-'}
                      </p>
                    </div>
                    <div className="table-col col-2 trade-row">
                      <p>
                        {obj.trade_date
                          ? moment(obj.trade_date).format('MM/DD/YYYY')
                          : '-'}
                      </p>
                    </div>

                    <div className="table-col col-2 trade-row">
                      <p>{obj.account_no}</p>
                    </div>

                    <div className="table-col col-2 trade-row">
                      <p>{obj.id}</p>
                    </div>
                    <div className="table-col col-2 trade-row">
                      <p>{obj.ticker}</p>
                    </div>
                    <div className="table-col col-1 trade-row">
                      <p>
                        <span className="hyper-link approved">
                          {obj.order_type}
                        </span>
                      </p>
                    </div>
                    <div className="table-col col-1 trade-row">
                      <p>
                        <span className="hyper-link approved">
                          {obj.sell_all ? 'TRUE' : '-'}
                        </span>
                      </p>
                    </div>
                    <div className="table-col col-2 trade-row">
                      <p>
                        <span className="hyper-link approved">
                          {obj.order_category}
                        </span>
                      </p>
                    </div>
                    <div className="table-col col-2 trade-row">
                      <p>
                        <span className="hyper-link approved">
                          {parseFloat(obj.quantity).toFixed(5)}
                        </span>
                      </p>
                    </div>

                    <div className="table-col col-2 trade-row">
                      <p>
                        <span className="hyper-link approved">
                          {obj.order_price}
                        </span>
                      </p>
                    </div>
                    <div className="table-col col-2 trade-row">
                      <Tooltip title={obj.order_status}>
                        <span className="hyper-link approved">
                          {obj.order_status}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="table-col col-2 trade-row">
                      <p>
                        <span className="hyper-link approved">
                          {obj.security_type}
                        </span>
                      </p>
                    </div>
                  </div>
                )
              })}
            <div style={{ paddingLeft: 10, paddingBottom: 50 }}>
              <Pagination
                showSizeChanger={false}
                defaultPageSize={defaultPageLimit}
                // onShowSizeChange={onShowSizeChange}
                current={this.state.activePage}
                total={this.state.tradesCount * defaultPageLimit}
                onChange={this.paginate}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let returnState = {}

  returnState.isFetching = createLoadingSelector(['ACCOUNT'])({ state })
  returnState.trades =
    state.trades && state.trades.trades ? state.trades.trades : []
  returnState.user = state.auth.user
  returnState.auth = state.auth
  returnState.loggedIn = state.auth.loggedIn
  returnState.error = state.trades.error

  return returnState
}

const mapDispatchToProps = {
  fetchTrades,
  fetchCustomTrades
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TradesListing))
