import { createAPIInstance } from './api'
import qs from 'query-string'
import axios from 'axios'
import config from '../env'

export function fetchAllAccounts(payload) {
  return createAPIInstance()
    .get(
      'user/apex/get_apex_accounts?pages=' +
        payload.page +
        '&status=' +
        payload.status +
        '&search_key=' +
        payload.searchKey +
        '&pending_documents=' +
        payload.pending_documents +
        '&transfer_request=' +
        payload.transfer_request
    )
    .then(response => response)
}

export function fetchAccountData(payload) {
  return createAPIInstance()
    .get('user/apex/get_apex_details_for_account?apex_account_id=' + payload)
    .then(response => response)
}

export function fetchAccountStatus(payload) {
  return createAPIInstance()
    .get('user/apex/account/status/admin?apex_account_id=' + payload)
    .then(response => response)
}

export const fetchRecentTransactions = async accountNo => {
  const { data } = await axios.get(
    `${config.tradeServiceBaseUrl}/v1/admin/user/get_details_for_account?account_no=${accountNo}`,
    {
      headers: {
        Authorization: `${localStorage.getItem('token')}`
      }
    }
  )
  return data
}
export function updateAccountStatus(payload) {
  return createAPIInstance()
    .post('user/apex/account/status/appeal', qs.stringify(payload))
    .then(res => res)
}

export function getApexAccountStatus(id) {
  return createAPIInstance()
    .get(`user/apex/account/status/admin?apex_account_id=${id}`)
    .then(response => response.data)
}

export function saveForReview(payload) {
  return createAPIInstance().post(
    'user/apex/snap/save',
    JSON.stringify(payload),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

export function uploadReviewImage(payload) {
  return createAPIInstance()
    .post('user/apex/snap/images', payload)
    .then(res => res.data.result)
}

export function downloadSnap(payload) {
  return createAPIInstance().get('user/apex/edocs/download', {
    params: payload,
    responseType: 'arraybuffer'
  })
}

export function makeAppeal(payload) {
  return createAPIInstance().post(
    'user/apex/account/status/appeal',
    JSON.stringify(payload),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

export function updateSnapStatus(payload) {
  return createAPIInstance().post(
    'user/apex/snap/update',
    JSON.stringify(payload),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

export function requestAction(payload) {
  return createAPIInstance().post(
    'user/apex/approve/request',
    JSON.stringify(payload),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}
export function updateRestrictedStatus(payload) {
  return createAPIInstance()
    .post(
      `user/apex/toggle/account/restriction/admin`,
      JSON.stringify(payload),
      {
        headers: { 'Content-Type': 'application/json' }
      }
    )
    .then(response => response)
}
// export function getUsersAccountDetails(payload) {
//   return createAPIInstance()
//     .post('user/bank/details', qs.stringify(payload))
//     .then(res => res.data)
// }
//
// export function createUserAccount(payload) {
//   return createAPIInstance()
//     .post('user/account_requests_new', payload, {
//       headers: { 'Content-Type': 'application/json' }
//     })
//     .then(res => res.data)
// }
export function cancelAchRelation(payload) {
  const api = createAPIInstance()
  return api.post('/user/apex/ach_relation/cancel', payload, {
    headers: { 'Content-Type': 'application/json' }
  })
}

export function validateAddress(address) {
  const instance = axios.create({
    baseURL: `https://api.address-validator.net/api/verify?StreetAddress=${address.street}&City=${address.city}&PostalCode=${address.PostalCode}&CountryCode=${address.CountryCode}&Geocoding=true&APIKey=av-1583b5e36c10f33e4f81c2220d2a043e`
  })

  return instance.get()
}

export function updateUserAccountType(flag, userId) {
  return createAPIInstance().post(
    `user/apex/set/test/account/state?user_id=${userId}&flag=${flag}`,
    null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
        Cookie:
          'csrftoken=eTiPiqtPXYAA3bsv2QlHxFmvzwjABxcZn6go5ePnXg8ChXruwc65LbP5qNsrcxTW'
      }
    }
  )
}

export function updateRestrictUserStatus(userId) {
  return createAPIInstance().post(
    `user/restrict-user`,
    { user_id: userId },
    {
      headers: {
        'Content-Type': 'application/json',
        servicetoken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ',
        Cookie:
          'csrftoken=wiAwCAj2pXzjIxE9iHAkdBq5tlR6dgvGE1e7Dr0r6bcd7ML6JSwrdCaTHSRF95gT'
      }
    }
  )
}
export function resubmitRequestToApex(accId) {
  return createAPIInstance(undefined, true).post(
    `user/apex/account/request/resubmit/admin`,
    {
      apex_account_id: accId
    }
  )
}
export function exportAccountList(payload) {
  return createAPIInstance().get('export-account-list', {
    params: payload,
    responseType: 'arraybuffer'
  })
}

// close-account api
export function closeAccount(payload) {
  let userId = payload.account_id
  return createAPIInstance()
    .post(
      `/user/apex/close/account/admin?request_type=CLOSE_ACCOUNT&user_id=${userId}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          servicetoken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'
          // 'Cookie': 'csrftoken=wiAwCAj2pXzjIxE9iHAkdBq5tlR6dgvGE1e7Dr0r6bcd7ML6JSwrdCaTHSRF95gT'
        }
      }
    )
    .then(res => res.data)
}

// toggle isActive Api
export function toggleisActive(id) {
  return createAPIInstance().post(
    `/update_account_status?account_id=${id}`,

    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`,
        Cookie:
          'csrftoken=AkpSvFZnWvwRRc3tVLQx37ckkC7EMm2aQTFvweMl3yNZPr7DxqUFjWUXO1sV2ilz'
      }
    }
  )
}

// export function updateRestrictedStatus(payload) {
//   return createAPIInstance()
//     .post(`user/apex/toggle/account/restriction/admin`, payload, {
//       headers: {
//         'Content-Type': 'application/json',
//         'servicetoken': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ',
//         'Cookie': 'csrftoken=wiAwCAj2pXzjIxE9iHAkdBq5tlR6dgvGE1e7Dr0r6bcd7ML6JSwrdCaTHSRF95gT'
//       }
//     })
// }
