import styled from 'styled-components'

export const PopulateDetailsWrapper = styled.div`
  h4 {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-bottom: 10px;
  }
  .manual-upload {
    padding-left: 10px;
  }
  .ant-btn-primary {
    background: #fff !important;
    color: #1e5f91 !important;
    border: 1px solid #1e5f91;
    border-radius: 5px;
    font-weight: 500;
  }

  .ant-btn-primary:hover {
    background: #1e5f91 !important;
    color: #fff !important;
    font-weight: 500;
  }
`

export const Wrapper = styled.div`
  margin: 30px 10px;
  .form {
    width: 100% !important;
    display: flex !important;
    gap: 20px;
  }
  .label {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-bottom: 10px;
  }
  .error__text {
    font-size: 14px !important;
    margin: 0;
    color: red;
    padding-top: 5px;
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  .ant-btn-primary {
    background: #fff !important;
    color: #1e5f91 !important;
    border: 1px solid #1e5f91;
    border-radius: 5px;
    font-weight: 500;
  }

  .ant-btn-primary:hover {
    background: #1e5f91 !important;
    color: #fff !important;
    font-weight: 500;
  }
  .input__wrapper {
    height: 40px !important;
    border-radius: 8px !important;
  }
  .radio__group {
    margin-top: 20px;
    .ant-radio-checked .ant-radio-inner:after {
      background-color: #1e5f91;
    }
  }
`
