import axios from 'axios'
import { isEmpty } from 'lodash'

import * as AuthUtils from 'common/auth-utils'
import config from '../env'
import { oktaAuth } from 'features/okta'

export const API_ROOT = config.apiBaseUrl

const defaultConfig = {
  baseURL: ''
}

export function getBaseUrl(config) {
  const instanceConfig = Object.assign({}, defaultConfig, config)
  return API_ROOT + instanceConfig.baseURL
}

export function createAPIInstance(config, ignoreContentTypeHeader = false) {
  // Set config defaults when creating the instance

  const authToken = window.localStorage.getItem('token')
  const tokenHeader = isEmpty(authToken)
    ? {}
    : {
        Authorization: `Token ${authToken}`
      }
  const api = axios.create({
    baseURL: getBaseUrl(config),
    headers: ignoreContentTypeHeader
      ? tokenHeader
      : {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...tokenHeader
        }
  })

  api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error && error.response && error.response.status === 401) {
        AuthUtils.deleteAuthToken()
        oktaAuth.signOut()
        return null
      }
      return Promise.reject(error)
    }
  )
  return api
}
