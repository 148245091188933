import React from 'react';

export const routerPages = {
  '/accounts': {
    name: 'Build Portfolio',
    icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
    title: 'Magnifi – Discover a world of investing.',
  },
  '/account/:id': {
    name: 'Build Portfolio',
    icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
    title: 'Magnifi – Discover a world of investing.',
  },

  '/trades': {
    name: 'Build Portfolio',
    icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
    title: 'Magnifi – Discover a world of investing.',
  },

  '/enhancer/exception/403': {
    name: 'Exception 403',
    icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
    title: 'Magnifi – Discover a world of investing.',
  },
  '/enhancer/exception/404': {
    name: 'Exception 404',
    icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
    title: 'Magnifi – Discover a world of investing.',
  },
  '/enhancer/exception/500': {
    name: 'Exception 500',
    icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
    title: 'Magnifi – Discover a world of investing.',
  },
};

export const hideHeaderForRoutes = [

  '/rec-enhancer'
]

export default {
  routerPages,
  hideHeaderForRoutes
}
