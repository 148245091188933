import { logout } from 'actions/auth'
import axios from 'axios'
import * as Yup from 'yup'
import { ApiHeaders } from '../../../DataSet'
import config from '../../../env'
import { getNotification } from '../utils'

export const emailValidation = Yup.object().shape({
  email_id: Yup.string()
    .email('Email must be a valid email')
    .required('Email is a required field')
})

export const personalActivatedValidation = Yup.object().shape({
  user_email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is a required field'),
  activate_mentor: Yup.string().required('One option need to be selected')
})

export const reinitiateValidation = Yup.object().shape({
  user_email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is a required field'),
  user_id: Yup.string().required('user ID is required')
})

export const accountReopenValidation = Yup.object().shape({
  account_no: Yup.string().required('Account number is required')
})
export const unlinkBank = Yup.object().shape({
  user_email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is required')
})

export const mcomValidation = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is required'),
  division_userid: Yup.string().required('Division UserId is required'),
  division: Yup.string().required('Division is required'),
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required')
})

export const oktaValidation = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is required')
})
export const deleteUserValidation = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is required')
})
export const oldToNewEmailValidation = Yup.object().shape({
  old_user_email: Yup.string()
    .email('Email must be a valid email')
    .required('Old Email is required'),
  new_user_email: Yup.string()
    .email('Email must be a valid email')
    .required('New Email is required')
})
export const unregisterUser = async (payload, setLoading, dispatch) => {
  setLoading(true)
  try {
    const {
      data
    } = await axios.post(
      `${config.profileBaseUrl}/profile/unregister-user-manually`,
      payload,
      { headers: ApiHeaders() }
    )

    setLoading(false)
    if (data) {
      getNotification('success', data.status)
    }
  } catch (error) {
    setLoading(false)
    getNotification(
      'error',
      error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
    )
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  }
}

export const clearMail = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const {
      data
    } = await axios.put(
      `${config.apiBaseUrl}/user/apex/clear-account`,
      payload,
      { headers: ApiHeaders() }
    )
    setLoading(false)
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    setLoading(false)
    getNotification(
      'error',
      error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
    )
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  }
}

export const activatePersonal = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const { data } = await axios.put(
      `${config.apiBaseUrl}/user/mentor/access`,
      payload,
      { headers: ApiHeaders() }
    )
    if (data) {
      getNotification('success', data.message)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    getNotification(
      'error',
      error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
    )
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  }
}

export const apexAccountOpen = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const { data } = await axios.post(
      `${config.tradeServiceBaseUrl}/v1/admin/user/apex_account_reopen`,
      payload,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    getNotification('error', error.response.data.error)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}

export const reinitiateAccount = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const { data } = await axios.post(
      `${config.tradeServiceBaseUrl}/v1/admin/user/reinstate`,
      payload,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    getNotification('error', error.response.data.error)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}

export const unlinkBankAccount = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const { data } = await axios.post(
      `${config.tradeServiceBaseUrl}/v1/admin/user/bank_unlink`,
      payload,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    getNotification(
      'error',
      error.response.data.error
        ? error.response.data.error
        : error.response.data.detail
    )
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}

export const mcomAccess = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const { data } = await axios.post(
      `${config.profileBaseUrl}/profile/mc-user-sync-event`,
      payload,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
          servicetoken:
            'eyJhbGciOiJIUzI1NiJ9eyJJc3N1ZXIiOiJFME1DQ3FaZjhFcDQ0aTJwSjhxUndwT1BRcUhIRG44ZTA1Rk0xQjV5VEVJMnZKRmxnUVVyIn0lAP_IEPPt1V663ZZtFIuLKnSFjckZBmOfS2BtOly92U',
          Cookie:
            'csrftoken=wiAwCAj2pXzjIxE9iHAkdBq5tlR6dgvGE1e7Dr0r6bcd7ML6JSwrdCaTHSRF95gT'
        }
      }
    )
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    getNotification('error', error.message)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}
export const deleteUserData = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const { data } = await axios.post(
      `${config.tradeServiceBaseUrl}/v1/admin/user/delete_information`,
      // '{"email": "test@gmail.com"}',
      payload,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification('success', data.message)
    }
  } catch (error) {
    getNotification('error', error.message)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}
export const checkOkta = async (payload, setLoading, dispatch) => {
  try {
    setLoading(true)
    const { data } = await axios.get(
      `${config.goService}/user/fetch-okta-user-info?email=${payload.email}`,
      {
        headers: {
          Authorization: window.localStorage.getItem('token'),
          Cookie:
            'csrftoken=1323Wml5YHw6MKRSDRlAtUWD04kwYgqdsvPMvfkHTODTANOYl8HinLCuzxhXp6F3'
        }
      }
    )
    if (data) {
      getNotification(
        'success',
        data[0] && `Provider Type - ${data[0].credentials.provider.type}`
      )
    }
  } catch (error) {
    getNotification('error', error.message)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}

export const changeOldEmailToNewEmail = async (
  payload,
  setLoading,
  dispatch
) => {
  try {
    setLoading(true)
    const { data } = await axios.post(
      `${config.tradeServiceBaseUrl}/v1/admin/user/email_change`,
      payload,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }
    )
    if (data) {
      getNotification(
        'success',
        data[0] && `Provider Type - ${data[0].credentials.provider.type}`
      )
    }
  } catch (error) {
    getNotification('error', error.response.data.error)
    if (error.response && error.response.status === 401) {
      if (error.response.data.detail === 'Invalid token.')
        await dispatch(logout())
    }
  } finally {
    setLoading(false)
  }
}
