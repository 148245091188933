const prodConfig = {
  apiBaseUrl: 'https://api.magnifi.com/main/api',
  profileBaseUrl: 'https://api.magnifi.com/profile/api',
  tradeServiceBaseUrl: 'https://api.magnifi.com/trade-service/api',
  oktaClientId: '0oa1m1yl1txz8Zyyn697',
  oktaIssuer: 'auth.magnifi.com',
  adminBaseUrl: 'https://api.magnifi.com/admin-api',
  goService: 'https://api.magnifi.com/go-profile-service'
}

export default prodConfig

// ISSUER => https://magnifi.okta.com/oauth2/default
// CLIENT ID => 0oa1m1yl1txz8Zyyn697
// Google IDP => 0oa1m1w5if9xYSwaf697
// Apple IDP => 0oa1m1z0twcMYs030697
