import ActionTypes from '../action-types'

export function setSponserSuccess(payload) {
  return {
    type: ActionTypes.GET_SPONSERS_SUCCESS,
    payload
  }
}

export function getListSponsers(payload) {
  return {
    type: ActionTypes.GET_SPONSERS,
    payload
  }
}

export function setSponserError(payload) {
  return {
    type: ActionTypes.GET_SPONSERS_FAILURE,
    payload
  }
}

export function setSponserLoading(payload) {
  return {
    type: ActionTypes.GET_SPONSERS_LOADING,
    payload
  }
}

export function setSponserLoadingMore(payload) {
  return {
    type: ActionTypes.GET_SPONSERS_LOADING_MORE,
    payload
  }
}
