import React, { Component, Fragment } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Badge } from 'antd';
import classnames from 'classnames';

const REASONS = {
  UPDATED_ROUTING_NUMBER_BY_NOC : 'The account routing number has been updated.'
}


class ErrorAlert extends Component {

  constructor(props) {
    super(props);


    this.state = {
      activeEvaluation: '1',
      activeEquifax: '1',
      primaryOrSecondary:  '1'
    }
  }

  _getInvestigationDataTop = (investigations) => {
    let elements = [];
    const { primaryOrSecondary } = this.state;
    if(investigations && investigations.length){
      elements.push(<Nav tabs key="Nav-tabs">
          {investigations[1] ? <NavItem>
            <NavLink
              className={classnames({ active: primaryOrSecondary === '1' })}
              onClick={() => { this.setState({primaryOrSecondary: '1'}) }}
            >
              Primary
            </NavLink>
          </NavItem>:null}
          {investigations[1] ? <NavItem>
            <NavLink
              className={classnames({ active: primaryOrSecondary === '2' })}
              onClick={() => { this.setState({primaryOrSecondary: '2'}) }}
            >
              Secondary
            </NavLink>
          </NavItem> : null}
      </Nav>);
      elements.push(<TabContent activeTab={primaryOrSecondary} className={'rejection_alert'} key="Nav-tabs-content">
        <TabPane tabId="1">
          {this._getInvestigationData(investigations[0])}
        </TabPane>
        {investigations[1] ? <TabPane tabId="2">
          {this._getInvestigationData(investigations[1])}
        </TabPane>:null}
      </TabContent>)
    }
    return elements;
  }

  showBadgeCount = (data) => {
    if (data.length <= 0) return;
    return(<div style={{ position: 'absolute', right: 0, top: -7 }}>
    <Badge style={{ backgroundColor: '#56a9e8' }} count={data.length} />
 </div>	)
  }

  _getInvestigationData = (investigations) => {
    let elements = [];
      if(investigations.sketchId.result.evaluation){
        let ev = investigations.sketchId.result.evaluation;
        elements.push(<div key="source-evaluation" >
          <div><b>Sources:</b> {Array.isArray(ev.dataSources) && ev.dataSources.join(',')}</div>
          <div><b>Evaluation Result:</b> {ev.evaluatedState}</div>
        </div>);
      }
      const { activeEvaluation, activeEquifax } = this.state;
      const { accept, indeterminate, reject } = investigations.sketchId.result.equifaxResult.results;
      elements.push(<Nav tabs key="Nav-tabs">
          <NavItem>
            <NavLink
              className={`pos-relative ${classnames({ active: activeEvaluation === '1' })}`}
              onClick={() => { this.setState({activeEvaluation: '1'}) }}
            >
              DNDB Result
              {investigations.sketchId.result.dndbResult.profiles && this.showBadgeCount(investigations.sketchId.result.dndbResult.profiles)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`pos-relative ${classnames({ active: activeEvaluation === '2' })}`}
              onClick={() => { this.setState({activeEvaluation: '2'}) }}
            >
              Dow Jones Result
              {investigations.sketchId.result.dowJonesResult.profiles && this.showBadgeCount(investigations.sketchId.result.dowJonesResult.profiles)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`pos-relative ${classnames({ active: activeEvaluation === '3' })}`}
              onClick={() => { this.setState({activeEvaluation: '3'}) }}
            >
              Equifax Result
              {investigations.sketchId.result.equifaxResult.results && 
              this.showBadgeCount([...Object.keys(indeterminate),...Object.keys(reject)])}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`pos-relative ${classnames({ active: activeEvaluation === '4' })}`}
              onClick={() => { this.setState({activeEvaluation: '4'}) }}
            >
              Ofac Result
              {(investigations.sketchId.result.ofacResult.ofacMatch !== "NO_MATCH") && this.showBadgeCount(Object.keys(investigations.sketchId.result.ofacResult))}
            </NavLink>
          </NavItem>
      </Nav>);
      elements.push(<TabContent activeTab={activeEvaluation} key="Nav-tabs-content">
        <TabPane tabId="1">
          {investigations.sketchId.result.dndbResult.profiles && <div>
              {investigations.sketchId.result.dndbResult.profiles.map(dn => {
                return dn.reasons.map(( res => <div key={res.field} ><div><b>Field:</b> {res.field}</div>
                  <div><b>Strength:</b> {res.strength}</div></div>))
              })}
          </div>}
        </TabPane>
        <TabPane tabId="2">
          {investigations.sketchId.result.dowJonesResult.profiles && <div>
            <table border="1">
              <thead>
                <th className="p-1">Sr. No.</th>
                <th className="p-1">Name</th>
                <th className="p-1">Certainty</th>
                <th className="p-1">Short Summary</th>
                <th className="p-1">Summary</th>
                <th className="p-1">Reasons</th>
              </thead>
              <tbody>
              {investigations.sketchId.result.dowJonesResult.profiles.length ? investigations.sketchId.result.dowJonesResult.profiles.map((res, i) =>  <tr>
                  <td className="p-1">{i+1}</td>
                  <td className="p-1">{res.name}</td>
                  <td className="p-1">{res.certainty}</td>
                  <td className="p-1" >{res.shortSummary}</td>
                  <td className="p-1"><ul style={{marginLeft: 20}}>{res.summary.map(sum => <li>{sum}</li>)}</ul></td>
                  <td className="p-1">{res.reasons.map(res => <div><p><b>Field: </b>{res.field}</p><p><b>Strength: </b>{res.strength}</p></div>)}</td>
                </tr>
              ) : <tr><td className="text-center" colSpan={6}>No Data</td></tr>}
              </tbody>
            </table>
          </div>}
        </TabPane>
        <TabPane tabId="3">
           {investigations.sketchId.result.equifaxResult.results && <div>
            <Nav tabs>
           <NavItem>
             <NavLink
               className={`pos-relative ${classnames({ active: activeEquifax === '1' })}`}
               onClick={() => { this.setState({activeEquifax: '1'}) }}
             >
               Accept
               {/* {investigations.sketchId.result.equifaxResult.results.accept && this.showBadgeCount(Object.keys(investigations.sketchId.result.equifaxResult.results.accept))} */}
             </NavLink>
           </NavItem>
           <NavItem>
             <NavLink
               className={`pos-relative ${classnames({ active: activeEquifax === '2' })}`}
               onClick={() => { this.setState({activeEquifax: '2'}) }}
             >
               Indeterminate
               {investigations.sketchId.result.equifaxResult.results.indeterminate && this.showBadgeCount(Object.keys(investigations.sketchId.result.equifaxResult.results.indeterminate))}
             </NavLink>
           </NavItem>
           <NavItem>
             <NavLink
               className={`pos-relative ${classnames({ active: activeEquifax === '3' })}`}
               onClick={() => { this.setState({activeEquifax: '3'}) }}
             >
               Reject
               {investigations.sketchId.result.equifaxResult.results.reject && this.showBadgeCount(Object.keys(investigations.sketchId.result.equifaxResult.results.reject)) }
             </NavLink>
           </NavItem>
         </Nav>
         <TabContent activeTab={activeEquifax}>
           <TabPane tabId="1">
             {investigations.sketchId.result.equifaxResult.results.accept && Object.keys(investigations.sketchId.result.equifaxResult.results.accept).map(key => <p key={key}>{investigations.sketchId.result.equifaxResult.results.accept[key]}</p>)}
           </TabPane>
           <TabPane tabId="2">
           {investigations.sketchId.result.equifaxResult.results.indeterminate && Object.keys(investigations.sketchId.result.equifaxResult.results.indeterminate).map(key => <p key={key}>{investigations.sketchId.result.equifaxResult.results.indeterminate[key]}</p>)}
           </TabPane>
           <TabPane tabId="3">
           {investigations.sketchId.result.equifaxResult.results.reject && Object.keys(investigations.sketchId.result.equifaxResult.results.reject).map(key => <p key={key}>{investigations.sketchId.result.equifaxResult.results.reject[key]}</p>)}
           </TabPane>
         </TabContent>
           </div>
            }
        </TabPane>
        <TabPane tabId="4">
          {investigations.sketchId.result.ofacResult.ofacMatch && investigations.sketchId.result.ofacResult.ofacMatch}
        </TabPane>
      </TabContent>)


    return elements;
  }

  render() {
    return this._getInvestigationDataTop(this.props.investigations)
  }
}


export default ErrorAlert
