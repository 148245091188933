import moment from 'moment';
import ActionTypes from '../action-types';
import { getAuthToken } from '../common/auth-utils';

const tokenInfo = getAuthToken()

const accountActions = {
  closedAccount: 'CLOSE_ACCOUNT',
  statusSent: 'SENT',
  statusPending: 'PENDING',
  statusRejected: 'REJECTED',
  statusApproved: 'APPROVED',
  statusInProgress: 'INPROGRESS',
};

const initialState = {
  loggedIn: Boolean(tokenInfo.token),
  isLoading: false,
  user: {
    email: tokenInfo.email || '',
    firstName: tokenInfo.firstName || '',
    lastName: tokenInfo.lastName || ''
  },
  token: tokenInfo.token || '',
  closeAccountStatus: '',
  accounts: [],
  allStatus: [],
  accountsCount: 0,
  appliedFilter: '',
  activePage: 1,
  apexStatus: null,
}

export function account(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.TRADING_ACCOUNT_LIST_FETCH:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.TRADING_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.TRADING_ACCOUNT_LIST_FAILED:
      return {
        error: payload.error,
      }

    case ActionTypes.TRADING_ACCOUNT_DETAIL_FETCH:
      return {
        ...state
      }

    case ActionTypes.TRADING_ACCOUNT_DETAIL_SUCCESS:
      let { closeAccountStatus: __closeAccountStatus } = state;
      const { data } = payload;
      if (data && data.request && data.request.length) {
        // let CLOSED_ACCOUNT_REQUEST = data.request.find((item) => item.request_type === accountActions.closedAccount);
        let CLOSED_ACCOUNT_REQUEST = data.request
                                         .filter((item) => item.request_type === accountActions.closedAccount)
                                         // .sort((x, y) => new Date(x.created_at) - new Date(y.created_at));
                                         .sort((x, y) => y.id - x.id);
        console.log('CLOSED_ACCOUNT_REQUEST --> ', CLOSED_ACCOUNT_REQUEST);
        if (CLOSED_ACCOUNT_REQUEST &&
          CLOSED_ACCOUNT_REQUEST.length &&
          CLOSED_ACCOUNT_REQUEST[0] &&
          CLOSED_ACCOUNT_REQUEST[0].status
        ) {
          if (CLOSED_ACCOUNT_REQUEST[0].status !== accountActions.statusApproved) {
            // NOTE: 'Close Account' request sent from UI side, Pending for APPROVAL
            // __closeAccountStatus = accountActions.statusSent;
            __closeAccountStatus = CLOSED_ACCOUNT_REQUEST[0].status;
            console.log('__closeAccountStatus SENT --> ', __closeAccountStatus)
          } else {
            // NOTE: 'Close Account' request sent and "APPROVED"
            __closeAccountStatus = accountActions.statusApproved;
            console.log('__closeAccountStatus APPROVED --> ', __closeAccountStatus)
          }
        }
      }
      return {
        ...state,
        closeAccountStatus: __closeAccountStatus,
        account: {
          ...payload
        }
      }

    case ActionTypes.TRADING_ACCOUNT_DETAIL_FAILED:
      return {
        error: payload.error
      }

    case ActionTypes.FETCH_ACCOUNT_STATUS:
      return {
        ...state
      }
    case ActionTypes.ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        account: {
          ...action.account
        }
      }
    case ActionTypes.ACCOUNT_STATUS_FAILURE:
      return {
        error: action.error
      }

    case ActionTypes.UPDATE_ACCOUNT_STATUS:
      return {
        ...state
      }
    case ActionTypes.ACCOUNT_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        account: {
          ...action.account
        }
      }
    case ActionTypes.ACCOUNT_UPDATE_STATUS_FAILURE:
      return {
        error: action.error
      }
    case ActionTypes.CANCEL_ACH_FETCH:
      return {
        ...state,
        account: {
          ...state.account,
          isLoading: true,
        }
      }
    case ActionTypes.CANCEL_ACH_SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          data: {
            ...state.account.data,
            ach: state.account.data.ach.filter(item => item.id !== payload)
          },
          isLoading: false
        }
      }
    case ActionTypes.CANCEL_ACH_FAILED:
      return {
        ...state,
        account: {
          ...state.account,
          isLoading: true,
          error: true
        }
      }
    case ActionTypes.CLOSE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.SET_ACCOUNT_STATE:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.USER_RESTRICT_SUCCESS:
      return {
        ...state,
        ...payload
      }

    default:
      return state
  }
}
