import { createAPIInstance } from './api'
import qs from 'query-string'

export function authenticate(payload) {
  return createAPIInstance().post('user/login', payload, {
    headers: {
      Authorization: null
    }
  })
}

export function validateOktaUser(payload) {
  return createAPIInstance(undefined, true).post(
    'v1/validate-okta-user',
    payload
  )
}

export function acceptsTerms(payload) {
  // return Promise.resolve({message: 'success'})
  return createAPIInstance().post('user/accept-terms', payload)
}

export function logout(payload) {
  return createAPIInstance().post('user/logout', payload)
}

export function sendChangePwdEmail(payload) {
  return createAPIInstance().get('user/send-change-pwd', { params: payload })
}

export function verifyChangePwdToken(payload) {
  return createAPIInstance().get('user/verify-sent-code', {
    params: { q: payload }
  })
}

export function changePassword(payload) {
  return createAPIInstance().post(
    'user/changepwd',
    qs.stringify({ password: payload.password }),
    {
      headers: {
        Authorization: `Token ${payload.verifypwdtoken}`
      }
    }
  )
}
