import React, { Component } from 'react';
import { uploadReviewImage } from 'apis/account'
import { Input, Alert } from 'reactstrap'
import { isEmpty, trim } from 'lodash';
const Loader = '/assets/images/ajax-loader.gif'

const FILE_SIZE = 15; // mb
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

class ConfirmModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      uploading   : false,
      uploaded    : false,
      uploadError : "",
      error: null
    }
  }

  onChangeHandler = async (event) => {
    if(event.target.files && event.target.files[0]){
      const files = event.target.files;
      let fileSize = files[0].size / 1024 / 1024;
      if (fileSize > FILE_SIZE) {
        return this.setState({
          uploading   : false,
          uploaded    : false,
          uploadError : `The file ${files[0].name} is too big. (files must be under 15MB in size).`,
        });
      }else if(!SUPPORTED_FORMATS.includes(files[0].type)){
        return this.setState({
          uploading   : false,
          uploaded    : false,
          uploadError : "Only .png,.jpg,.jpeg,.gif, image format are allowed",
        });
      }
      let formData = new FormData();
      formData.append('file', event.target.files[0])
      formData.append('tag', 'AFFILIATED_APPROVAL')
      formData.append('account', this.props.apexAccountID)
      this.setState({
        uploading   : true,
        uploaded    : false,
        uploadError : null,
      })

      try {
        let upload = await uploadReviewImage(formData)

        this.setState({
          uploading   : false,
          uploaded    : true,
          uploadError : null,
        })
        this.props.handleUprovedDocID(upload.record_id)
      } catch (e) {
        this.setState({
          uploading   : false,
          uploaded    : false,
          uploadError : "Error"
        })
      }
    }
  }

  handleModalAction = () => {
    if(!this.state.uploadError && this.props.userInput && isEmpty(trim(this.props.userInputArea))){
        this.setState({
          error: 'Please enter reason!'
        });
    } else {
      this.props.handleModalAction();
    }
  }

  removeAlert = () => {
    this.setState({
      error: null
    });
  }

  render() {
  const { error, uploadError } = this.state;
  let btn1ClassName = "popup-btn mr-4 " + this.props.type

    return (
      <div className="popup-overlay">
          <div className="model-popup md-popup">
              <a className="popup-cross" onClick={this.props.closeModal}><i className="fa fa-times"></i></a>
              <h1>{this.props.heading}</h1>
              <div className="model-body text-center">

                {this.props.userInput && <div className="reject-document-textarea">
                  <textarea name="userInputArea" placeholder={this.props.placeholder} onFocus={this.removeAlert} onChange={this.props.onChange} value={this.props.userInputArea}></textarea>
                  {error && <Alert color="danger">
                  {error}
                  </Alert>}
                </div>}

                {this.props.canUpload && <div className="fileUploadOuter">
                  {this.props.modalType === 'appeal_modal' && <label className="modalLbl">Please upload any of the following documents: SSC, W2, Letter from SSA or IRS</label>}
                  <input type="file"/>
                    <Input
                      type="file"
                      name={`uploadDoc`}
                      id={`uploadDoc`}
                      onChange={this.onChangeHandler}
                    />
                    
                  <div className="customFileUpload">
                      <i className="fa fa-upload iconUpload"></i> Upload File
                      {this.state.uploaded && <i className="fa fa-check-circle iconCheck"></i>}
                      {this.state.uploading && <img className="fileload" src={Loader}/>}
                  </div>
                  {uploadError && <Alert color="danger">
                  {uploadError}
                  </Alert>}
                </div>}

                <button onClick={(this.state.uploading) ? console.log('wait...') : this.handleModalAction} className={btn1ClassName}>{this.props.btn1}</button>
                <button className="popup-btn line-btn" onClick={this.props.closeModal}>{this.props.btn2}</button>
              </div>
          </div>



          {/*<div className="model-popup md-popup d-none">
              <a className="popup-cross"><i className="fa fa-times"></i></a>
              <h1>Approve Identity Document</h1>
              <div className="model-body text-center">
                <button className="popup-btn approved mr-4">Approve</button>
                <button className="popup-btn line-btn">Cancel</button>
              </div>
          </div>

          <div className="model-popup md-popup d-none">
              <a className="popup-cross"><i className="fa fa-times"></i></a>
              <h1>Confirm Closing An Account</h1>
              <div className="model-body text-center">
                <button className="popup-btn mr-4">Yes</button>
                <button className="popup-btn line-btn">No</button>
              </div>
          </div>*/}

      </div>



    )
  }

}

export default ConfirmModal;
